export const BUYBOX_STATS = "BUYBOX_STATS";
export const BUYBOX_STATS_SUCCESS = "BUYBOX_STATS_SUCCESS";
export const FETCH_UPLOAD_FILE_HISTORY = "FETCH_UPLOAD_FILE_HISTORY";
export const FETCH_UPLOAD_FILE_HISTORY_SUCCESS = "FETCH_UPLOAD_FILE_HISTORY_SUCCESS";
export const FETCH_REPRICER_LISTINGS = "FETCH_REPRICER_LISTINGS";
export const FETCH_REPRICER_LISTINGS_SUCCESS = "FETCH_REPRICER_LISTINGS_SUCCESS";
export const FETCH_REPRICER_LISTINGS_FORMULAS = "FETCH_REPRICER_LISTINGS_FORMULAS";
export const FETCH_REPRICER_LISTINGS_FORMULAS_SUCCESS = "FETCH_REPRICER_LISTINGS_FORMULAS_SUCCESS";
export const FETCH_REPRICER_LISTING_DETAILS = "FETCH_REPRICER_LISTING_DETAILS";
export const FETCH_REPRICER_LISTING_DETAILS_SUCCESS = "FETCH_REPRICER_LISTING_DETAILS_SUCCESS";
export const FETCH_REPRICER_PRICE_CHANGE_HISTORIES = "FETCH_REPRICER_PRICE_CHANGE_HISTORIES";
export const FETCH_REPRICER_PRICE_CHANGE_HISTORIES_SUCCESS = "FETCH_REPRICER_PRICE_CHANGE_HISTORIES_SUCCESS";
export const CREATE_REPRICER_LISTINGS_FORMULAS = "CREATE_REPRICER_LISTINGS_FORMULAS";
export const CREATE_REPRICER_LISTINGS_FORMULAS_SUCCESS = "CREATE_REPRICER_LISTINGS_FORMULAS_SUCCESS";
export const UPDATE_REPRICER_LISTINGS_FORMULAS = "UPDATE_REPRICER_LISTINGS_FORMULAS";
export const UPDATE_REPRICER_LISTINGS_FORMULAS_SUCCESS = "UPDATE_REPRICER_LISTINGS_FORMULAS_SUCCESS";
export const DELETE_REPRICER_LISTINGS_FORMULA = "DELETE_REPRICER_LISTINGS_FORMULA";
export const DELETE_REPRICER_LISTINGS_FORMULA_SUCCESS = "DELETE_REPRICER_LISTINGS_FORMULA_SUCCESS";
export const CREATE_REPRICER_LISTINGS_TAGS = "CREATE_REPRICER_LISTINGS_TAGS";
export const CREATE_REPRICER_LISTINGS_TAGS_SUCCESS = "CREATE_REPRICER_LISTINGS_TAGS_SUCCESS";
export const CREATE_AND_UPDATE_TAG = "CREATE_AND_UPDATE_TAG";
export const UPDATE_REPRICER_LISTINGS_TAGS = "UPDATE_REPRICER_LISTINGS_TAGS";
export const UPDATE_REPRICER_LISTINGS_TAGS_SUCCESS = "UPDATE_REPRICER_LISTINGS_TAGS_SUCCESS";
export const DELETE_REPRICER_LISTINGS_TAG = "DELETE_REPRICER_LISTINGS_TAG";
export const DELETE_REPRICER_LISTINGS_TAG_SUCCESS = "DELETE_REPRICER_LISTINGS_TAG_SUCCESS";
export const DELETE_ALL_TAGS = "DELETE_ALL_TAGS";
export const DELETE_ALL_TAGS_SUCCESS = "DELETE_ALL_TAGS_SUCCESS";
export const BULK_UPDATE_CSV_LISTINGS = "BULK_UPDATE_CSV_LISTINGS";
export const BULK_UPDATE_CSV_LISTINGS_SUCCESS = "BULK_UPDATE_CSV_LISTINGS_SUCCESS";
export const FETCH_LISTINGS_STATS = "FETCH_LISTINGS_STATS";
export const FETCH_LISTINGS_STATS_SUCCESS = "FETCH_LISTINGS_STATS_SUCCESS";
export const UPDATE_REPRICER_LISTING = "UPDATE_REPRICER_LISTING";
export const UPDATE_REPRICER_LISTING_SUCCESS = "UPDATE_REPRICER_LISTING_SUCCESS";
export const UPDATE_LISTING_MAP_PRICE = "UPDATE_LISTING_MAP_PRICE";
export const UPDATE_LISTING_MAP_PRICE_SUCCESS = "UPDATE_LISTING_MAP_PRICE_SUCCESS";
export const ASSIGN_STRATEGY = "ASSIGN_STRATEGY";
export const ASSIGN_STRATEGY_SUCCESS = "ASSIGN_STRATEGY_SUCCESS";
export const ASSIGN_LISTINGS_STRATEGY = "ASSIGN_LISTINGS_STRATEGY";
export const ASSIGN_LISTINGS_STRATEGY_SUCCESS = "ASSIGN_LISTINGS_STRATEGY_SUCCESS";
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_ALL_REPRICER_ACCOUNTS = "GET_ALL_REPRICER_ACCOUNTS";
export const GET_ALL_REPRICER_ACCOUNTS_SUCCESS = "GET_ALL_REPRICER_ACCOUNTS_SUCCESS";
export const GET_STRATEGIES = "GET_STRATEGIES";
export const GET_STRATEGIES_SUCCESS = "GET_STRATEGIES_SUCCESS";
export const GET_STRATEGIES_STATS = "GET_STRATEGIES_STATS";
export const GET_STRATEGIES_STATS_SUCCESS = "GET_STRATEGIES_STATS_SUCCESS";
export const CREATE_STRATEGY = "CREATE_STRATEGY";
export const CREATE_STRATEGY_SUCCESS = "CREATE_STRATEGY_SUCCESS";
export const UPDATE_STRATEGY = "UPDATE_STRATEGY";
export const UPDATE_STRATEGY_SUCCESS = "UPDATE_STRATEGY_SUCCESS";
export const REMOVE_STRATEGY = "REMOVE_STRATEGY";
export const REMOVE_STRATEGY_SUCCESS = "REMOVE_STRATEGY_SUCCESS";
export const REMOVE_STRATEGY_ACCOUNT = "REMOVE_STRATEGY_ACCOUNT";
export const REMOVE_STRATEGY_ACCOUNT_SUCCESS = "REMOVE_STRATEGY_ACCOUNT_SUCCESS";
export const SET_PROFIT_DASHBOARD_TRIAL_MODAL = "SET_PROFIT_DASHBOARD_TRIAL_MODAL";
export const SET_REPRICER_FORMULAS_MODAL = "SET_REPRICER_FORMULAS_MODAL";
export const SET_REPRICER_TAGS_MODAL = "SET_REPRICER_TAGS_MODAL";
export const SET_REPRICER_LISTINGS_MODAL = "SET_REPRICER_LISTINGS_MODAL";
export const SET_STRATEGY_MODAL = "SET_STRATEGY_MODAL";
export const SET_UPDATE_COGS_MODAL = "SET_UPDATE_COGS_MODAL";
export const SET_ASSIGN_STRATEGY_MODAL = "SET_ASSIGN_STRATEGY_MODAL";
export const SET_REMOVE_STRATEGY_MODAL = "SET_REMOVE_STRATEGY_MODAL";
export const API_ERROR = "API_ERROR";
export const REMOVE_STRATEGY_ERROR = "PLATFORM_API_ERROR";
