/* eslint-disable no-eval */
import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import StrategyMinMaxPriceCard from "./StrategyMinMaxPriceCard";
import $ from "lodash";
import StatusBadge from "../../../components/Common/StatusBadge";
import { RenderIf } from "../../../utils/common";
import SimpleSelect from "../../../components/Common/SimpleSelect";
import PillBtn from "../../../components/Common/PillBtn";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/repricer/actions";
import { useFormik } from "formik";

const minProfitValueMsg = "Enter minimum profit value";
const maxProfitValueMsg = "Enter maximum profit value";
const minProfitPercentageMsg = "Enter minimum profit percentage";
const maxProfitPercentageMsg = "Enter maximum profit percentage";

const Step2 = ({ stepCounter, setStepCounter, step2InitialData, setStep2InitialData, formikValues }) => {
  const dispatch = useDispatch();
  const strategiesCardData = [
    {
      icon: <i className="bx mt-1 font-size-15 bxs-plus-circle text-success" />,
      title: "Fixed Value",
      value: "FIXED_VALUE",
      description: "Calculate minimum and maximum prices using fixed value.",
      note: "Note: We already include marketplace fees, so you don't have to.",
    },
    {
      icon: <i className="bx  mt-1 font-size-15 bxs-dollar-circle" style={{ color: "#FD7923" }} />,
      title: "Fixed Profit",
      value: "FIXED_PROFIT",
      description: "Calculate minimum and maximum prices using fixed profit percentage.",
      note: "Note: We already include marketplace fees, so you don't have to.",
    },
    {
      icon: <i className="bx mt-1 font-size-15 bx-line-chart text-warning" />,
      title: "Choose Max",
      value: "CHOOSE_MAX",
      description: "Maximum value will be used among the profit percentage and fixed value. ",
      note: "Note: We already include marketplace fees, so you don't have to.",
    },
    {
      icon: <i className="bx mt-1 font-size-15 bx-stats text-primary" />,
      title: "Formula Base Min/Max",
      value: "FORMULA_BASED",
      description: "Calculate minimum and maximum prices using a custom formula.",
      note: "Note: We already include marketplace fees, so you don't have to.",
    },
  ];
  const { listingsFormulas } = useSelector((state) => state.Repricer);
  const strategyFormulasLabels = listingsFormulas?.results?.map(({ id, name, statement }) => ({
    label: name,
    value: id,
    statement,
  }));
  const keys = [
    "minMaxType",
    "minimumValue",
    "maximumValue",
    "minimumPercentage",
    "maximumPercentage",
    "minimumHybridValue",
    "minimumHybridPercentage",
    "maximumHybridValue",
    "maximumHybridPercentage",
    "minPriceFormula",
    "maxPriceFormula",
  ];
  const checkError = (values) => {
    const errors = {};
    let keys = [];
    if (values.minMaxType === "FIXED_PROFIT") {
      keys = ["minimumPercentage", "maximumPercentage"];
    } else if (values.minMaxType === "CHOOSE_MAX") {
      keys = ["minimumHybridValue", "minimumHybridPercentage", "maximumHybridValue", "maximumHybridPercentage"];
    } else if (values.minMaxType === "FORMULA_BASED") {
      keys = ["minPriceFormula", "maxPriceFormula"];
    } else {
      keys = ["minimumValue", "maximumValue"];
    }
    keys.forEach((key) => {
      if (key.includes("maximum")) {
        const sameKey = key.split("maximum")[1];
        const value = values[key];
        if ($.isFinite(value) && value <= values?.["minimum" + sameKey]) {
          errors[key] = "Maximum value greater than Minimum value and not zero";
        }
      }
      if (key.includes("PriceFormula")) {
        const [lhs, rhs] = ["minPriceFormula", "maxPriceFormula"].map((k) =>
          strategyFormulasLabels?.find((x) => x.value === values[k])?.statement?.replace(/cost/g, "100"),
        );
        const [lVal, rVal] = [lhs, rhs].map(eval);
        if (lhs > rhs) {
          errors.minPriceFormula = `Maximum Formula value is ${(rVal / 0.85).toFixed(
            2,
          )} and Minimum Formula value is ${(lVal / 0.85).toFixed(
            2,
          )}. Maximum value should be greater than Minimum value and not zero`;
        }
      }
      if (!$.isFinite(values[key])) errors[key] = "Please Input Required Fields";
    });
    return errors;
  };

  const errorExists = (values) => !$.isEmpty(checkError(values));

  const checkAndSetErrors = (values) => {
    const errors = checkError(values);
    formik.setErrors(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: $.pick(step2InitialData || formikValues, keys),
    validate: checkAndSetErrors,
    onSubmit: (values) => {
      if (!$.isEmpty(formik.errors)) return;
      if (values.minMaxType === "FIXED_VALUE") {
        setStep2InitialData($.merge(step2InitialData, $.pick(values, "minMaxType", "minimumValue", "maximumValue")));
      } else if (values.minMaxType === "FIXED_PROFIT") {
        setStep2InitialData(
          $.merge(step2InitialData, $.pick(values, "minMaxType", "minimumPercentage", "maximumPercentage")),
        );
      } else if (values.minMaxType === "CHOOSE_MAX") {
        setStep2InitialData(
          $.merge(
            step2InitialData,
            $.pick(
              values,
              "minMaxType",
              "minimumHybridValue",
              "maximumHybridValue",
              "minimumHybridPercentage",
              "maximumHybridPercentage",
            ),
          ),
        );
      } else if (values.minMaxType === "FORMULA_BASED") {
        setStep2InitialData(
          $.merge(step2InitialData, $.pick(values, "minMaxType", "minPriceFormula", "maxPriceFormula")),
        );
      }
      setStepCounter(3);
    },
  });

  const [cardType, setCardType] = useState(
    formik.values.minMaxType || setStep2InitialData.minMaxType || formikValues.minMaxType || null,
  );

  const InputAddOn = ({ symbol, style }) => (
    <span className="input-group-append">
      <span
        style={{ position: "relative", borderRadius: "1", ...style }}
        className="input-group-text colorpicker-input-addon"
      >
        {symbol}
      </span>
    </span>
  );

  const InputField = ({ field, className, symbol, isPrepend = true, placeholder = "Enter profit" }) => (
    <div className={"d-flex no-spinners " + className}>
      <RenderIf isTrue={isPrepend}>
        <InputAddOn symbol={symbol} style={{ left: "1px" }} />
      </RenderIf>
      <Input
        type="number"
        step="0.01"
        min="0.01"
        className="form-control"
        placeholder={placeholder}
        onKeyPress={(e) => ["-", "e", "E"].includes(e.key) && e.preventDefault()}
        onChange={(e) => formik.setFieldValue(field, +e.target.value || "")}
        value={formik.values[field] || ""}
      />
      <RenderIf isTrue={!isPrepend}>
        <InputAddOn symbol={symbol} style={{ left: "-1px" }} />
      </RenderIf>
    </div>
  );

  return (
    <Col md={12}>
      <div
        className="strategy-card-header font-size-15 font-weight-semibold inventory-color"
        style={{ marginBottom: "24px" }}
      >
        <div className="d-flex justify-content-between">
          <span>{stepCounter}. Min or Max Price</span>
          <StatusBadge status={"Strategy: Get BuyBox"} colorsMapping={{ "Strategy: Get BuyBox": "orange" }} />
        </div>
      </div>
      <Row className="font-size-12 font-weight-medium">
        {strategiesCardData.map((cardData, index) => (
          <StrategyMinMaxPriceCard
            key={index}
            {...cardData}
            cardType={cardType}
            onClick={() => {
              if (cardData.value === cardType) return;
              setCardType(cardData.value);
              setStep2InitialData({});
              formik.setValues(
                $.merge(formik.values, {
                  minMaxType: cardData.value,
                  minimumValue: null,
                  maximumValue: null,
                  minimumPercentage: null,
                  maximumPercentage: null,
                  minimumHybridValue: null,
                  maximumHybridValue: null,
                  minimumHybridPercentage: null,
                  maximumHybridPercentage: null,
                  minPriceFormula: null,
                  maxPriceFormula: null,
                }),
              );
            }}
          />
        ))}
      </Row>
      <RenderIf isTrue={cardType}>
        <Row className="mt-3 font-size-13 font-weight-medium">
          <Col md={4}>
            <RenderIf isTrue={cardType === "FIXED_VALUE"}>
              <div className="w-100">
                <FormGroup className="select2-container">
                  <Label>Fixed minimum profit:</Label>
                  {InputField({ field: "minimumValue", symbol: "$", placeholder: minProfitValueMsg })}
                </FormGroup>
              </div>
              <div className="w-100 mt-2">
                <FormGroup className="select2-container">
                  <Label>Fixed maximum profit:</Label>
                  {InputField({ field: "maximumValue", symbol: "$", placeholder: maxProfitValueMsg })}
                </FormGroup>
              </div>
            </RenderIf>
            <RenderIf isTrue={cardType === "FIXED_PROFIT"}>
              <div className="w-100">
                <FormGroup className="select2-container">
                  <Label>Fixed minimum profit:</Label>
                  {InputField({
                    field: "minimumPercentage",
                    symbol: "%",
                    isPrepend: false,
                    placeholder: minProfitPercentageMsg,
                  })}
                </FormGroup>
              </div>
              <div className="w-100 mt-2">
                <FormGroup className="select2-container">
                  <Label>Fixed maximum profit:</Label>
                  {InputField({
                    field: "maximumPercentage",
                    symbol: "%",
                    isPrepend: false,
                    placeholder: maxProfitPercentageMsg,
                  })}
                </FormGroup>
              </div>
            </RenderIf>
            <RenderIf isTrue={cardType === "CHOOSE_MAX"}>
              <div className="w-100">
                <FormGroup className="select2-container">
                  <Label>Minimum Profit:</Label>
                  {InputField({ field: "minimumHybridValue", symbol: "$", placeholder: minProfitValueMsg })}
                  {InputField({
                    className: "mt-2",
                    field: "minimumHybridPercentage",
                    symbol: "%",
                    isPrepend: false,
                    placeholder: minProfitPercentageMsg,
                  })}
                </FormGroup>
              </div>
              <div className="w-100">
                <FormGroup className="select2-container">
                  <Label>Maximum Profit:</Label>
                  {InputField({ field: "maximumHybridValue", symbol: "$", placeholder: maxProfitValueMsg })}
                  {InputField({
                    className: "mt-2",
                    field: "maximumHybridPercentage",
                    symbol: "%",
                    isPrepend: false,
                    placeholder: maxProfitPercentageMsg,
                  })}
                </FormGroup>
              </div>
            </RenderIf>
            <RenderIf isTrue={cardType === "FORMULA_BASED"}>
              <>
                <div>
                  Minimum Formula:
                  <SimpleSelect
                    options={strategyFormulasLabels}
                    onChange={(e) => {
                      formik.setFieldValue("minPriceFormula", e.value);
                    }}
                    value={strategyFormulasLabels?.find((x) => x.value === formik.values.minPriceFormula)}
                    formStyle={{ width: "100%" }}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div>
                  Maximum Formula:
                  <SimpleSelect
                    options={strategyFormulasLabels}
                    onChange={(e) => {
                      formik.setFieldValue("maxPriceFormula", e.value);
                    }}
                    value={strategyFormulasLabels?.find((x) => x.value === formik.values.maxPriceFormula)}
                    formStyle={{ width: "100%" }}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <PillBtn
                  title={"New Formula"}
                  name={"New Formula"}
                  color={"primary"}
                  icon={"bx bx-xs bx-plus"}
                  outline={true}
                  onClick={() => dispatch(actions.setRepricerFormulasModal(true))}
                />
              </>
            </RenderIf>
          </Col>
        </Row>
      </RenderIf>
      <RenderIf isTrue={errorExists(formik.values)}>
        <Row className="font-size-11 font-weight-medium mt-3">
          <Col md={12}>
            <div className="d-flex align-items-center text-warning">
              <i className="bx bx-info-circle mx-1" />
              <RenderIf
                isTrue={formik.errors.minPriceFormula}
                fallback="Note: Maximum value should be greater than Minimum value and not zero."
              >
                {formik.errors.minPriceFormula}
              </RenderIf>
            </div>
          </Col>
        </Row>
      </RenderIf>
      <Row className="font-size-13 font-weight-medium">
        <Col md={12}>
          <div style={{ padding: "10px 0px" }}>
            <hr />
          </div>
          <div className="d-flex justify-content-between" style={{ padding: "10px 0px" }}>
            <PillBtn
              className="ml-2"
              title={"Back"}
              name={"Back"}
              color={"primary"}
              outline={true}
              onClick={() => {
                setStepCounter(stepCounter - 1);
              }}
            />
            <PillBtn
              disabled={errorExists(formik.values)}
              className={`${errorExists(formik.values) ? "cursor-not-allowed" : ""} ml-2`}
              title={"Save & Next"}
              name={"Save & Next"}
              color={"primary"}
              onClick={formik.handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Step2;
