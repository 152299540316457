import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Row, Button, Container, Alert } from "reactstrap";

import { fetchSavedFilters, fetchMatches, setMarketplace } from "../../store/actions";
import Breadcrumbs from "./Components/Breadcrumb";
import MatchingList from "./Components/MatchingList";
import SelectFilter from "./Components/SelectFilter";
import FilterSection from "./Components/FilterSection";
import SaveFilterModal from "./Components/SaveFilterModal";
import ColumnSelector from "./Components/ColumnSelector";
import StoreSelector from "../../components/Common/StoreSelector";

import { RenderIf, setLoader, getUserInfo } from "../../utils/common";
import { formatFilter } from "./common";
import { MARKET_PLACES } from "../../constants";
import { merge } from "lodash";

const ProfitAnalyzer = () => {
  const dispatch = useDispatch();
  const {
    filters: filterState,
    selectedMarketplace,
    loading,
    totalCount,
    selectedFilter,
    error,
    columnFilters,
  } = useSelector((state) => state.ProfitAnalyzer);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [columns, setColumns] = useState({ Check: false, Uncheck: true });
  const [showFilters, setShowFilters] = useState(true);
  const [marketPlaceAccount, setMarketPlaceAccount] = useState({ value: "", label: "" });

  useEffect(() => {
    dispatch(fetchSavedFilters({ email: getUserInfo()?.email }));
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (accounts) {
      const defaultAccount = accounts?.data?.find((account) => account.marketplace === "amazon");
      setMarketPlaceAccount({
        label: defaultAccount.name || "",
        value: defaultAccount.id ? defaultAccount.id : defaultAccount.value,
        marketplace: defaultAccount.marketplace,
        identifier:
          defaultAccount.marketplace === MARKET_PLACES.walmart
            ? defaultAccount.unique_identifier
            : defaultAccount.access_id,
        valid: defaultAccount.valid,
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (marketPlaceAccount?.marketplace) {
      dispatch(setMarketplace({ marketplace: marketPlaceAccount.marketplace }));
    }
  }, [marketPlaceAccount]);

  const handleApplyFilter = (page = 0) => {
    const currentFilters = formatFilter(filterState, selectedMarketplace);
    dispatch(
      fetchMatches({
        filter: currentFilters,
        marketplace: selectedMarketplace,
        email: getUserInfo()?.email,
        columnFilters,
        page,
      }),
    );
  };

  const DEFAULT_HIDDEN_COLS = [
    "Supplier category",
    "Brand",
    "Item ID",
    "Lowest FBA",
    "Lowest FBA (Avg. 30 days)",
    "Lowest FBA (Avg. 60 days)",
    "Lowest FBA (Avg. 90 days)",
    "Lowest FBA (Avg. 180 days)",
    "Lowest Offer",
    "Lowest Offer (Avg. 30 days)",
    "Lowest Offer (Avg. 60 days)",
    "Lowest Offer (Avg. 90 days)",
    "Lowest Offer (Avg. 180 days)",
    "Buybox Price",
    "Buy Box (Avg. 30 days)",
    "Buy Box (Avg. 60 days)",
    "Buy Box (Avg. 90 days)",
    "Buy Box (Avg. 180 days)",
    "Buy Box+",
    "Buy Box+ (Avg. 30 days)",
    "Buy Box+ (Avg. 60 days)",
    "Buy Box+ (Avg. 90 days)",
    "Buy Box+ (Avg. 180 days)",
    "Size",
    "Color",
    "Competitive Sellers",
    "Net Profit",
    "Brand Restriction",
    "Hazmat",
    "Avg Rank",
    "UPC",
    "30 Day Price",
    "60 Day Price",
    "90 Day Price",
    "FBA Fees",
    `${selectedMarketplace} Category`,
    "Amz Fees",
    "Fees",
    "Shipping Details",
    "Discount/Premiuim",
    "Estimated Sales",
    "Amazon on Listing",
    "Margin",
    "Amz Offers",
  ];

  function getTableHeaders (headers) {
    const filterData = headers?.filter((x) => !x.hide);
    setTableHeaders(filterData);

    setColumns(
      merge(
        columns,
        headers.reduce((acc, x) => {
          if (DEFAULT_HIDDEN_COLS.includes(x.title)) acc[x.title] = false;
          else acc[x.title] = true;
          return acc;
        }, {}),
      ),
    );
  }

  return (
    <React.Fragment>
      <RenderIf isTrue={error}>
        <Alert color="danger" className="d-flex align-items-center gap-2">
          <i className="bx bx-info-circle" />
          <span>{error}</span>
        </Alert>
      </RenderIf>

      <SaveFilterModal isOpen={isModalOpen} toggleModal={() => setIsModalOpen(!isModalOpen)} />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Product Finder"
            totalCount={totalCount}
            children={
              <>
                <Row className="align-items-center d-flex justify-content-end mr-1">
                  <RenderIf
                    isTrue={!showFilters}
                    fallback={
                      <StoreSelector
                        value={marketPlaceAccount}
                        onChange={setMarketPlaceAccount}
                        forRepricer={true}
                        marketplaces={["amazon"]}
                        isValid={true}
                      />
                    }
                  >
                    <ColumnSelector tableHeaders={tableHeaders} columns={columns} setColumns={setColumns} />

                    <SelectFilter classes="mt-3 mr-3 text-[#C3CBE4]" style={{ width: "131px" }} />
                    <Button
                      color="primary"
                      onClick={() => setShowFilters(!showFilters)}
                      className="d-inline-flex align-items-center gap-1 dropdown-toggle btn btn-primary"
                    >
                      <i className="bx bx-xs bx-filter-alt" style={{ width: "18px", height: "18px" }} /> Filters
                    </Button>
                  </RenderIf>
                </Row>
              </>
            }
          />
          <RenderIf isTrue={showFilters}>
            <FilterSection
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              filters={filterState?.[selectedMarketplace]}
              marketplace={selectedMarketplace}
              setIsModalOpen={setIsModalOpen}
              selectedFilter={selectedFilter}
              handleApplyFilter={handleApplyFilter}
            />
          </RenderIf>

          <Card>
            <MatchingList
              columns={columns}
              setColumns={setColumns}
              filters={filterState}
              getTableHeaders={getTableHeaders}
              tableHeaders={tableHeaders}
              showFilters={showFilters}
              handleApplyFilter={handleApplyFilter}
              totalCount={totalCount}
              marketPlaceAccount={marketPlaceAccount}
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProfitAnalyzer;
