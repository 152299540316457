import React from "react";
import { Row, Button, CardBody, Col } from "reactstrap";

import Modal from "../../../components/Common/Modal";

const ListingModal = (props) => {
  const { isOpen, toggleModal, match, currentState, setState } = props;

  return (
    <Modal size="md" isOpen={isOpen} toggle={() => toggleModal(match?._id, currentState, setState)}>
      <div className="modal-header pt-3 pb-2">
        <h4 className="modal-title text-secondary" id="myLargeModalLabel">
          Product Listing
        </h4>
        <button
          onClick={() => toggleModal(match?._id, currentState, setState)}
          type="button"
          className="close text-white"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" className="bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body p-2">
        <div className="px-2">
          <CardBody>
            <h5>List As</h5>
            <Row>
              <Col>
                <Button color="link" className="text-white w-100">
                  <div className="border rounded p-3 text-center">
                    <box-icon name="cube" type="solid" color="#6478ff" size="lg"></box-icon>
                    <p>Product Shipping Item</p>
                  </div>
                </Button>
              </Col>
              <Col>
                <Button color="link" className="text-white w-100">
                  <div className="border rounded p-3 text-center">
                    <box-icon name="list-ul" type="solid" color="#6478ff" size="lg"></box-icon>
                    <p>Simple Listing</p>
                  </div>
                </Button>
              </Col>
            </Row>
          </CardBody>
          <Row className="align-items-center d-flex justify-content-end mr-1">
            <Button
              color="primary "
              outline
              className="waves-effect waves-light mr-3"
              onClick={() => toggleModal(match?._id, currentState, setState)}
            >
              Cancel
            </Button>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ListingModal;
