import React, { useEffect, useMemo } from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Alert, Spinner } from "reactstrap";
import { RenderIf, amountText } from "../../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as actions from "../../../store/repricer/actions";
import { Link } from "react-router-dom";
import PriceChangeHistory from "./PriceChangeHistoryGraph";
import { size } from "lodash";
import { MARKET_PLACES } from "../../../constants";

function ProductDetails () {
  const { platform, itemId, mpAccountId } = useParams();
  const { error, listingDetails } = useSelector((state) => state.Repricer);
  const dispatch = useDispatch();

  const [isAmazon, dataColumns] = useMemo(() => {
    const isAmazon = platform === MARKET_PLACES.amazon;
    return [
      isAmazon,
      [
        {
          title: "Brand",
          content: listingDetails.brand || "N/A",
        },
        {
          title: isAmazon ? "ASIN" : "Item ID",
          content: isAmazon ? listingDetails.asin : listingDetails.marketplace_item_id || "N/A",
        },
        {
          title: "SKU",
          content: listingDetails.sku || "N/A",
        },
        {
          title: "COGS",
          content: amountText(listingDetails.cost),
        },
        {
          title: "Value of Current Stock",
          content: amountText(listingDetails.listed_price * listingDetails.stock) || 0,
        },
        {
          title: "Est. Profit at Listed Price",
          content: amountText(listingDetails.profit?.profit),
        },
        ...(isAmazon
          ? [
              {
                title: "Competitors",
                content: +listingDetails.no_of_competitors,
              },
            ]
          : []),
      ],
    ];
  }, [platform, listingDetails]);

  useEffect(() => {
    dispatch(actions.fetchRepricerListingDetails(isAmazon && platform, itemId, mpAccountId));
  }, [platform, itemId]);

  return (
    <>
      <RenderIf isTrue={error}>
        <Alert color="danger">
          <i className="bx bx-info-circle pr-2"></i>
          {error}
        </Alert>
      </RenderIf>

      <div className="d-flex flex-column w-100 h-100">
        <div className="d-inline-flex flex-row align-items-center py-4">
          <Link to="/listings_new">
            <i title="Back" className="bx bx-sm bx-arrow-back text-primary mr-2 cursor-pointer" />
          </Link>
          <h1 className="mt-2 font-size-18 text-uppercase">Product Details</h1>
        </div>

        <Card>
          <CardBody
            className={`d-flex flex-column flex-md-row align-items-center ${
              !size(listingDetails) && "justify-content-center"
            } gap-3`}
          >
            <RenderIf
              isTrue={size(listingDetails)}
              fallback={<Spinner color="primary" style={{ width: "36px", height: "36px" }} />}
            >
              <div className="w-100 d-flex flex-column align-items-start">
                <CardTitle className="font-size-18 text-white mb-4">{listingDetails.listing_title}</CardTitle>
                <div className="w-100 row pl-2">
                  {dataColumns.map((item, index) => (
                    <div key={index} className="col-3 p-2">
                      <CardSubtitle className="mb-2">{item.title}</CardSubtitle>
                      <span className="inventory-color">{item.content}</span>
                    </div>
                  ))}
                </div>
              </div>
            </RenderIf>
          </CardBody>
        </Card>
        <PriceChangeHistory />
      </div>
    </>
  );
}

export default ProductDetails;
