import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import { addMismatchProduct } from "../../../store/actions";
import { getUserInfo } from "../../../utils/common";

const MismatchModal = (props) => {
  const { isOpen, toggleModal, match, currentState, setState } = props;
  const dispatch = useDispatch();
  const { selectedMarketplace } = useSelector((state) => state.ProfitAnalyzer);
  const currentUser = getUserInfo()?.email;
  const data = match?._source;

  const handleMismatch = () => {
    dispatch(
      addMismatchProduct({
        email: currentUser,
        matchableId: data?.matchableId,
        matchableType: data?.matchableType,
        marketPlace: selectedMarketplace,
      }),
    );
    toggleModal(match?._id, currentState, setState);
  };

  return (
    <Modal size="md" isOpen={isOpen} toggle={() => toggleModal(match?._id, currentState, setState)}>
      <div className="pb-4 px-3 pt-3">
        <div className="d-flex justify-content-end">
          <button
            onClick={() => toggleModal(match?._id, currentState, setState)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="bx bx-sm bx-x" style={{ color: "#FFFFFF" }}></i>
          </button>
        </div>
        <div className="">
          <div className="d-flex justify-content-center pb-3">
            <i className="bx bx-question-mark text-primary" style={{ fontSize: "96px" }} />
          </div>
          <div className="d-flex justify-content-center">
            <h3>Are you sure you want to proceed?</h3>
          </div>
          <div className="d-flex justify-content-center">
            <p>This item will be marked as mismatch.</p>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              outline
              className="waves-effect waves-light mr-3"
              onClick={() => toggleModal(match?._id, currentState, setState)}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleMismatch()}>
              Mark as Mismatch
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MismatchModal;
