import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const ColumnSelector = (props) => {
  const { tableHeaders, columns, setColumns } = props;
  const [selectColumnsDropDown, setSelectColumnsDropDown] = useState(false);
  return (
    <ButtonDropdown
      isOpen={selectColumnsDropDown}
      toggle={() => setSelectColumnsDropDown(!selectColumnsDropDown)}
      className="mr-3"
    >
      <DropdownToggle tag="i" className="bx bx-sm bx-columns text-primary ml-4 cursor-pointer" />
      <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
        {columns.Uncheck && (
          <DropdownItem
            onClick={() => {
              setColumns({
                Check: true,
                ...tableHeaders.reduce((acc, x) => {
                  acc[x.title] = false;
                  return acc;
                }, {}),
              });
            }}
          >
            Uncheck All
          </DropdownItem>
        )}
        {columns.Check && (
          <DropdownItem
            onClick={() =>
              setColumns({
                Uncheck: true,
                ...tableHeaders.reduce((acc, x) => {
                  acc[x.title] = true;
                  return acc;
                }, {}),
              })
            }
          >
            Check All
          </DropdownItem>
        )}
        {tableHeaders
          ?.filter((x) => !x.show)
          ?.map((header, key) => (
            <DropdownItem key={`_select_columns_+ ${key}`}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={columns[header.title]}
                  onChange={() => false}
                />
                <label
                  className="custom-control-label"
                  onClick={(e) => {
                    e.stopPropagation();
                    setColumns({ ...columns, [header.title]: !columns[header.title] });
                  }}
                >
                  {header.title}
                </label>
              </div>
            </DropdownItem>
          ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ColumnSelector;
