import { takeLeading, takeEvery, fork, all, put, call, select } from "redux-saga/effects";
import { getUserInfo } from "../../utils/common";

import * as api from "../../api";
import * as types from "./actionTypes";
import * as actions from "./actions";
import { formatFilter } from "../../pages/ProfitAnalyzer/common";
const currentUser = getUserInfo()?.email;

// ************************************************ //
function * fetchMatchesSaga ({ payload }) {
  try {
    const res = yield call(api.fetchMatches, { body: payload });
    yield put(actions.fetchMatchesSuccess(res));
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchFetchMatches () {
  yield takeLeading(types.FETCH_MATCHES, fetchMatchesSaga);
}
// ************************************************ //

// ************************************************ //
function * addNewSavedFilterSaga ({ payload }) {
  try {
    const newFilter = yield call(api.addNewSavedFilter, { body: payload });
    if (newFilter?.success) {
      const savedFilterRes = yield call(api.fetchSavedFilters, { params: { email: currentUser } });
      yield put(actions.fetchSavedFiltersSuccess(savedFilterRes));
    } else {
      yield put(actions.apiError(newFilter?.message));
    }
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchAddNewSavedFilter () {
  yield takeLeading(types.ADD_NEW_SAVED_FILTER, addNewSavedFilterSaga);
}
// ************************************************ //

function * fetchFiltersSaga ({ payload }) {
  try {
    const res = yield call(api.fetchSavedFilters, { params: payload });
    yield put(actions.fetchSavedFiltersSuccess(res));
    const states = yield select((state) => state);
    const { filters, selectedMarketplace, columnFilters } = states.ProfitAnalyzer;
    const filter = formatFilter(filters, selectedMarketplace);
    yield put(
      actions.fetchMatches({
        filter,
        marketplace: selectedMarketplace,
        email: currentUser,
        columnFilters,
      }),
    );
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchFetchFilters () {
  yield takeEvery(types.FETCH_SAVED_FILTERS, fetchFiltersSaga);
}
// ************************************************ //

// ************************************************ //

function * updateSavedFilterSaga ({ params, payload }) {
  try {
    yield call(api.updateSavedFilter, { params, body: payload });
    const savedFilterRes = yield call(api.fetchSavedFilters, { params: { email: currentUser } });
    yield put(actions.fetchSavedFiltersSuccess(savedFilterRes));
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchUpdateSavedFilter () {
  yield takeEvery(types.UPDATE_SAVED_FILTER, updateSavedFilterSaga);
}

// ************************************************ //

// ************************************************ //

function * addMismatchProductSaga ({ payload }) {
  try {
    yield call(api.addMismatchProduct, { body: payload });
    const states = yield select((state) => state);
    const { filters, selectedMarketplace, columnFilters } = states.ProfitAnalyzer;
    const filter = formatFilter(filters, selectedMarketplace);
    yield put(
      actions.fetchMatches({
        filter,
        marketplace: selectedMarketplace,
        email: currentUser,
        columnFilters,
      }),
    );
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchAddMismatchProduct () {
  yield takeEvery(types.ADD_MISMATCH_PRODUCT, addMismatchProductSaga);
}

// ************************************************ //

// ************************************************ //

function * checkProductEligibilitySage ({ params, payload }) {
  try {
    const res = yield call(api.checkProductEligibility, { params });
    yield put(actions.checkProductEligibilitySuccess({ ...res, ...payload }));
  } catch (error) {
    yield put(actions.apiError(error));
  }
}

export function * watchCheckProductEligibility () {
  yield takeEvery(types.CHECK_PRODUCT_ELIGIBILITY, checkProductEligibilitySage);
}

function * ProfitAnalyzer () {
  yield all([
    fork(watchFetchMatches),
    fork(watchAddNewSavedFilter),
    fork(watchFetchFilters),
    fork(watchUpdateSavedFilter),
    fork(watchAddMismatchProduct),
    fork(watchCheckProductEligibility),
  ]);
}

export default ProfitAnalyzer;
