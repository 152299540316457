import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Alert, Row, Col, Label, Input, Button, FormGroup } from "reactstrap";
import Modal from "../../../components/Common/Modal";
import Select from "react-select";

// utils
import { isStrongPassword, toPascalCase, verifyRole } from "../../../utils/common";

// actions
import { fetchAccounts, setAddUserModal, setEditUserModal, updateUser, createUser } from "../../../store/actions";

const UserModal = (props) => {
  const canAddManager = verifyRole("admin", "owner");
  const [isPasswordUpdate, setPasswordUpdate] = useState(false);

  const dispatch = useDispatch();
  const { accounts, roles, selectedUser, error, success, loading, editUserModalIsOpen, addUserModalIsOpen } =
    useSelector((state) => state.User);

  if (!canAddManager) delete roles.manager;

  function handleUpdateUser (data) {
    dispatch(updateUser(data));
  }

  function handleCreateUser (data) {
    dispatch(createUser(data));
  }

  const validate = (values) => {
    const errors = {};
    if (props.type === "edit" && !isPasswordUpdate) {
      Object.keys(values).forEach((key) => {
        if (values[key] === "" && !["old_Password", "password"].includes(key)) errors[key] = "Field is Required";
      });
    } else {
      Object.keys(values).forEach((key) => {
        if (values[key] === "") errors[key] = "Field is Required";
      });
    }

    if (values.password && !isStrongPassword(values.password))
      errors.password =
        "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum.";

    return errors;
  };

  const initialValues = {
    email: "",
    username: "",
    account_id: "",
    role: "",
  };

  if (props.type === "edit" && isPasswordUpdate) {
    initialValues.old_password = "";
    initialValues.password = "";
  } else if (props.type === "add") {
    initialValues.password = "";
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      if (props.type === "edit" && selectedUser) {
        handleUpdateUser({
          data: {
            ...values,
            role: values.role.value,
            account_id: values?.account_id?.value,
          },
          id: selectedUser.id,
        });
      } else if (props.type === "add") {
        handleCreateUser({
          data: {
            ...values,
            status: "complete",
            role: values.role.value,
            account_id: values?.account_id?.value,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (selectedUser && props.type === "edit") {
      Object.keys(initialValues).forEach((key) => {
        selectedUser[key] && formik.setFieldValue(key, selectedUser[key], false);
        key === "role" &&
          formik.setFieldValue(
            key,
            {
              label: toPascalCase(selectedUser[key]),
              value: selectedUser[key],
            },
            false,
          );
        key === "account_id" &&
          accounts.length &&
          formik.setFieldValue(
            key,
            {
              label: accounts?.find((x) => x.id === selectedUser[key])?.email,
              value: selectedUser[key],
            },
            false,
          );
      });
    }
  }, [selectedUser, isPasswordUpdate, accounts]);

  useEffect(() => {
    if (!accounts.length || !Object.keys(roles).length) {
      dispatch(fetchAccounts({}));
    }
  }, []);

  useEffect(() => {
    success && !loading && formik.resetForm();
  }, [success, loading]);

  const toggleModal = () => {
    props.type === "edit"
      ? dispatch(setEditUserModal(!editUserModalIsOpen))
      : dispatch(setAddUserModal(!addUserModalIsOpen));
  };

  return (
    <Modal size="md" isOpen={editUserModalIsOpen || addUserModalIsOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          {props.type === "edit" ? "Edit User" : "Add User"}
        </h5>
        <button onClick={toggleModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      <div className="modal-body">
        {error && (
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        )}

        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="6 mb-2">
              <Label>Email*</Label>
              <Input
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email
                ? (
                <span className="text-danger mt-1">{formik.errors.email}</span>
                  )
                : null}
            </Col>
            <Col lg="6 mb-2">
              <Label>Username*</Label>
              <Input
                name="username"
                value={formik.values.username}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={formik.values.email === "admin@ecomcircles.com"}
              />
              {formik.touched.username && formik.errors.username
                ? (
                <span className="text-danger mt-1">{formik.errors.username}</span>
                  )
                : null}
            </Col>

            {props.type === "edit" && (
              <Col lg="12 mb-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={() => false}
                    checked={isPasswordUpdate}
                  />
                  <label
                    className="custom-control-label"
                    onClick={() => {
                      setPasswordUpdate(!isPasswordUpdate);
                    }}
                  >
                    Update Password
                  </label>
                </div>
              </Col>
            )}

            {(isPasswordUpdate || props.type === "add") && (
              <Col lg="6 mb-2">
                <Label>Password*</Label>
                <Input
                  name="password"
                  value={formik.values.password || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password
                  ? (
                  <span className="text-danger mt-1">{formik.errors.password}</span>
                    )
                  : null}
              </Col>
            )}

            {isPasswordUpdate && (
              <Col lg="6 mb-2">
                <Label>Old Password*</Label>
                <Input
                  name="old_password"
                  value={formik.values.old_password || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.old_password && formik.errors.old_password
                  ? (
                  <span className="text-danger mt-1">{formik.errors.old_password}</span>
                    )
                  : null}
              </Col>
            )}

            <Col lg="6 mb-2">
              <FormGroup className="select2-container">
                <Label>Parent Account*</Label>
                <Select
                  name="account_id"
                  value={formik.values.account_id || ""}
                  onChange={(option) => {
                    formik.setFieldValue("account_id", option);
                  }}
                  options={accounts?.map((acc) => ({
                    label: acc.email,
                    value: acc.id,
                  }))}
                  placeholder={"Select an Account"}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
              {formik.touched.account_id && formik.errors.account_id
                ? (
                <span className="text-danger mt-1">{formik.errors.account_id}</span>
                  )
                : null}
            </Col>
            <Col lg="6 mb-2">
              <FormGroup className="select2-container">
                <Label>Role*</Label>
                <Select
                  name="role"
                  value={formik.values.role || ""}
                  onChange={(option) => {
                    formik.setFieldValue("role", option);
                  }}
                  options={Object.keys(roles).map((key) => ({
                    label: toPascalCase(key === "readOnly" ? "client" : key),
                    value: roles[key],
                  }))}
                  placeholder={"Select a Role"}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
              {formik.touched.role && formik.errors.role
                ? (
                <span className="text-danger mt-1">{formik.errors.role}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button
              type="reset"
              color="secondary"
              className="mr-3"
              onClick={() => {
                formik.handleReset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default UserModal;
