import Request from "../restClient";
import { makeQP } from "../../utils/common";
import { PROFIT_ANALYZER_FILTERS, PROFIT_ANALYZER_PARSER } from "../../constants";
import * as _ from "lodash";

export const fetchMatches = async ({ body }) => {
  return await getFilteredProducts(body);
};

export const checkProductEligibility = async ({ params }) => {
  return await Request({
    url: "/sp_api/is_restricted_item" + makeQP(params),
    method: "GET",
  });
};

export const addMismatchProduct = async ({ body }) => {
  const { email, matchableId, matchableType, marketPlace } = body;

  const userInfo = await getCurrentUser(email);
  if (!userInfo.success) return { success: false, message: userInfo.message };
  const userId = userInfo?.user?.hits?.hits[0]?._id;
  const document = {
    userId,
    matchableId,
    matchableType,
    marketPlace,
    productId: `${matchableId}-${matchableType}`,
  };
  const documentId = `${userId}-${matchableId}-${matchableType}-${marketPlace}`;
  return await upsertRecord("mismatch_products", documentId, document);
};

export const addNewSavedFilter = async ({ body }) => await addFilter("saved_filters", body);

export const updateSavedFilter = async ({ params, body }) => {
  const { _id } = params;
  if (!_id) return { success: false, message: "No filter id provided" };
  return await updateRecord("saved_filters", _id, body);
};

export const fetchSavedFilters = async ({ params }) => {
  const { email } = params;
  let processedFilters = {};
  let defaultFilter = "";
  let defaultMarketplace = "";
  try {
    const savedFilters = await search(
      {
        _source: {
          excludes: ["event"],
        },
        query: {
          bool: {
            must: [{ term: { "email.keyword": email } }],
          },
        },
      },
      "saved_filters",
    );

    savedFilters?.hits.hits?.forEach((filter) => {
      const { destination, name, filter: filterData, isDefault } = filter?._source;

      if (!processedFilters[destination]) {
        processedFilters[destination] = {};
      }
      if (isDefault) {
        defaultFilter = name;
        defaultMarketplace = destination;
      }

      processedFilters[destination][name] = { ...filterData, _id: filter?._id };
    });
  } catch (e) {
    processedFilters = {};
  }

  return {
    success: true,
    savedFilters: processedFilters,
    defaultFilter,
    defaultMarketplace,
    filters: PROFIT_ANALYZER_FILTERS,
  };
};

const getFilteredProducts = async (req) => {
  try {
    const { email, filter, columnFilters = {}, page = 0 } = req;

    const { data, totalCount } = await elasticFetchDataFromIndex("amazon_profits", filter, page, email, columnFilters);

    return { success: true, data, page, totalCount };
  } catch (e) {
    return { success: false, message: e.toString() };
  }
};

const getCurrentUser = async (email, createNew = false) => {
  try {
    const query = {
      query: {
        term: {
          email,
        },
      },
    };
    const user = await search(query, "users");
    const totalUsers = user?.hits?.total?.value || 0;
    if (totalUsers === 0) {
      if (createNew) return await addRecord("users", { email });

      return { success: false, message: "No user found" };
    }

    return { success: true, user };
  } catch (e) {
    return { success: false, message: e.toString() };
  }
};

const getMismatchedProducts = async (userId) => {
  try {
    const query = {
      query: {
        match: {
          userId,
        },
      },
      size: 10000,
    };
    const mismatchedProducts = await search(query, "mismatch_products");
    return {
      success: true,
      data: mismatchedProducts?.hits?.hits
        ?.map((hit) => {
          const productId = hit?._source?.matchableId;
          return productId;
        })
        .filter(Boolean),
    };
  } catch (e) {
    return { success: false, data: [], message: e.toString() };
  }
};

const elasticFetchDataFromIndex = async (index, filter, page, email, columnFilters = {}) => {
  try {
    const priceType = PROFIT_ANALYZER_PARSER[filter?.profitablity?.controls?.priceType?.value] || "lowestOffer";
    const valueType = PROFIT_ANALYZER_PARSER[filter?.profitablity?.controls?.valueType?.value] || "";
    const feeType = PROFIT_ANALYZER_PARSER[filter?.profitablity?.controls?.feeType?.value] || "FBA";

    const mustQueries = [];
    const mustNotQueries = [];

    const userInfo = await getCurrentUser(email, true);
    if (!userInfo.success) return { success: false, message: userInfo.message };
    const userId = userInfo?.user?.hits?.hits[0]?._id || userInfo?.data?._id;

    const misMatchedProductIds = await getMismatchedProducts(userId);
    const mismatchQuery =
      misMatchedProductIds && misMatchedProductIds?.length > 0
        ? { terms: { matchableId: misMatchedProductIds?.data } }
        : {};

    const isMismatchQueryValid = Object.keys(mismatchQuery).length > 0;

    Object.keys(columnFilters).forEach((column) => {
      const { option, value } = columnFilters[column];
      const { type, query } = getQueryFormat(option, column, value);
      if (type === "must") {
        mustQueries.push(query);
      } else if (type === "must_not") {
        mustNotQueries.push(query);
      }
    });

    const costType = `${priceType}${valueType}`;

    const notRestricted = filter?.otherSettings?.controls?.notRestricted?.checked;

    const suppliersObj = filter?.suppliers?.controls;
    const suppliers = [];
    const supplierConditions = {};

    _.forEach(suppliersObj, (supplier, key) => {
      const supplierKey = PROFIT_ANALYZER_PARSER[key.toLowerCase()];
      if (supplier.checked) {
        suppliers.push(supplierKey);
        if (supplier.options) {
          supplierConditions[supplierKey] = [];
          _.forEach(supplier.options, (option, conditionKey) => {
            if (option.checked) {
              supplierConditions[supplierKey].push(...PROFIT_ANALYZER_PARSER[conditionKey]);
            }
          });
        }
      }
    });

    const query = {
      _source: {
        excludes: ["event"],
      },
      query: {
        bool: {
          must: [{ term: { "costType.keyword": costType } }, { term: { "feeType.keyword": feeType } }, ...mustQueries],
          must_not: [...mustNotQueries, ...(isMismatchQueryValid ? [mismatchQuery] : [])],
        },
      },
      sort: [{ supplierCheckedAt: { order: "desc" } }],
      track_total_hits: true,
      size: 10,
      from: page * 10 || 0,
    };

    if (notRestricted) query.query.bool.must.push({ term: { isRestricted: false } });

    if (suppliers && suppliers.length > 0) {
      query.query.bool.must.push({ terms: { matchableType: suppliers } });
    }

    const conditionFilters = [];
    _.forEach(supplierConditions, (conditions, supplier) => {
      if (conditions.length > 0) {
        conditionFilters.push({
          bool: {
            must: [
              { term: { "matchableType.keyword": supplier } },
              { terms: { "condition.keyword": conditions } },
            ],
          },
        });
      } else if (suppliers.includes(supplier) && conditions.length === 0) {
        conditionFilters.push({
          term: { "matchableType.keyword": supplier },
        });
      }
    });

    if (conditionFilters.length > 0) {
      query.query.bool.must.push({
        bool: {
          should: conditionFilters,
        },
      });
    }

    const data = await search(query, index);

    const totalCount = data?.hits?.total?.value || 0;

    return { data: data?.hits?.hits || [], totalCount };
  } catch (err) {
    return { success: false, data: [], message: err.toString() };
  }
};

const getQueryFormat = (option, column, value) => {
  const columnKeyword = `${column}.keyword`;
  const numericValue = Number(value);
  switch (option) {
    case "<":
      return { type: "must", query: { range: { [column]: { lt: numericValue } } } };
    case ">":
      return { type: "must", query: { range: { [column]: { gt: numericValue } } } };
    case "==":
      return { type: "must", query: { term: { [column]: numericValue } } };
    case "contains":
      return { type: "must", query: { wildcard: { [columnKeyword]: `*${value}*` } } };
    case "notContains":
      return { type: "must_not", query: { wildcard: { [columnKeyword]: `*${value}*` } } };
    default:
      return { type: "must", query: {} };
  }
};

const search = async (query, index) => {
  try {
    const payload = {
      operation: "search",
      index,
      body: query,
    };
    const response = await Request({
      url: "/elastic",
      method: "POST",
      body: payload,
    });
    return response.result;
  } catch (err) {
    return null;
  }
};

const addFilter = async (index, document) => {
  try {
    const query = {
      query: {
        bool: {
          must: [
            { match: { email: document.email } },
            { match: { "name.keyword": document.name } },
            { match: { destination: document.destination } },
          ],
        },
      },
    };

    const searchResponse = await search(query, index);

    if (searchResponse && searchResponse?.hits?.total?.value > 0) {
      return {
        success: false,
        data: null,
        message: `Filter '${document.name}' with the same name, and marketplace already exists.`,
      };
    }

    const payload = {
      operation: "add",
      index,
      body: document,
    };
    const response = await Request({
      url: "/elastic",
      method: "POST",
      body: payload,
    });
    return { success: true, data: response.result, message: "Document added successfully." };
  } catch (err) {
    return { success: false, data: [], message: err.toString() };
  }
};

const addRecord = async (index, document) => {
  try {
    const payload = {
      operation: "add",
      index,
      body: document,
    };
    const response = await Request({
      url: "/elastic",
      method: "POST",
      body: payload,
    });
    return { success: true, data: response.result };
  } catch (err) {
    return { success: false, data: [], message: err.toString() };
  }
};

const updateRecord = async (index, id, document) => {
  try {
    const payload = {
      operation: "update",
      index,
      body: document,
      id,
    };
    const response = await Request({
      url: "/elastic",
      method: "POST",
      body: payload,
    });
    return { success: true, data: response.result };
  } catch (err) {
    return { success: false, data: [], message: err.toString() };
  }
};

const upsertRecord = async (index, id, document) => {
  try {
    const payload = {
      operation: "upsert",
      index,
      body: document,
      id,
    };
    const response = await Request({
      url: "/elastic",
      method: "POST",
      body: payload,
    });
    return { success: true, data: response.result };
  } catch (err) {
    return { success: false, data: [], message: err.toString() };
  }
};
