import { omit, size, values, isEmpty } from "lodash";
import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { ACCOUNT_STATUSES, NEW_DASHBOARD_URL, USER_ROLES } from "../../constants";
import {
  verifyRole,
  getUserInfo,
  isWHStaff,
  clearLocalStorage,
  isAdmin as isUserAdmin,
  isShopifyAPIURL,
  isUserHaveFullAccess,
  hasFullAccessOfAnyPlan,
  encode,
} from "../../utils/common";
import queryString from "query-string";
import { useSelector } from "react-redux";

const WH_ROUTES = {
  items: "/warehouse_items",
  storage: "/warehouse_storage",
  charges: "/warehouse_charges",
  returns: "/returns",
  outbounds: "/outbound-items",
  label_generation_stats: "/label_generation_stats",
  inbounds: "/inbound_items",
  dashboard: "/warehouse_dashboard",
  inventory: "/inventory-items",
  scanforms: "/scanforms",
};
const WAREHOUSE_ROUTES = values(WH_ROUTES);
const AGENCY_ROUTES = [
  "/agency",
  "/users",
  "/restricted_brands",
  "/exported_reports",
  "/profile_settings",
  "/restricted_items",
];
const REPRICER_ROUTES = ["/formulas", "/strategies"];
const PARTIAL_USER_ROUTES = [
  "/new_dashboard",
  "/listings_new",
  "/upload_listings_new",
  "/settings",
  "/inventory-items",
  "/returns",
  "/warehouse_charges",
  "/warehouse",
  "/exported_reports",
].concat(hasFullAccessOfAnyPlan() ? ["/tags", "/profit_analyzer"] : []);

const getPartialRoutes = (isStoreAdded) =>
  PARTIAL_USER_ROUTES.concat(
    isStoreAdded && hasFullAccessOfAnyPlan()
      ? ["/source_orders/:statusTab", "/source_orders_details/:marketplaceOrderId/:id"]
      : [],
  );

const showComponent = (props, showLayout, Layout, Component, title) =>
  showLayout === false ? (
    <Component {...props} />
  ) : (
    <Layout title={title}>
      <Component {...props} />
    </Layout>
  );
const isAgencyChild = ({ agency_id: agencyId, account_id: accountId, role }, path) => {
  return agencyId && agencyId !== accountId && path.includes("/agency") && role !== USER_ROLES.admin;
};

const isShowWHComponet = (roles, path, allowUsers, isShowReturn, isTwoStep) => {
  const { role, account_id: accId } = getUserInfo() || {};
  if (path === "/purchase_order" && role === "admin") return true;
  if (!roles?.includes(role) && path === WH_ROUTES.charges) return false;
  if (allowUsers?.includes(accId)) return true;
  if (role === USER_ROLES.whStaff) {
    if (WAREHOUSE_ROUTES.some((x) => path.includes(x))) return true;
    return false;
  }
  if (path === "/warehouse") {
    if (verifyRole("admin", "owner")) return true;
    return false;
  }
  if (roles && !verifyRole(...roles)) return false;
  if (values(omit(WH_ROUTES, "returns")).some((x) => path.includes(x)) && isTwoStep) return true;
  if (values(omit(WH_ROUTES, "items")).some((x) => path.includes(x)) && isShowReturn) return true;
};

const isAdmin = ({ role, agency_id: agencyId }, path) =>
  agencyId && path.includes("/agency") && role === USER_ROLES.admin;

const isAgency = (path) => !isWHStaff() && !getUserInfo()?.agency_id && !AGENCY_ROUTES.includes(path);
const hasPartialRouteAccess = (path, roles = [], isStoreAdded) =>
  isUserHaveFullAccess() ||
  getPartialRoutes(isStoreAdded).includes(path) ||
  (isUserAdmin() && verifyRole(...roles));

const hasRepricerAccess = (path) => isUserHaveFullAccess("repricer") && REPRICER_ROUTES.includes(path);

const encodedUserEmail = getUserInfo()?.user_email ? encode(getUserInfo()?.user_email) : "";
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  path,
  showLayout,
  roles,
  allowUsers,
  title,
  allowedUserIds,
  isTwoStep,
  isShowReturn,
}) => {
  const { account: tenantObj } = useSelector((state) => state.Settings);
  const isStoreAdded = !!tenantObj?.data?.stores_count;
  return (
    <Route
      path={path}
      render={(props) => {
        if (getUserInfo()?.status === "pending_confirm" && !isWHStaff()) {
          return props.location?.pathname === "/email_verification/" ? (
            <></>
          ) : (
            <Redirect to={{ pathname: `/email_verification/${encodedUserEmail}`, state: { from: props.location } }} />
          );
        }
        if (path === "/authorise_stamps") {
          const { code } = queryString.parse(document.location.search);
          return (
            <Redirect
              to={{ pathname: "/settings", search: `?account=true&code=${code}`, state: { from: props.location } }}
            />
          );
        } else if (!localStorage.getItem("authTokenJwt") || !getUserInfo()?.exp) {
          const shopifyRedirectParams = queryString.parse(document.location.search);
          const isShopify = isShopifyAPIURL(shopifyRedirectParams);
          if (isShopify) localStorage.setItem("shopifyParams", JSON.stringify(shopifyRedirectParams));
          clearLocalStorage();
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        } else if (isAgency(path) && getUserInfo()?.role !== "admin")
          return <Redirect to={{ pathname: "/agency", state: { from: props.location } }} />;
        else if (isAgencyChild(getUserInfo(), path))
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        else if (isAdmin(getUserInfo(), path))
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        else if (isShowWHComponet(roles, path, allowUsers, isShowReturn, isTwoStep))
          return showComponent(props, showLayout, Layout, Component, title);
        else if (isWHStaff() && !path.includes(WH_ROUTES.dashboard))
          return <Redirect to={{ pathname: WH_ROUTES.dashboard, state: { from: props.location } }} />;
        else if (hasRepricerAccess(path)) return showComponent(props, showLayout, Layout, Component, title);
        else if (!hasPartialRouteAccess(path, roles, isEmpty(tenantObj) || isStoreAdded))
          return <Redirect to={{ pathname: NEW_DASHBOARD_URL, state: { from: props.location } }} />;
        else if (["/warehouse", ...WAREHOUSE_ROUTES].includes(path) && !isTwoStep && !isShowReturn) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        } else if (roles !== undefined && !verifyRole(...roles)) {
          if (path === "/settings" && getUserInfo()?.id === 5335)
            return showComponent(props, showLayout, Layout, Component, title);
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        } else if (allowUsers !== undefined && !allowUsers.includes(getUserInfo().account_id)) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        } else if (allowedUserIds !== undefined && !allowedUserIds.includes(getUserInfo().id)) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        } else if (size(tenantObj) && tenantObj.data?.status !== ACCOUNT_STATUSES.active && path.includes("/emails")) {
          return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
        }
        return showComponent(props, showLayout, Layout, Component, title);
      }}
    />
  );
};

export default withRouter(Authmiddleware);
