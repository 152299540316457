import { Country, City, State } from "country-state-city";
import { reduce, pick, values } from "lodash";

export const ENV = process.env;
const {
  REACT_APP_APPLICATION_ID,
  REACT_APP_WH_SUPPORT_USERS,
  REACT_APP_LABEL_GENERATION_USERS,
  REACT_APP_STAMPS_CLIENT_ID,
  REACT_APP_STAMPS_REDIRECT_URL,
  REACT_APP_CHANGE_WH_ALLOWED_USERS,
  REACT_APP_SHOPIFY_CLIENT_ID,
  REACT_APP_SHOPIFY_REDIRECT_URL,
  REACT_APP_SHOPIFY_ACCESS_SCOPES,
  REACT_APP_DELETE_ACCOUNT_USERS,
  REACT_APP_SEND_ERROR_EMAIL,
} = ENV;

export const DELETE_ACCOUNT_USERS = JSON.parse(REACT_APP_DELETE_ACCOUNT_USERS || "[]");
export const SEND_ERROR_EMAIL = JSON.parse(REACT_APP_SEND_ERROR_EMAIL || "[]");
export const WH_SUPPORT_USERS = JSON.parse(REACT_APP_WH_SUPPORT_USERS || "[]");
export const LABEL_GENERATION_USERS = JSON.parse(REACT_APP_LABEL_GENERATION_USERS || "[]");
export const GEN_REPORT_USER_IDS = JSON.parse(ENV.REACT_APP_GEN_REPORT_USER_IDS || "[]");
export const CHANGE_WH_ALLOWED_USERS = JSON.parse(REACT_APP_CHANGE_WH_ALLOWED_USERS || "[]");
export const OTP_ACCESS_USER_IDS = JSON.parse(ENV.REACT_APP_OTP_ACCESS || "[]");
export const SAADANI_EMAILS = JSON.parse(ENV.REACT_APP_SAADANI_EMAILS || "[]");

export const INVENTORY_ALLOWED_ACCS = [2];
export const TOKEN_REFRESHED_AT_KEY = "tokenRefreshedAt";
export const EXTENSION_LINK = "https://chrome.google.com/webstore/detail/walmart-and-other-seller/ldnklphedmbceklcaackajhikmnlnlld?hl=en";
export const STATUS_COLOR_CODE = {
  new: "info",
  processing: "primary",
  marked_not_shipped: "secondary",
  partially_processed: "success",
  pending_cancellation: "warning",
  shipped: "success",
  errored: "danger",
  cancelled: "danger",
  checked_in: "warning",
  at_warehouse: "success",
  ignored: "secondary",
  processed: "success",
  not_found_in_co: "danger",
  not_orderable_in_co: "danger",
  unshipped: "warning",
  Created: "warning",
  shipped_with_tba: "warning",
  delivered_with_tba: "warning",
  Unshipped: "warning",
  Acknowledged: "success",
  Shipped: "success",
  Delivered: "success",
  showing_delivered: "success",
  Cancelled: "danger",
  Canceled: "danger",
  in_queue: "primary",
  checking: "primary",
  issue: "warning",
  completed: "success",
  complete: "success",
  incomplete: "warning",
  in_transit: "success",
  delivered: "success",
  undeliverable: "danger",
  lost: "danger",
  delayed: "warning",
  manual_fulfillment: "danger",
  inbound: "primary",
  refund: "danger",
  wh_shipped: "success",
  wh_delivered: "info",
  stranded: "warning",
  initiated: "warning",
  "Moved to Inventory": "danger",
  "Received at warehouse": "success",
  "Awaiting Shipment": "danger",
  "Awaiting from customer": "warning",
  "Awaiting from warehouse": "warning",
  "Awaiting warehouse": "danger",
  "Return Completed": "success",
  paid: "success",
  draft: "danger",
  tracking_items: "warning",
  returns: "danger",
  purchase_orders: "info",
};

export const NEW_DASHBOARD_URL = "/new_dashboard";
export const PROFIT_ANALYZER_URL = "/profit_analyzer";
export const SIGN_UP_SERVICES = {
  extension: "extension",
  repricer: "repricer",
  wfs_calculator: "wfs_calculator",
};

export const METADATA_KEYS = [
  "oversized_handling_fees",
  "storage_per_unit_fees",
  "return_handling_fees",
  "tracking_uploads",
  "buying_accounts",
  "va_accounts",
  "listings",
  "orders",
];

export const RETURNED_ITEM_ISSUES = {
  itemNotFound: "item_not_found",
  lost: "lost",
  emptyBox: "empty_box",
  wrongItem: "wrong_item",
  damaged: "damaged",
  invalidTracking: "invalid_tracking",
  addressMismatch: "address_mismatch",
  other: "other",
};

export const WH_CHARGE_HISTORY_STATUSES = {
  done: "processed",
  pending: "failed",
  pre_paid: "pre paid",
  not_billed: "not_billed",
};
export const PRIME_ACC_TYPES = ["personal", "personal_prime", "business", "business_prime", "any"];

export const ORDER_STATUS_MAPPING = {
  wh_shipped: "Shipped To Warehouse",
  wh_delivered: "Delivered To Warehouse",
};

export const MARKETPLACE_ORDER_STATUSES = {
  new: "new",
  refund: "refund",
  processing: "processing",
  partially_processed: "partially_processed",
  processed: "processed",
  shipped: "shipped",
  errored: "errored",
  cancelled: "cancelled",
};

export const LISTING_TYPES = { normal: "normal", "2-step": "2-step" };

export const ECS_STATUS_COLOR_CODE = (status) =>
  ({
    provisioning: "primary",
    running: "success",
    stopped: "danger",
    deprovisioning: "warning",
    pending: "warning",
  }[status?.toLowerCase()]);

export const MATCHING_SYSTEM_FLAGS = [
  "isPackMatch",
  "isBrandMatch",
  "isColorMatch",
  "isSizeMatch",
  "isMpnMatch",
  "isWeightMatch",
  "isDimensionMatch",
];

export const INVENTORY_PRODUCTS_STATUSES = {
  stranded: "stranded",
  discard_initiated: "discard_initiated",
  liquidation_initiated: "liquidation_initiated",
  donate_initiated: "donate_initiated",
  return_initiated: "return_initiated",
  fba: "fba",
  wfs: "wfs",
  discarded: "discarded",
  donated: "donated",
  returned_to_customer: "returned_to_customer",
  returned_to_supplier: "returned_to_supplier",
};

export const INVENTORY_ITEM_TYPES = {
  two_step: "2 Step",
  return: "return",
};

export const INVENTORY_WH_STATUS = {
  incomplete: "incomplete",
  completed: "completed",
  blocked: "blocked",
};

export const ORDER_TRACKING_STATUSES = {
  unshipped: "unshipped",
  shipped: "shipped",
  in_transit: "in_transit",
  delivered: "delivered",
  undeliverable: "undeliverable",
  lost: "lost",
  delayed: "delayed",
  cancelled: "cancelled",
  refund: "refund",
};

export const SHIPMENT_LINE_STATUSES = {
  incomplete: "incomplete",
  complete: "complete",
  checked_in: "checked_in",
  closed: "closed",
};

export const MARKETPLACE_STATUSES = {
  Created: "Created",
  Acknowledged: "Acknowledged",
  Delivered: "Delivered",
  Refund: "Refund",
  Shipped: "Shipped",
  Cancelled: "Cancelled",
  partially_shipped: "Partially_Shipped",
  PendingAvailability: "PendingAvailability",
  Pending: "Pending",
  Unshipped: "Unshipped",
  PartiallyShipped: "PartiallyShipped",
  Canceled: "Canceled",
  Unfulfillable: "Unfulfillable",
};

export const UN_PROCESSED_STATUSES = [
  "new",
  "checking",
  "manual_checking",
  "processing",
  "delayed",
  "errored",
  "in_queue",
  "refund",
];

export const PROCESSED_STATUSES = [
  "partially_processed",
  "processed",
  "shipped",
  "marked_not_shipped",
  "completed",
  "manual_fulfillment",
  "shipped_with_tba",
  "delivered_with_tba",
  "wh_delivered",
  "wh_shipped",
];

export const ADMIN_IDS = [1, 16, 17, 23, 27, 298, 530, 2062, 4593, 4013];

export const TRACKING_ITEM_FILTERS = {
  inbound: "checked_in",
  at_warehouse: "at_warehouse",
  shipped: "shipped",
  completed: "completed",
  stranded: "stranded",
  refund: "refund",
};

export const LABEL_STATUSES = { created: "created", draft: "draft", cancel: "cancel" };

export const TRACKING_ITEMS_STATUSES = { on_hold: "on_hold", not_received: "not_received" };

export const RETURN_INTERNAL_STATUSES = { open: "open", closed: "closed" };

export const TRACKING_ITEM_ISSUES = {
  genericIssue: "Generic Issue",
  orderNotFound: "Order not found in ShipStation",
  lost: "Lost",
  invalidTracking: "Invalid Tracking",
  wrongItem: "Wrong Item",
  damagedItem: "Damaged Item",
  emptyBox: "Empty box",
  itemMismatch: "Item Mismatch",
  other: "Other",
};

export const WAREHOUSE_TRACKING_STATUSES = {
  checked_in: "checked_in",
  at_warehouse: "at_warehouse",
};

export const SOURCE_ORDER_STATUSES = {
  new: "new",
  in_queue: "in_queue",
  checking: "checking",
  manual_checking: "manual_checking",
  errored: "errored",
  processing: "processing",
  manual_fulfillment: "manual_fulfillment",
  partially_processed: "partially_processed",
  processed: "processed",
  delayed: "delayed",
  wh_shipped: "wh_shipped",
  wh_delivered: "wh_delivered",
  shipped_with_tba: "shipped_with_tba",
  marked_not_shipped: "marked_not_shipped",
  shipped: "shipped",
  completed: "completed",
  delivered_with_tba: "delivered_with_tba",
  refund: "refund",
  cancelled: "cancelled",
  pending_cancellation: "pending_cancellation",
};

export const OPEN_ORDER_STATUSES = values(pick(SOURCE_ORDER_STATUSES, ["new", "in_queue", "checking", "processing", "errored", "manual_fulfillment", "manual_checking"]));

export const CREDIT_TYPES = ["AO", "BCE", "FedEx"];

export const BULK_SOURCE_ORDER_STATUSES = {
  completed: "completed",
  shipped: "shipped",
  new: "new",
};

export const BULK_LISTINGS_VERIFIED_MATCH_STATUSES = {
  verified: true,
  not_verified: false,
};

export const SUPPLIER_ORDER_STATUSES = {
  processed: "processed",
  errored: "errored",
  cancelled: "cancelled",
  ignored: "ignored",
  refund: "refund",
};

export const ISSUE_OPTIONS = {
  "Cancel Request": "Cancel Request",
  "Supplier Cancelled Order": "Supplier Cancelled Order",
  "Shipping Due in 24 hrs": "Shipping Due in 24 hrs",
  "Tracking Number Invalid": "Tracking Number Invalid",
  "Tracking Number Missing": "Tracking Number Missing",
  "Product Mismatch": "Product Mismatch",
  Critical: "Critical Order",
  Other: "Other",
};

export const ACCOUNT_STATUSES_DROPDOWN = {
  on_boarding: "on_boarding",
  trial: "trial",
  subscribed: "subscribed",
  canceled: "canceled",
  payment_failed: "payment_failed",
};

export const ACCOUNT_STATUSES = {
  active: "active",
  in_active: "in_active",
  in_trial: "in_trial",
  payment_failed: "payment_failed",
  hold: "on_hold",
  cancelled: "cancelled",
};

export const EC_REPORT_TYPES = {
  order_report: "order_report",
  payment_report: "payment_report",
  fifty_fifty_orders_report: "50/50_orders_report",
  va_performance_report: "va_performance_report",
  order_processor_report: "order_processor_report",
  transaction_report: "transaction_report -> 2",
  order_details_report: "order_details_report -> 2",
  settlement_report: "settlement_report -> 2",
  store_front_orders_report: "store_front_orders_report -> 2",
  marketplace_orders_report: "marketplace_orders_report -> 2",
  orders_report: "orders_report -> 2",
  inventory_report: "inventory_report -> 2",
  inventory_insights_report: "inventory_insights_report -> 2",
  returns_report: "returns_report -> 2",
  wh_label_report: "wh_label_report -> 2",
};

export const REPORT_STATUSES = { pending: "pending", done: "done", failed: "failed" };

export const CANCEL_MARKETPLACE_ORDER_OPTIONS = {
  amazon: [
    "NoInventory",
    "ShippingAddressUndeliverable",
    "BuyerCanceled",
    "GeneralAdjustment",
    "CarrierCreditDecision",
    "RiskAssessmentInformationNotValid",
    "CarrierCoverageFailure",
    "CustomerReturn",
    "MerchandiseNotReceived",
    "CannotVerifyInformation",
    "PricingError",
    "RejectOrder",
    "WeatherDelay",
  ],
  walmart: [
    "CUSTOMER_REQUESTED_SELLER_TO_CANCEL",
    "SELLER_CANCEL_OUT_OF_STOCK",
    "SELLER_CANCEL_FRAUD_STOP_SHIPMENT",
    "SELLER_CANCEL_PRICING_ERROR",
    "SELLER_CANCEL_ADDRESS_NOT_SERVICABLE",
  ],
};

export const WH_NOTICES_TYPES = {
  tracking_items: "tracking_items",
  returns: "returns",
  inventory_products: "inventory_products",
  shipment_lines: "shipment_lines",
};

export const SHIPPING_CARRIER = [
  "4PX",
  "AFL/Fedex",
  "AUSSIE_POST",
  "Amazon Logistics",
  "Aramex",
  "Australia Post",
  "Blue Package",
  "BlueDart",
  "Bluecare Express",
  "Canada Post",
  "China Post",
  "Chrono Express",
  "Chronopost",
  "City Link",
  "Correios",
  "Correos",
  "DHL",
  "DHL Global Mail",
  "DPD",
  "DTDC",
  "Delhivery",
  "Deutsche Post",
  "EUB",
  "Endopack",
  "Fastway",
  "FedEx",
  "FEDEX_JP",
  "FedEx SmartPost",
  "First Flight",
  "GLS",
  "GO!",
  "Hermes Logistik Gruppe",
  "India Post",
  "JP_EXPRESS",
  "La Poste",
  "Lasership",
  "MRW",
  "NITTSU",
  "Nacex",
  "Newgistics",
  "NipponExpress",
  "OSM",
  "OnTrac",
  "Other",
  "Otro",
  "Overnite Express",
  "Parcelforce",
  "Parcelnet",
  "Poste Italiane",
  "Professional",
  "Royal Mail",
  "SAGAWA",
  "SDA",
  "SF Express",
  "SagawaExpress",
  "Seur",
  "Smartmail",
  "Streamlite",
  "TNT",
  "Target",
  "Toll Global Express",
  "UPS",
  "UPS Mail Innovations",
  "USPS",
  "YAMATO",
  "YANWEN",
  "YamatoTransport",
  "Yodel",
  "Yun Express",
];

export const BCE_TRACKING_BASE_URL = "https://www.bluecare.express/Tracking?trackingReference=";
export const TRACKING_SERVICES = ["FedEx", "UPS", "USPS"];

export const IL_WH_ID = 152;

export const MARKET_PLACES = {
  amazon: "amazon",
  walmart: "walmart",
  ebay: "ebay",
  lucky_vitamin: "lucky_vitamin",
  home_depot: "home_depot",
  target: "target",
  zoro: "zoro",
  zappos: "zappos",
  overstock: "overstock",
  wayfair: "wayfair",
  costco: "costco",
  lowes: "lowes",
  cool_pet_stuff: "cool_pet_stuff",
  az_importer: "az_importer",
  shopify: "shopify",
  facebook: "facebook",
};

export const STATUS = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  ACTIVE: "PUBLISHED",
  INACTIVE: "UNPUBLISHED",
};

export const ALL_SUPPLIERS = {
  ...MARKET_PLACES,
  boscovs: "boscovs",
  bestbuy: "bestbuy",
  dsw: "dsw",
  fleetfarm: "fleetfarm",
  farmandflee: "farmandflee",
  homedepot: "homedepot",
  ifulfil: "ifulfil",
  luckyvitamin: "luckyvitamin",
  samsandclub: "samsandclub",
  samsclub: "samsclub",
  topdawg: "topdawg",
  vitacost: "vitacost",
  walgreen: "walgreen",
  stuller: "stuller",
  coolpetstuff: "coolpetstuff",
  azimporter: "azimporter",
  newegg: "newegg",
  bjs: "bjs",
  sixpm: "sixpm",
  costway: "costway",
  menards: "menards",
  warehouse: "warehouse",
  thefulfiller: "thefulfiller",
  plumberstock: "plumberstock",
};

export const SUPPLIER_NAMES = { ifulfill: "ifulfill", normal: "normal" };

export const MATCHING_SYSTEM_SUPPLIERS = {
  amazon: "amazon",
  walmart: "walmart",
  homedepot: "homedepot",
  dsw: "dsw",
  samsclub: "samsclub",
  zappos: "zappos",
  topdawg: "topdawg",
  zoro: "zoro",
  wayfair: "wayfair",
  costco: "costco",
  ebay: "ebay",
  ssisports: "ssisports",
  bestbuy: "bestbuy",
  boscovs: "boscovs",
  newegg: "newegg",
  bjs: "bjs",
  vitacost: "vitacost",
  plumberstock: "plumbersStock",
};

export const REFRESHERS_SUPPLIERS = {
  all: "all",
  amazon: "amazon",
  walmart: "walmart",
  homedepot: "home_depot",
  dsw: "dsw",
  samsclub: "samsclub",
  zappos: "zappos",
  topdawg: "topdawg",
  zoro: "zoro",
  wayfair: "wayfair",
  costco: "costco",
  ebay: "ebay",
  ssisports: "ssisports",
  luckvitamin: "lucky_vitamin",
  overstock: "overstock",
  sixpm: "sixpm",
};

export const OFFER_PRIORITY = {
  "FBA Only": "fba",
  "FBM Only": "fbm",
  "FBA + FBM - Choose cheapest": "fba_fbm_c",
  "FBA + FBM - Prioritize FBA": "fba_fbm",
  "FBA + FBM - Prioritize FBM": "fbm_fba",
};

export const WALMART_OFFER_TYPE = {
  "FBM Only": "fbm",
  "Walmart Only": "walmart_only",
  "Walmart + WFS": "walmart_and_wfs",
  "Vetted 3rd Party Sellers": "third_party_sellers",
  "Hayneedle Offers Only": "hayneedle",
  "All Walmart Offers": "all_offers",
};

export const THRESHOLD_STOCK_SUPPLIERS = [
  "home_depot",
  "ebay",
  "wayfair",
  "lucky_vitamin",
  "costco",
  "dsw",
  "overstock",
  "farmandfleet",
  "lowes",
  "vitacost",
  "boscovs",
  "thefulfiller",
];

export const SKIP_DELIVERY_DAYS_SUPPLIERS = [
  "farmandfleet",
  "fleetfarm",
  "bestbuy",
  "target",
  "walgreens",
  "boscovs",
  "vitacost",
  "bjs",
];

export const SKIP_ZIP_CODE_SUPPLIERS = ["fleetfarm", "walgreens", "boscovs", "vitacost"];

export const ALLOW_ZIP_SETTING = [
  "farmandfleet",
  "fleetfarm",
  "bestbuy",
  "target",
  "walgreens",
  "boscovs",
  "vitacost",
  "samsclub",
  "overstock",
  "home_depot",
  "walmart",
  "lowes",
  "menards",
  "newegg",
];

export const THIRD_PARTY_SELLERS = [
  "walmart",
  "zoro",
  "hayneedle",
  "overstock",
  "wayfair",
  "vm innovations",
  "pharmapacks",
];

export const AMZ_REGION = [
  {
    region: "America",
    countries: [{ country: "US", code: "US", id: "ATVPDKIKX0DER" }],
  },
];

export const CSV_FILE_TYPE_LISTINGS = {
  suppliers_upload: "bulk_update",
  bulk_listing: "bulk_list",
  oag_template: "oag_template",
  retire_skus: "retire_skus",
};

export const LOCAL_STORAGE_KEYS = [
  "repricerListingsColumns",
  "listingsSelectedMarketplace",
  "showProfitMargin",
  "listingsSearch",
  "listingsSearchType",
  "listingFilters",
];

export const AMZ_REGIONS = [
  {
    region: "North America",
    countries: [
      { country: "Brazil", code: "BR", id: "A2Q3Y263D00KWC" },
      { country: "Canada", code: "CA", id: "A2EUQ1WTGCTBG2" },
      { country: "Mexico", code: "MX", id: "A1AM78C64UM0Y8" },
      { country: "US", code: "US", id: "ATVPDKIKX0DER" },
    ],
  },
  {
    region: "Europe",
    countries: [
      {
        country: "United Arab Emirates",
        code: "(U.A.E.)",
        id: "A2VIGQ35RCS4UG",
      },
      { country: "Germany", code: "DE", id: "A1PA6795UKMFR9" },
      { country: "Egypt", code: "EG", id: "ARBP9OOSHTCHU" },
      { country: "Spain", code: "ES", id: "A1RKKUPIHCS9HS" },
      { country: "France", code: "FR", id: "A13V1IB3VIYZZH" },
      { country: "UK", code: "GB", id: "A1F83G8C2ARO7P" },
      { country: "India", code: "IN", id: "A21TJRUUN4KGV" },
      { country: "Italy", code: "IT", id: "APJ6JRA9NG5V4" },
      { country: "Netherlands", code: "NL", id: "A1805IZSGTT6HS" },
      { country: "Saudi Arabia", code: "SA", id: "A17E79C6D8DWNP" },
      { country: "Sweden", code: "SE", id: "A2NODRKZP88ZB9" },
      { country: "Turkey", code: "TR", id: "A33AVAJ2PDY3EV" },
    ],
  },
  {
    region: "Far East",
    countries: [
      { country: "Singapore", code: "SG", id: "A19VAU5U5O7RUS" },
      { country: "Australia", code: "AU", id: "A39IBJ37TRP1C6" },
      { country: "Japan", code: "JP", id: "A1VC38T7YXB528" },
    ],
  },
];

export const DEFAULT_TZ = "EST";

export const AO_ALLOWED = JSON.parse(ENV.REACT_APP_AO_ALLOWED || "[]");

export const TENANTS_ACTIONS_ACCESS = JSON.parse(ENV.REACT_APP_TENANTS_ACTIONS_ACCESS || "[]");

export const WAREHOUSE_ADMINS = JSON.parse(ENV.REACT_APP_WAREHOUSE_ADMINS || "[]");

export const REPRICER_ALLOWED = JSON.parse(ENV.REACT_APP_REPRICER_ALLOWED || "[]");

export const BCE_TRIGGERS = {
  return: "generate_return_label",
  cancel: "cancel_order",
  seller: "add_seller_id",
  payment: "payment_declined",
  approve: "approve_order",
};

export const NOTIFICATION_TYPE = {
  new_sale: "new_sale",
  shipped_to_wrong_address: "shipped_to_wrong_address",
  payment_declined: "payment_declined",
  errored_order: "errored_order",
  subscription: "subscription",
  prime_shipping: "prime_shipping",
  support_account: "support_account",
  other: "other",
};

export const NON_STORE_USERS = [3242, 3304];

export const DEV_IDS = JSON.parse(ENV.REACT_APP_DEV_IDS || "[]");

export const MATT_IDS = JSON.parse(ENV.REACT_APP_MATT_IDS || "[]");

export const AO_DEV_IDS = JSON.parse(ENV.REACT_APP_AO_DEV_IDS || "[]");

export const SHOW_TENANT_SWITCH_IDS = JSON.parse(ENV.REACT_APP_SHOW_TENANT_SWITCHER || "[]");

export const DELETE_OUTBOUND_ITEM = JSON.parse(ENV.REACT_APP_DELETE_OUTBOUND_ITEM || "[]");

export const SHOW_OVERSIZED_COL = JSON.parse(ENV.REACT_APP_SHOW_OVERSIZED_COL || "[]");

export const SHOW_IS_LIQUIDATED = JSON.parse(ENV.REACT_APP_SHOW_IS_LIQUIDATED || "[]");

export const MARK_ORDER_BUTTON = JSON.parse(ENV.REACT_APP_MARK_ORDER_BUTTON || "[]");

export const REPRICER_PROFIT_TYPES = {
  "Fixed Profit": "fixed_profit",
  "Percentage Profit": "percentage_profit",
  "Choose Max": "max_profit",
};

export const REPRICER_TYPES = {
  informed: "repricer_informed",
  old_repricer: "repricer_internal",
  ecom_repricer: "repricer_external",
};

export const ERR_PREFIXES = [
  "Address Error",
  "Cancelled Supplier Order",
  "Delivery Date Limit",
  "Losing Money",
  "Out Of Stock",
  "Less Quantity",
  "Other",
];

export const ONBOARD_FLAGS = {
  confirm_email: 1,
  forwarder_setting_visited: 6,
  lister_visited: 7,
  payment: 3,
  store_added: 4,
  tos_accepted: 2,
};

export const onboardingStepsIds = {
  verifyUser: 1,
  welcom: 2,
  amazonSetUp: 4,
  walmartsetUp: 5,
  emailForwarder: 6,
  facebooksetUp: 8,
  shopifysetUp: 9,
  lister: 7,
  payment: 3,
};

export const WH_OPTS_MAPPING = { returns: "returns", fulfillment: "fulfillment" };

export const USER_ROLES = {
  admin: "admin",
  owner: "owner",
  manager: "manager",
  va: "va",
  dev: "dev",
  service: "service",
  readOnly: "readOnly",
  whStaff: "wh_staff",
};

export const PAYMENT_SOURCE = {
  default: "Client’s payment method",
  parent: "Agency’s payment method",
};

export const PAYMENT_TYPES = {
  parent: "parent",
  default: "default",
};

export const LISTINGS_PAGES = [
  { value: "in_progress", label: "In Progress Listing" },
  { value: "live", label: "Live Listing" },
  { value: "errored", label: "Errored Listing" },
  { value: "retired", label: "Retired/Deleted Listing" },
];

export const FACEBOOK_PAGES = [
  { value: "live", label: "Live Listing" },
  { value: "retired", label: "Retired/Deleted Listing" },
];

export const LISTING_SEARCH_TYPES = {
  All: "keyword",
  SKU: "sku",
  "Item Id": "item_id",
  UPC: "upc",
};

export const INBOUNDS_SEARCH_TYPES = {
  All: "",
  "Tracking Number": "tracking_number",
  "Account ID": "account_id",
  UPC: "upc",
  "Purchase Order Number": "order_id",
};

export const RETURN_REFUND_STATUSES = {
  refund_pending: "refund_pending",
  partially_refunded: "partially_refunded",
  fully_refunded: "fully_refunded",
  refund_canceled: "refund_canceled",
};

export const LABEL_UPLOAD_TYPES = { inventory: "inventory", returns: "returns" };

export const RETURN_STATUSES = {
  completed: "completed",
  delivered: "delivered",
  initiated: "initiated",
};

export const BULK_SELECT_TYPES = {
  current_page: "current_page",
  all_listings: "all_listings",
};

export const IP_REGEX = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const INT_REGEX = /^\d+$/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const PASSWORD_REGEX = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})";

export const USER_NAME_REGEX = /^[A-Za-z0-9|*|$\-_+@!#]+$/;

// eslint-disable-next-line no-useless-escape
export const FIRST_LAST_NAME_REGEX = "^[A-Za-z\\s\\-']+$";

export const CANADA_ZIP_CODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export const USA_ZIP_CODE_REGEX = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;

export const US_PHONE_NUMBER_REGEX = /\+1 \([2-9][\d]{2}\) [\d]{3}-[\d]{4}$/;

export const STORE_ADD_MSG = "This could take 3-5 minutes. Please stay on this page while we verify the credentials. Maybe go grab another coffee?";

export const CUSTOM_FILTERS = {
  platform: { value: "", label: "Select a Platform" },
  user: { value: "", label: "Select an User" },
  won_buybox: { value: "" },
  published: { value: "" },
  auto_ordering_enabled: { value: "" },
  mp_fi: { value: "" },
  seller_type: { value: "" },
  ship_to_warehouse: { value: "" },
  stock_lock: { value: "" },
  refreshed_at: "",
  listing_type: { value: "" },
  listing_source: { value: "" },
  created_at: "",
  oos_since: "",
  keyword: { value: "" },
  offer_status: { value: "", label: "Select a Reason" },
  min_weight: { value: "" },
  max_weight: { value: "" },
  min_shipping: { value: "" },
  max_shipping: { value: "" },
  missing_dimensions: false,
  missing_estimates: false,
};

export const FILTER_TAGS_MAPPING = {
  keyword: "All",
  sku: "SKU",
  item_id: "Item Id",
  upc: "UPC",
  auto_ordering_enabled: "Verified Match",
  won_buybox: "BuyBox",
  published: "Marketplace Status",
  stock_lock: "Stock Lock",
  seller_type: "Seller Type",
  ship_to_warehouse: "Ship to Warehouse",
  mp_fi: "Marketplace Items Fulfillment",
  listing_source: "Listing Type",
  offer_status: "OOS Reason",
  refreshed_at: "Refreshed Before This Date",
  oos_since: "Out of Stock Since or Before",
  created_at: "Created At",
  platform_id: "Supplier",
  va_id: "User",
  dimensions: "Missing Dimensions",
  estimates: "Missing Estimates",
};

export const LABEL_SERVICES = {
  easypost: "easypost",
  amazon: "amazon",
  stamps: "stamps",
  ecom_circles_shipping: "ecom_circles_shipping",
};

export const BRAND_OPTIONS = {
  restricted: "true",
  non_restricted: "false",
  all_brands: "",
};

export const BULK_LISTINGS_COLUMNS_DESCRIPTION = [
  {
    subHeading: "Supplier URL *",
    content: "The product’s URL on the supplier website will be put here in this column.",
  },
  {
    subHeading: "MarketPlace URL *",
    content: "The link fo that product on the Marketplace site.",
  },
  {
    subHeading: "Supplier Price *",
    content: "Price set by the supplier in dollars",
  },
  {
    subHeading: "Supplier Shipping",
    content: "The shipping fee in dollars",
  },
  {
    subHeading: "Quantity Multiplier",
    content:
      "This is NOT related to stock. This is used for bundling. For example, if the marketplace listing is a 3-pack and your supplier's listing is a 1 pack, you want want to set Quantity Multiplier = 3 so that Ecom Circles knows to calculate your supplier price x 3.",
  },
  {
    subHeading: "Verified Match",
    content:
      "This should be true if you are 100% sure the supplier & marketplace products are the same, it helps with AO options.",
  },
  {
    subHeading: "SKU(Stock Keeping Unit)",
    content: "An ID consisting of a string of numbers and letters representing each product.",
  },
  {
    subHeading: "User",
    content: "The user’s email provided by the customer’s VA is put in the last column as the user’s identity.",
  },
  {
    subHeading: "Is Default",
    content:
      "You can add more than 1 supplier link for a listing. The Default option allows you to specify which of your supplier links should be considered for stock and price refreshing. 1 stands for \"Yes\", 0 stands for \"No.\" If you only have 1 supplier link for your listing, you will want to set Default = 1 for all of your listings.",
  },
  {
    subHeading: "Variation",
    content:
      "To provide specific variation attributes please use predefined columns with these headers: Color, Size, Width, Flavour, Style, Configuration, Capacity. In case of any new attributes please add a new column with attribute name.",
  },
  {
    subHeading: "Stock Lock",
    content: "It can turned ON to set manual stock and override the supplier stock. \"Stock Lock\" = 1 means ON and \"Stock Lock\" = 0 means OFF.",
  },
  {
    subHeading: "Manual Stock",
    content: "Stock value to override the supplier stock manually. Applicable only when the stock lock is ON.",
  },
  {
    subHeading: "Ship To Warehouse",
    content: "Option to set the supplier shipment type as \"2-Step\" or \"Direct\". \"Ship To Warehouse\" = 1 means \"2-Step\" and \"Ship To Warehouse\" = 0 means \"Direct\".",
  },
];

export const ITEM_IDENTIFIER = {
  asin: "asin",
  item_id: "item_id",
  sku: "sku",
};

export const SOCK_EVENT_TYPES = {
  view_order: "view_order",
  close_conn: "close_conn",
  disconnect: "disconnect",
  order_updated: "order_updated",
};

export const READ_ONLY_RESOURCES = {
  default: "",
  dashboard: "dashboard",
  source_orders: "source_orders",
  source_orders_details: "source_orders_details",
  view_report: "view_report",
  credentials: "credentials",
  credit_cards: "credit_cards",
  two_factor_auth_instructions: "two_factor_auth_instructions",
  listings: "listings",
  listings_csv: "listings_csv",
  unpublished_listings: "unpublished-listings",
  pending_listings: "pending_listings",
  uploaded_files: "uploaded_files",
  emails: "emails",
  email: "email",
  users: "users",
  walmart_feed: "walmart_feed",
  amazon_feed: "amazon_feed",
  amazon_sellers: "amazon_sellers",
  shipment_report: "shipment-report",
  settings: "settings",
  instructions_setup_ao: "instructions_setup_ao",
  suppliers: "suppliers",
  credit_logs: "credit_logs",
  submit_bce: "submit_bce",
  tenant_accounts: "tenant_accounts",
  restricted_brands: "restricted_brands",
  resources: "resources",
  ao_ecs_tasks: "ao_ecs_tasks",
  repricer_settings: "repricer_settings",
  platforms: "platforms",
};

export const INACTIVE_MESSAGE =
  "Your account is currently inactive. Please check your email to learn why it was inactive (check spam!). Once resolved, you can reactivate your account here";

export const PROXY_COUNT_USERS = [298, 17, 22];

export const RETURN_LABEL_USERS = [
  1, 6, 7, 10, 12, 15, 16, 17, 22, 23, 27, 32, 40, 41, 42, 62, 298, 351, 476, 518, 530, 543, 565, 570, 1135, 1140, 1538,
  1653, 1793, 1798, 1801, 1803, 1804, 1807, 1816, 1817, 1818, 1833, 1835, 1838, 1843, 1848, 1855, 1947, 2062, 2144,
  2145, 2147, 2370, 2397, 2398, 2403, 3461, 4010, 4011, 4012, 4013, 4214, 50, 309, 1151, 1374, 1547, 1549, 1978, 1995,
  2234, 2331, 2897, 3565, 3572, 3794, 4229, 4510, 4518, 4523, 54, 2157, 2864, 4460, 75, 413, 774, 952, 4511, 103, 4462,
  4464, 445, 450, 451, 1558, 3155, 3578, 3852, 4465, 963, 1164, 2375, 4466, 991, 1709, 4502, 1010, 1739, 2815, 1053,
  1235, 4503, 1054, 1288, 3800, 3875, 4467, 1108, 1386, 2718, 2818, 3660, 3661, 3662, 3677, 4505, 1126, 4468, 1127,
  1128, 1503, 3803, 4469, 1129, 1269, 4432, 4470, 1131, 1276, 2262, 4405, 4436, 4471, 1133, 4472, 4509, 1134, 2806,
  4473, 1136, 1217, 2808, 4500, 1137, 1219, 1657, 2816, 2892, 3595, 4474, 1251, 2814, 4475, 1060, 1267, 1373, 4408,
  4476, 1398, 2678, 4407, 4477, 1529, 2810, 2811, 4514, 1574, 2261, 4478, 1590, 2069, 2265, 4479, 1852, 2346, 1997,
  2097, 2259, 2263, 2277, 2283, 2311, 3667, 4428, 4504, 1998, 2316, 2807, 1999, 2072, 2001, 2677, 4480, 2002, 2548,
  4507, 2003, 2679, 4506, 2004, 2550, 4513, 2054, 2604, 2812, 4499, 2100, 2682, 2161, 2267, 4446, 4481, 2236, 2544,
  4447, 4482, 2238, 2500, 4448, 4483, 2248, 4501, 2291, 2414, 4498, 2373, 2426, 4497, 2435, 2436, 4449, 4484, 3076,
  4277, 4450, 4485, 3153, 4451, 4486, 3229, 3552, 3978, 4487, 3232, 4488, 3782, 4452, 4489, 3783, 4508, 2126, 3920,
  4169, 4454, 4490, 3938, 4431, 4491, 3983, 4335, 4456, 4492, 4059, 4225, 4457, 4493, 4060, 4458, 4494, 4593,
];

export const JOB_DASHBOARD_USERS = JSON.parse(ENV.REACT_APP_JOB_DASHBOARD_USERS || "[]");

export const UNPUBLISHED_LISTINGS_USERS = JSON.parse(ENV.REACT_APP_UNPUBLISHED_LISTINGS_ACCESS || "[]");

export const AMAZON_TAX_VALUES = {
  "Amazon Device Accessories": 45,
  "Amazon Explore": 30,
  "Automotive & Powersports": 12,
  "Baby Diapering, Care, & Other": { 0: 8, 10: 15 },
  Books: 15,
  "Beauty, Personal Care, & Hygiene": 8,
  "Camera and Photo": 8,
  "Cell Phone Devices": 8,
  "Consumer Electronics": 8,
  Clothing: 17,
  "Collectible Books": 15,
  "Collectible Coins": { 0: 15, 250: 10, 1000: 6 },
  "Compact Appliances": 15,
  "3D Printed Products": 12,
  "Electronics Accessories": 15,
  "Everything Else": 15,
  "Entertainment Collectibles": { 0: 20, 100: 10, 1000: 6 },
  Furniture: 15,
  "Full-Size Appliances": 8,
  "Fine Art": { 0: 20, 100: 15, 1000: 5 },
  "Gift Cards": 20,
  "Grocery & Gourmet Food": { 0: 8, 15: 15 },
  "Home & Garden": 15,
  "Health & Personal Care": { 0: 8, 10: 15 },
  "Industrial & Scientific": 12,
  Jewelry: { 0: 20, 250: 5 },
  Kitchen: 15,
  "Luggage & Travel Accessories": 15,
  Mattresses: 15,
  Music: 15,
  "Musical Instruments": 15,
  "Office Products": 15,
  Outdoors: 15,
  "Personal Computers": 8,
  "Pet Supplies": 15,
  "Software & Computer/Video Games": 15,
  "Sport & Recreation Other": 15,
  "Shoes, Handbags & Sunglasses": 15,
  "Tools & Home Improvement": 15,
  "Toys & Games": 15,
  "Video & DVD": 15,
  "Video Game Consoles": 8,
  Watches: { 0: 16, 1500: 3 },
};

export const SHIPPMENT_MAPPING = {
  Direct: false,
  "2 Step": true,
};

export const WALMART_TAX_VALUES = {
  "Apparel & Accessories": 15,
  "Automotive & Powersports": 12,
  "Baby Diapering, Care, & Other": { 0: 8, 10: 15 },
  "Beauty, Personal Care, & Hygiene": { 0: 8, 10: 15 },
  Books: 15,
  "Camera & Photo": 8,
  "Cell Phones": 8,
  "Consumer Electronics": 8,
  "Electronics Accessories": { 0: 15, 100: 8 },
  "Furniture & Decor": 15,
  "Grocery & Gourmet Food": 15,
  Grocery: { 0: 8, 10: 15 },
  "Health & Personal Care": { 0: 8, 10: 15 },
  "Home & Garden": 15,
  "Industrial & Scientific": 12,
  Jewelry: { 0: 20, 250: 5 },
  Kitchen: 15,
  "Luggage & Travel Accessories": 15,
  "Major Appliances": 8,
  Music: 15,
  "Musical Instruments": 12,
  "Office Products": 15,
  Outdoors: 15,
  "Personal Computers": 6,
  "Pet Supplies": 15,
  "Plumbing Heating Cooling & Ventilation": 10,
  "Shoes, Handbags & Sunglasses": 15,
  "Software & Computer Video Games": 15,
  "Sporting Goods": 15,
  "Tires & Wheels": 10,
  "Tools & Home Improvement": 15,
  "Toys & Games": 15,
  "Video & DVD": 15,
  "Video Game Consoles": 8,
  "Video Games": 15,
  Watches: { 0: 15, 1500: 3 },
  "Everything Else": 15,
};

export const PRIVACY_URL = "https://ecomcircles.com/privacy-policy";

export const TERMS_OF_CONDITION_URL = "https://ecomcircles.com/tos";

export const skuChars = ["%", "/"];

export const listingPlatforms =
  "amazon|walmart|ebay|homedepot|home depot|luckyvitamin|zoro|bjs|newegg|boscovs|bestbuy|farmandfleet|fleetfarm|walgreens|zappos|wayfair|overstock|costco|dsw|samsclub|samsandclub|vitacost|sixpm|lowes|thefulfiller|plumberstock".split(
    "|",
  );

export const DISABLED_PLATFORMS = ["bjs", "newegg", "boscovs", "bestbuy", "farmandfleet", "fleetfarm", "walgreens"];

export const ASC_URL = (state) =>
  `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${REACT_APP_APPLICATION_ID}&version=beta&state=${state}`;

export const STAMPS_AUTH_URL = () =>
  `https://signin.stampsendicia.com/authorize?client_id=${REACT_APP_STAMPS_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_STAMPS_REDIRECT_URL}&scope=offline_access`;

export const SHOPIFY_AUTH_URL = (shop, state) =>
  `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${REACT_APP_SHOPIFY_CLIENT_ID}&scope=${REACT_APP_SHOPIFY_ACCESS_SCOPES}&redirect_uri=${REACT_APP_SHOPIFY_REDIRECT_URL}&state=${state}`;

export const regions = reduce(
  City.getAllCities(),
  (obj, city) => {
    const { countryCode, stateCode } = city;
    if (!stateCode || !countryCode) return obj;
    const country = Country.getCountryByCode(countryCode);
    const state = State.getStateByCodeAndCountry(stateCode, countryCode);

    if (!country || !state) return obj;
    if (!obj[countryCode]) obj[countryCode] = { states: {}, name: country.name };

    if (!obj[countryCode].states[stateCode]) obj[countryCode].states[stateCode] = { cities: [], name: state.name };

    obj[countryCode].states[stateCode].cities.push(city.name);
    return obj;
  },
  {},
);

export const WH_CHARGES_TYPES = {
  shipping: "shipping",
  return: "return",
  oversized_return: "oversized_return",
  storage: "storage",
  stranded_2_step: "stranded_2_step",
};
export const MATCHES_REQ_WEIGHT_SUPPORT = ["amazon", "topdawg", "thefulfiller", "plumberstock"];
export const MATCHES_REQ_STATUS = { pending: "pending", cancelled: "cancelled", completed: "completed" };
export const DEFAULT_TENANT_ID = 2;
export const MATCHING_REQ_TYPES = { refresh: "Refresh Matches", matches: "Matches Requests" };
export const AO_AVAILABLE_PLATFORMS = ["amazon", "walmart", "home_depot", "topdawg"];
export const MP_ACC_STATUSES = {
  active: "active",
  suspended: "suspended",
  terminated: "terminated",
  onboarding_active: "onboarding_active",
  onboarding_inactive: "onboarding_inactive",
  tns_declined: "tns_declined",
};

export const HELP_DOCS_LINK = "https://docs.ecomcircles.com";
export const PO_SHIPMENT_TYPES = {
  tracking_items: "tracking_items",
  returns: "returns",
  purchase_orders: "purchase_orders",
};

export const DATE_FORMATS = {
  FULL_DATE: "MMM DD, yyyy hh:mm:ss a",
  GRAPH_FULL_DATE: "MMM dd, yyyy hh:mm:ss a",
  DATE_TIME: "MMM DD, yyyy hh:mm a",
  DATE: "MMM DD, yyyy",
  GRAPH_DATE: "MMM dd, yyyy",
  DATE_PICKR: "M d, Y",
  BACKEND: "YYYY-MM-DD",
};

export const ORDERABLE_TYPES_MAPPING = {
  tracking_items: "2 Step",
  returns: "Returns",
  purchase_orders: "Purchase Orders",
};

export const ORDERABLE_TYPES = {
  purchase_orders: "purchase_orders",
  returns: "returns",
  tracking_items: "tracking_items",
};

export const TWO_STEP_NON_SUPPORTED_STORES = { facebook: "facebook", shopify: "shopify" };
export const CONTENT_TYPE_MAPPING = {
  png: "image/png",
  pdf: "application/pdf",
  txt: "text/plain",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
};

export const REPORT_TYPES = {
  inventory_insights: "Inventory Insights",
  transaction: "Transactional Report",
  order_detail: "Order Details Report",
  settlement: "Settlement Report",
  orders: "Orders Report",
  store_front: "Storefront Report",
  marketplace: "Marketplace Report",
  inventory: "Inventory Report",
  returns: "Returns Report",
  wh_label: "Warehouse Label Report",
};

export const TRACKING_STATUS_OPTIONS = [
  { label: "All Tracking Statuses", value: "" },
  { label: "Shipped", value: "shipped" },
  { label: "Delivered", value: "delivered" },
  { label: "Unshipped", value: "unshipped" },
  { label: "In Transit", value: "in_transit" },
];

export const OUTBOUND_TYPES = {
  "2-Step": "tracking_items",
  "Purchase Orders": "purchase_orders",
  Returns: "returns",
  "Inventory Fulfilled": "inventory_fulfilled",
  "Discard Initiated": "discard_initiated",
  "Donate Initiated": "donate_initiated",
  FBA: "fba",
  WFS: "wfs",
};

export const OUTBOUND_STATUS_OPTIONS = {
  All: "all",
  Incomplete: "in_complete",
  Complete: "complete",
};

export const OUTBOUND_STATUSES = { complete: "Complete", in_complete: "Incomplete", partial: "Partial" };

export const PROFIT_ANALYZER_PARSER = {
  "Buy Box+": "buyboxPlus",
  "Buy Box": "buybox",
  "Lowest FBA": "lowestFba",
  "Lowest Offer": "lowestOffer",
  FBA: "FBA",
  FBM: "FBM",
  None: "None",
  "Last Captured Value": "",
  "30-Day Moving Average": "30Days",
  "60-Day Moving Average": "60Days",
  "90-Day Moving Average": "90Days",
  "180-Day Moving Average": "180Days",
  walmart: "walmart_products",
  amazon: "amazon_products",
  ebay: "ebay_products",
  undefined: null,
  new: ["New"],
  used: ["Used - Like New", "Used - Very Good", "Used - Good", "Used - Acceptable", "Refurbished"],
};

export const PROFIT_ANALYZER_FILTERS = {
  amazon: {
    profitablity: {
      name: "Profitablity",
      label: "Calculate profit using this Amazon price:",
      type: "dropdown",
      controls: {
        priceType: {
          info: [
            "Buy Box+ = Buy box Price (Uses Lowest Price if there is no Buy Box)",
            "Lowest FBA = Lowest FBA Offer (Ignores FBM Offers)",
            "Buy Box = Competes with Buy Box (If no Buy Box then items will be ignored)",
            "Lowest Offer = Lowest Offer Overall (considers both FBA or FBM)",
          ],
          label: "Price Type",
          value: "Lowest Offer",
          options: ["Buy Box+", "Lowest FBA", "Buy Box", "Lowest Offer"],
        },
        valueType: {
          label: "Value Type",
          value: "Last Captured Value",
          options: [
            "Last Captured Value",
            "30-Day Moving Average",
            "60-Day Moving Average",
            "90-Day Moving Average",
            "180-Day Moving Average",
          ],
        },
        feeType: {
          info: [
            "FBA = Calculate Profit using FBA fees",
            "None = Do not include FBA or Est. Shipping fees in Profit calculation",
            "All methods include category referral fees",
          ],
          label: "Fees",
          value: "FBA",
          options: ["None", "FBA"],
        },
      },
    },
    suppliers: {
      name: "Suppliers",
      type: "checkbox",
      controls: {
        walmart: {
          label: "Walmart",
          value: "Walmart",
          checked: true,
          options: [],
        },
        ebay: {
          label: "Ebay",
          value: "Ebay",
          checked: false,
          options: {
            new: {
              label: "New",
              value: "New",
              checked: false,
            },
            used: {
              label: "Used",
              value: "Used",
              checked: false,
            },
          },
        },
      },
    },
    otherSettings: {
      name: "Other Settings",
      type: "checkbox",
      controls: {
        upcOnly: {
          label: "Only opportunities with a UPC or ISBN",
          value: "UPCs only",
          checked: false,
          info: [
            "You'll get less mismatches if this is checked. Uncheck to see more results, but possibly more mismatches.",
          ],
        },
      },
    },
  },
  walmart: {
    profitablity: {
      name: "Profitablity",
      label: "Calculate profit using this Walmart price:",
      type: "dropdown",
      controls: {
        priceType: {
          info: ["Buy Box+ = Buy box Price (Uses Lowest Price if there is no Buy Box)"],
          label: "Price Type",
          value: "Buy Box+",
          options: ["Buy Box+", "Lowest WFS", "Buy Box", "Lowest Offer"],
        },
        valueType: {
          label: "Value Type",
          value: "Last Captured Value",
          options: ["Last Captured Value", "30-Day Moving Average", "60-Day Moving Average", "90-Day Moving Average"],
        },
        feeType: {
          info: ["WFS = Calculate Profit using WFS fees"],
          label: "Fees",
          value: "WFS",
          options: ["None", "WFS", "SF"],
        },
      },
    },
    suppliers: {
      name: "Suppliers",
      type: "checkbox",
      controls: {
        amazon: {
          label: "Amazon",
          value: "Amazon",
          checked: false,
          options: [],
        },
        ebay: {
          label: "Ebay",
          value: "Ebay",
          checked: true,
          options: [
            {
              label: "New",
              value: "New",
              checked: true,
            },
            {
              label: "Used",
              value: "Used",
              checked: false,
            },
          ],
        },
      },
    },
    otherSettings: {
      name: "Other Settings",
      type: "checkbox",
      controls: {
        upcOnly: {
          label: "Only opportunities with a UPC or ISBN",
          value: "UPCs only",
          checked: false,
          info: [
            "You'll get less mismatches if this is checked. Uncheck to see more results, but possibly more mismatches.",
          ],
        },
      },
    },
  },
};
