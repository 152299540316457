import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardHeader, Collapse, Row, Col } from "reactstrap";
import { RenderIf, toPascalCase, toUpper, formatNumber, getSupplierName } from "../../../utils/common";
import { setSelectedPlatform, setPlatformEditModal } from "../../../store/actions";
import getBrandImageSrc from "../../../utils/brandImages";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import {
  SKIP_ZIP_CODE_SUPPLIERS,
  SKIP_DELIVERY_DAYS_SUPPLIERS,
  MARKET_PLACES,
  ALLOW_ZIP_SETTING,
  THRESHOLD_STOCK_SUPPLIERS,
} from "../../../constants";
import { startCase } from "lodash";

const SupplierCard = ({ item, handleUpdate, account }) => {
  const [collapse, setCollapse] = useState([]);

  const dispatch = useDispatch();
  function handleEditPlatform (acc, e) {
    dispatch(setSelectedPlatform(acc));
    dispatch(setPlatformEditModal(true));
    e.stopPropagation();
  }

  const handleCollapse = (id) => {
    const collapseHandle = [...collapse];
    collapseHandle[id] = !collapseHandle[id];
    setCollapse(collapseHandle);
  };

  const imageSrc = getBrandImageSrc(item.name);
  const isPlumber = item.name === "plumberstock";

  return (
    <>
      <Col xl="12" sm="4" md="4">
        <Card style={{ minWidth: 300 }}>
          <CardHeader onClick={() => handleCollapse(item.id)} className="cursor-pointer" style={{ height: 60 }}>
            <div className="d-flex align-items-center justify-content-between">
              <div style={item.name === "plumberstock" ? { height: 36, padding: 5 } : { height: 36 }}>
                <RenderIf isTrue={imageSrc}>
                  <img
                    style={isPlumber ? { borderRadius: "20px" } : {}}
                    src={imageSrc}
                    alt="supplier logo"
                    width={isPlumber ? "25px" : "36px"}
                  />
                </RenderIf>
                <span style={item.name === "plumberstock" ? { marginLeft: "7px" } : {}}>
                  {startCase(getSupplierName(item.name))}
                </span>
              </div>
              <div
                className="custom-control grid-column-gap-10 custom-switch custom-switch-lg  d-flex align-items-center justify-content-center"
                dir="ltr"
              >
                <i
                  title="Edit"
                  className="bx bx-edit-alt"
                  style={{ fontSize: "20px", color: "royalblue" }}
                  onClick={(e) => {
                    handleEditPlatform(item, e);
                  }}
                ></i>
                <i className="bx bx-expand" style={{ fontSize: "20px", color: "royalblue" }}></i>
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={collapse[item.id]}>
            <Card className="p-3">
              <h6 className="mt-2 mb-2">Marketplace</h6>
              <Row>
                {account?.data?.some((x) => x?.marketplace?.includes(MARKET_PLACES.amazon)) && (
                  <Col md="3">
                    <div>
                      <div className="d-flex flex-direction-column mb-2" style={{ fontSize: "10px" }}>
                        Amazon Handling Time
                      </div>
                      <h6> {item.handling_time}</h6>
                    </div>
                  </Col>
                )}
                <RenderIf
                  isTrue={!(item.is_wholesaler && THRESHOLD_STOCK_SUPPLIERS.includes(item.name))}
                  fallback={
                    <Col md="3">
                      <div>
                        <div
                          id="sup_threshold_stock"
                          className="d-flex flex-direction-column mb-2"
                          style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                        >
                          Threshold Stock
                          <CustomTooltip
                            target="sup_threshold_stock"
                            content="We will consider the stock amount we get from the supplier less than Threshold Stock amount."
                          />
                        </div>
                        <h6> {item.threshold_stock}</h6>
                      </div>
                    </Col>
                  }
                >
                  <Col md="3">
                    <div>
                      <div className="d-flex flex-direction-column mb-2" style={{ fontSize: "10px" }}>
                        Default Restocking
                      </div>
                      <h6> {item.default_stock}</h6>
                    </div>
                  </Col>
                </RenderIf>
              </Row>
              <h6 className="mt-2 mb-2">Refreshers</h6>
              <Row>
                {!item?.is_wholesaler && !SKIP_DELIVERY_DAYS_SUPPLIERS.includes(item.name) && (
                  <Col md="3">
                    <div>
                      <div
                        className="d-flex flex-direction-column mb-2"
                        style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                      >
                        Delivery Days
                      </div>
                      <h6> {item.delivery_days}</h6>
                    </div>
                  </Col>
                )}
                <Col md="3">
                  <div>
                    <div
                      className="d-flex flex-direction-column mb-2"
                      style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                    >
                      Est. Tax
                    </div>
                    <h6>{formatNumber(item.tax, "percentage") + "%"}</h6>
                  </div>
                </Col>
                {ALLOW_ZIP_SETTING.includes(item?.name) && !SKIP_ZIP_CODE_SUPPLIERS.includes(item?.name) && (
                  <Col md="3">
                    <div>
                      <div
                        className="d-flex flex-direction-column mb-2"
                        style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                      >
                        Zip Code
                      </div>
                      <h6> {item.zip_code || "N/A"}</h6>
                    </div>
                  </Col>
                )}
                <Col md="3">
                  <div>
                    <div className="d-flex flex-direction-column mb-2" style={{ fontSize: "10px" }}>
                      Shipping Fee Override
                    </div>
                    <h6> {item.shipping_fee_lock ? formatNumber(item.shipping_fee) : "N/A"}</h6>
                  </div>
                </Col>
                {item.refresher_offer_priority && (
                  <Col md="3">
                    <div>
                      <div
                        className="d-flex flex-direction-column mb-2"
                        style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                      >
                        Refreshers
                      </div>
                      <h6 style={{ width: "6rem" }}> {toUpper(toPascalCase(item.refresher_offer_priority))}</h6>
                    </div>
                  </Col>
                )}
              </Row>
              <RenderIf isTrue={item.ao_available}>
                <>
                  <h6 className="my-2 pt-1">Automatic Ordering</h6>
                  <Row>
                    {item.gift_message && (
                      <Col md="3">
                        <div>
                          <div
                            className="d-flex flex-direction-column mb-2"
                            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                          >
                            Gift Message
                          </div>
                          <h6 className="font-size-10">{item.gift_message}</h6>
                        </div>
                      </Col>
                    )}
                    <Col md="3">
                      <div>
                        <div
                          className="d-flex flex-direction-column mb-2"
                          style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                        >
                          Min Reviews
                        </div>
                        <h6> {item.min_reviews}</h6>
                      </div>
                    </Col>
                    <Col md="3">
                      <div>
                        <div
                          className="d-flex flex-direction-column mb-2"
                          style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                        >
                          Min Feedback
                        </div>
                        <h6>{item.min_feedback}</h6>
                      </div>
                    </Col>
                    {item.ao_offer_priority && (
                      <Col md="3">
                        <div>
                          <div
                            className="d-flex flex-direction-column mb-2"
                            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                          >
                            Auto Ordering
                          </div>
                          <h6> {toUpper(item.ao_offer_priority)}</h6>
                        </div>
                      </Col>
                    )}
                    <Col md={3}>
                      {item?.name === "amazon" && item?.ao_enabled && (
                        <div>
                          <div
                            className="d-flex flex-direction-column mb-2"
                            style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                          >
                            Promotions
                          </div>
                          <div className="custom-control custom-switch custom-switch-lg" dir="ltr">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={item.apply_promotions}
                              onChange={() => null}
                            />
                            <label
                              onClick={() =>
                                handleUpdate({
                                  data: { apply_promotions: !item.apply_promotions },
                                  id: item.id,
                                })
                              }
                              className="custom-control-label cursor-pointer"
                            ></label>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              </RenderIf>
            </Card>
          </Collapse>
        </Card>
      </Col>
    </>
  );
};
export default SupplierCard;
