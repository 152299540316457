import React, { useState, useEffect, useRef } from "react";
import { capitalize, kebabCase } from "lodash";
import { CardBody, Button, UncontrolledTooltip, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";

import { RenderIf, amountText } from "../../../utils/common";
import { parseName, getPriceType, getCostType } from "../common";
import {
  setPageNumber,
  addColumnFilter,
  removeColumnFilter,
  checkProductEligibility,
  setHideRow,
} from "../../../store/actions";
import ColumnLevelFilter from "./ColumnLevelFilter";
import ColumnSelector from "./ColumnSelector";
import SelectSalesRank from "./SelectSalesRank";
import MismatchModal from "./MismatchModal";
import ListingModal from "./ListingModal";
import CustomTooltip from "../../../components/Common/CustomTooltip";

const MatchingList = (props) => {
  const {
    columns,
    getTableHeaders,
    tableHeaders,
    setColumns,
    showFilters,
    handleApplyFilter,
    totalCount,
    marketPlaceAccount,
  } = props;
  const dispatch = useDispatch();
  const {
    selectedMarketplace: marketPlace,
    matches,
    salesRank,
    columnFilters,
    appliedFilters,
    page = 0,
  } = useSelector((state) => state.ProfitAnalyzer);

  const [openFilterId, setOpenFilterId] = useState(null);
  const [openMismatchProductId, setOpenMismatchProductId] = useState(null);
  const [openListingProductId, setOpenListingProductId] = useState(null);

  const priceType = parseName[getPriceType(appliedFilters, marketPlace)];
  const costType = getCostType(appliedFilters, marketPlace);
  const approveRequestUrl = "https://sellercentral.amazon.com/hz/approvalrequest/restrictions/approve";

  const isInitialRenderFilters = useRef(true);
  const isInitialRenderPage = useRef(true);

  const toggleModal = (productId, currentState, setState) => {
    if (currentState === productId) {
      setState(null);
    } else {
      setState(productId);
    }
  };

  const toggleFilter = (filterId) => {
    if (openFilterId === filterId) {
      setOpenFilterId(null);
    } else {
      setOpenFilterId(filterId);
    }
  };

  const handleColumnFilter = (column, option, value, title, displayOption) => {
    dispatch(addColumnFilter({ column, option, value, title, displayOption }));
  };

  const handleRemoveFilter = (column) => {
    dispatch(removeColumnFilter({ column }));
  };

  useEffect(() => {
    if (isInitialRenderFilters.current) {
      isInitialRenderFilters.current = false;
      return;
    }
    handleApplyFilter();
  }, [columnFilters]);

  useEffect(() => {
    if (isInitialRenderPage.current) {
      isInitialRenderPage.current = false;
      return;
    }
    handleApplyFilter(page);
  }, [page]);

  const imageDisplay = (imageUrl) => {
    return (
      <div>
        <img src={imageUrl} alt="Displayed Image" style={{ width: "130px", height: "100px" }} />
      </div>
    );
  };

  const urlDisplay = (value, linkUrl) => {
    if (!linkUrl?.startsWith("http")) {
      linkUrl = `https://${linkUrl}`;
    }
    return (
      <div>
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    );
  };

  const rateDisplay = (value, ...rest) => {
    value = `${value}%`;
    try {
      if (!value) return <span>{value}</span>;
      let colorClass = "";
      const numericValue = Math.round(parseFloat(value));
      if (numericValue > 0) {
        colorClass = "text-success";
        value = `${numericValue}%`;
      } else {
        colorClass = "text-danger";
        value = `-${numericValue * -1}%`;
      }

      return <span className={colorClass}>{value}</span>;
    } catch (error) {
      return <span>{value}</span>;
    }
  };

  const currencyDisplay = (value, ...rest) => {
    try {
      if (!value) return <span>{value}</span>;
      let colorClass = "";

      const numericValue = parseFloat(value).toFixed(2);
      if (numericValue > 0) {
        colorClass = "text-success";
      } else {
        colorClass = "text-danger";
      }

      return <span className={colorClass}>{amountText(numericValue)}</span>;
    } catch (error) {
      return <span>{value}</span>;
    }
  };

  const CompetitorQuantityDisplay = ({ fbaCount, fbmCount }) => (
    <div className="d-flex flex-column align-items-center p-2" style={{ width: "80px", borderRadius: "5px" }}>
      <div className="d-flex justify-content-between w-100 mb-2">
        <span>FBA</span>
        <span className="border-start border-1 border-light mx-2"></span>
        <span>FBM</span>
      </div>
      <div className="d-flex justify-content-between text-white w-100">
        <span>{fbaCount}</span>
        <span className="border-start border-1 border-light mx-2"></span>
        <span>{fbmCount}</span>
      </div>
    </div>
  );

  const OnListingDisplay = ({ onListing }) => {
    const message = onListing ? "Yes" : "No";
    return <span>{message}</span>;
  };

  const HazmatDisplay = ({ hazmat }) => (
    <div>{hazmat ? <span className="text-white">Hazmat Product</span> : <span>Not Hazmat</span>}</div>
  );
  const BrandRestrictedDisplay = ({ isRestricted }) => (
    <div>{isRestricted ? <span className="text-white">Restricted</span> : <span>Not Restricted</span>}</div>
  );

  const FeeDisplay = ({ match }) => {
    const { supplierPrice, referralFeePercentage, pickAndPackFee, variableClosingFee } = match?._source;
    const referralFee = supplierPrice * (referralFeePercentage / 100);

    return (
      <div>
        <div>
          Referral Fee:{" "}
          {referralFee ? (
            <span className="text-white">{amountText(referralFee)}</span>
          ) : (
            <span>{amountText(referralFee)}</span>
          )}
        </div>
        <div>
          Prep Fee:{" "}
          {pickAndPackFee ? (
            <span className="text-white">{amountText(pickAndPackFee)}</span>
          ) : (
            <span>{amountText(pickAndPackFee)}</span>
          )}
        </div>
        <div>
          Variable Closing Fee:{" "}
          {variableClosingFee ? (
            <span className="text-white">{amountText(variableClosingFee)}</span>
          ) : (
            <span>{amountText(variableClosingFee)}</span>
          )}
        </div>
      </div>
    );
  };

  const MinMaxDisplay = ({ maxValue, minValue }) => (
    <div>
      <div>
        Min:{" "}
        {minValue ? <span className="text-white">{amountText(minValue)}</span> : <span>{amountText(minValue)}</span>}
      </div>
      <div className="pt-1">
        Max:{" "}
        {maxValue ? <span className="text-white">{amountText(maxValue)}</span> : <span>{amountText(maxValue)}</span>}
      </div>
    </div>
  );
  const AvgRankDisplay = ({ avg30DaysRank, avg90DaysRank }) => (
    <div>
      <div>
        Avg 30 Day Rank: {avg30DaysRank ? <span className="text-white">#{avg30DaysRank}</span> : <span> N/A</span>}
      </div>
      <div className="pt-1">
        Avg 90 Day Rank: {avg90DaysRank ? <span className="text-white">#{avg90DaysRank}</span> : <span> N/A</span>}
      </div>
    </div>
  );

  const RestrictedButton = ({ productIdentifier }) => (
    <>
      <Button color="danger" outline className="waves-effect waves-light px-2 py-1">
        <div className="d-inline-flex align-items-center pt-1">
          <i className="bx bx-error-circle px-1" style={{ fontSize: "18px" }} />
          <span>Restricted</span>
        </div>
      </Button>
      <p className="font-12 py-2">
        <a href={`${approveRequestUrl}?asin=${productIdentifier}`} target="_blank" rel="noopener noreferrer">
          Apply to Sell Item
        </a>
      </p>
    </>
  );

  const EligibleButton = () => (
    <Button color="success" outline className="waves-effect waves-light px-2 py-0">
      <div className="d-inline-flex align-items-center pt-1">
        <i className="bx bx-check-circle px-1" style={{ fontSize: "18px" }} />
        <span>Eligible</span>
      </div>
    </Button>
  );

  const CheckButton = ({ loading, _id, productIdentifier }) => {
    const accountIdentifier = marketPlaceAccount?.identifier;
    return (
      <RenderIf isTrue={!loading} fallback={<Spinner style={{ width: "20px", height: "20px" }} className="ml-3" />}>
        <Button
          color="primary"
          onClick={() =>
            dispatch(
              checkProductEligibility(
                {
                  sellerId: accountIdentifier,
                  asin: productIdentifier,
                },
                { _id },
              ),
            )
          }
          outline
          className="waves-effect waves-light px-2 py-1"
        >
          Check
        </Button>
      </RenderIf>
    );
  };

  const RestrictedDisplay = ({ match }) => {
    const data = match?._source;
    const eligibility = match?.eligibility;
    const identifier = data?.[`${marketPlace}Identifier`];
    const { destinationUrl: url } = data;
    const productIdentifier = identifier || extractASIN(url);

    return (
      <>
        {eligibility === "eligible" ? (
          <EligibleButton />
        ) : eligibility === "restricted" ? (
          <RestrictedButton productIdentifier={productIdentifier} />
        ) : (
          <CheckButton loading={match?.loading} _id={match?._id} productIdentifier={productIdentifier} />
        )}
      </>
    );
  };

  const ActionsColumn = ({ data }) => {
    const hideTooltipId = `${data._id.split("-")[1]}-hide-tooltip`;
    const mismatchTooltipId = `${data._id.split("-")[1]}-mismatch-tooltip`;
    return (
      <div className="d-inline-flex align-items-center gap-1">
        <i
          id={hideTooltipId}
          className="bx bx-low-vision text-primary cursor-pointer"
          style={{ fontSize: "18px" }}
          onClick={() => dispatch(setHideRow({ _id: data?._id }))}
        />
        <UncontrolledTooltip target={hideTooltipId} style={{ minWidth: "fit-content", maxWidth: "380px" }}>
          Hide
        </UncontrolledTooltip>
        <i
          id={mismatchTooltipId}
          className="bx bx-shield-x text-danger cursor-pointer"
          style={{ fontSize: "18px" }}
          onClick={() => toggleModal(data?._id, openMismatchProductId, setOpenMismatchProductId)}
        />
        <UncontrolledTooltip target={mismatchTooltipId} style={{ minWidth: "fit-content", maxWidth: "380px" }}>
          Mark as Mismatch
        </UncontrolledTooltip>
      </div>
    );
  };

  const filterIcon = <i className="bx bx-filter-alt cursor-pointer" style={{ fontSize: "18px" }} />;

  const extractASIN = (url) => {
    const asinMatch = url.match(/\/(?:dp|gp\/product)\/([A-Z0-9]{10})/);
    return asinMatch ? asinMatch[1] : null;
  };

  const matchingListHeader = [
    {
      title: "Restricted",
      name: "destinationUrl",
      extraAttr: "obj",
      customDisplay: (match, header) => <RestrictedDisplay match={match} />,
      hide: false,
    },
    {
      title: `${capitalize(marketPlace)} Image`,
      name: `${marketPlace}Image`,
      customDisplay: imageDisplay,
      hide: false,
    },
    {
      title: "Supplier Image",
      name: "supplierImage",
      customDisplay: imageDisplay,
      hide: false,
    },
    {
      title: `${capitalize(marketPlace)} Title`,
      name: `${marketPlace}Title`,
      customDisplay: urlDisplay,
      extraAttr: "destinationUrl",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Supplier Title",
      name: "supplierTitle",
      customDisplay: urlDisplay,
      extraAttr: "supplierUrl",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Sales Rank",
      name: `${salesRank?.name}`,
      hide: false,
      filterIcon,
      info: `${capitalize(marketPlace)} Sales Rank (BSR), ${salesRank.label}`,
    },
    {
      title: "Supplier Price",
      name: "supplierPrice",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Price",
      name: `${costType?.value}`,
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
      info: `${capitalize(marketPlace)} Price - ${costType?.priceLable}, ${costType?.valueLable}`,
    },
    {
      title: "Profit",
      name: "profit",
      customDisplay: (data, header = {}) => (
        <SimpleTooltip
          data={data}
          header={header}
          title="Profit Details"
          targetKey="profit"
          field="Profit"
          isCurrency={true}
        />
      ),
      extraAttr: "obj",
      hide: false,
      filterIcon,
    },
    {
      title: "ROI",
      name: "roi",
      customDisplay: (data, header = {}) => (
        <SimpleTooltip data={data} header={header} title="ROI Details" targetKey="roi" field="ROI" isCurrency={false} />
      ),
      extraAttr: "obj",
      isCurrency: false,
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest FBA",
      name: "lowestFba",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest FBA (Avg. 30 days)",
      name: "lowestFba30Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest FBA (Avg. 60 days)",
      name: "lowestFba60Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest FBA (Avg. 90 days)",
      name: "lowestFba90Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest FBA (Avg. 180 days)",
      name: "lowestFba180Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest Offer",
      name: "lowestOffer",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest Offer (Avg. 30 days)",
      name: "lowestOffer30Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest Offer (Avg. 60 days)",
      name: "lowestOffer60Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest Offer (Avg. 90 days)",
      name: "lowestOffer90Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Lowest Offer (Avg. 180 days)",
      name: "lowestOffer180Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buybox Price",
      name: "buybox",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box (Avg. 30 days)",
      name: "buybox30Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box (Avg. 60 days)",
      name: "buybox60Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box (Avg. 90 days)",
      name: "buybox90Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box (Avg. 180 days)",
      name: "buybox180Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box+",
      name: "buyboxPlus",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box+ (Avg. 30 days)",
      name: "buyboxPlus30Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box+ (Avg. 60 days)",
      name: "buyboxPlus60Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box+ (Avg. 90 days)",
      name: "buyboxPlus90Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Buy Box+ (Avg. 180 days)",
      name: "buyboxPlus90Days",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Competitor Quantity",
      name: "fbaOffersCount",
      extraAttr: "fbmOffersCount",
      customDisplay: (fbaCount, fbmCount) => CompetitorQuantityDisplay({ fbaCount, fbmCount }),
      hide: false,
    },
    {
      title: "Supplier Date Listed",
      name: "supplierCreatedAt",
      customDisplay: (value) => moment(value).tz("America/Halifax").format("DD MMMM YYYY h:mmA z"),
      hide: false,
    },
    {
      title: "Supplier Checked at",
      name: "supplierCheckedAt",
      customDisplay: (value) => moment(value).tz("America/Halifax").format("DD MMMM YYYY h:mmA z"),
      hide: false,
      info: "For Ebay, this is the listed date. For other suppliers, it is the date we last checked the site to get the stock and price",
    },
    {
      title: "Condition",
      name: "condition",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Brand",
      name: "brand",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Item ID",
      name: "supplierIdentifier",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Size",
      name: "size",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Color",
      name: "color",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Competitive Sellers",
      name: "numOfSellers",
      hide: false,
      filterIcon,
    },
    {
      title: "Fees",
      name: "pickAndPackFee",
      extraAttr: "obj",
      customDisplay: (match, header) => <FeeDisplay match={match} />,
      hide: false,
      filterIcon,
    },
    {
      title: "Brand Restriction",
      name: "isRestricted",
      customDisplay: (isRestricted) => <BrandRestrictedDisplay isRestricted={isRestricted} />,
      hide: false,
    },
    {
      title: "Hazmat",
      name: "hazmat",
      customDisplay: (hazmat) => <HazmatDisplay hazmat={hazmat} />,
      hide: false,
    },
    {
      title: "Avg Rank",
      name: "salesRank30Days",
      extraAttr: "salesRank90Days",
      customDisplay: (avg30, avg90) => <AvgRankDisplay avg30DaysRank={avg30} avg90DaysRank={avg90} />,
      hide: false,
      filterIcon,
    },
    {
      title: "Amazon On Listing",
      name: "amazonOnListing",
      customDisplay: (onListing) => <OnListingDisplay onListing={onListing} />,
      hide: false,
      isNumeric: false,
      filterIcon,
    },
    {
      title: "UPC",
      name: "upc",
      customDisplay: urlDisplay,
      extraAttr: "destinationUrl",
      hide: false,
      filterIcon,
    },
    {
      title: "30 Day Price",
      name: `${priceType}30DaysMaxPrice`,
      extraAttr: `${priceType}30DaysMinPrice`,
      customDisplay: (maxVal, minVal) => <MinMaxDisplay maxValue={maxVal} minValue={minVal} />,
      hide: false,
      filterIcon,
    },
    {
      title: "60 Day Price",
      name: `${priceType}60DaysMaxPrice`,
      extraAttr: `${priceType}60DaysMinPrice`,
      customDisplay: (maxVal, minVal) => <MinMaxDisplay maxValue={maxVal} minValue={minVal} />,
      hide: false,
      filterIcon,
    },
    {
      title: "90 Day Price",
      name: `${priceType}90DaysMaxPrice`,
      extraAttr: `${priceType}90DaysMinPrice`,
      customDisplay: (maxVal, minVal) => <MinMaxDisplay maxValue={maxVal} minValue={minVal} />,
      hide: false,
    },
    {
      title: `${capitalize(marketPlace)} Category`,
      name: `${marketPlace}Category`,
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "Supplier category",
      name: "supplierCategory",
      hide: false,
      filterIcon,
      isNumeric: false,
    },
    {
      title: "FBA Fees",
      name: "fba",
      customDisplay: (value) => amountText(value),
      hide: false,
      filterIcon,
    },
    {
      title: "Amz Offers",
      name: "numOfSellers",
      customDisplay: (value) => value,
      hide: false,
      filterIcon,
    },

    {
      title: "Action",
      customDisplay: (data) => <ActionsColumn data={data} />,
      extraAttr: "obj",
    },
  ];

  const [sortFilter] = useState(undefined);
  const SortArrows = ({ arrow }) => (
    <i
      id={arrow + "-filter"}
      style={{ fontSize: "12px" }}
      className={`dripicons-arrow-thin-${arrow} cursor-pointer ${arrow === "down" ? "ml-n3" : ""}`}
    />
  );

  useEffect(() => {
    getTableHeaders(matchingListHeader);
  }, [marketPlace]);

  const handleDisplayValue = (header, match) => {
    const data = match?._source;

    if (header?.customDisplay) {
      if (header?.extraAttr) {
        if (header.extraAttr === "obj") {
          return header.customDisplay(match, header);
        }
        return header.customDisplay(data?.[header.name], data?.[header.extraAttr]);
      }
      return header.customDisplay(data?.[header.name]);
    }
    return data?.[header.name];
  };

  const handleLoadMore = () => {
    dispatch(setPageNumber({ page: page + 1 }));
  };

  const Calculate = ({ id }) => <i id={id} className={"mx-1 cursor-pointer bx bx-xs bx-calculator text-primary"} />;

  const TooltipLayout = ({ title, id, targetKey, children = <></> }) => (
    <CustomTooltip
      placement="top"
      style={{ maxWidth: "fit-content" }}
      content={
        <>
          <RenderIf isTrue={title}>
            <div className="d-flex font-size-16 mb-2">{title}</div>
          </RenderIf>
          <div className="mb-2" style={{ minWidth: "max-content" }}>
            {children}
          </div>
        </>
      }
      target={`${targetKey}-tooltip-${id}`}
      modifiers={{ preventOverflow: { enabled: false }, fade: { enabled: false } }}
    />
  );

  const TooltipData = ({ data }) => {
    const { price, cost, value, label, fee, profit, referralFee = 0, feeType = "None", isCurrency = true } = data;
    return (
      <React.Fragment>
        <div className={"d-flex font-size-12 justify-content-between"}>
          <div className="mr-1">Cost:</div>
          <div className="ml-1">{amountText(cost)}</div>
        </div>
        <RenderIf
          isTrue={isCurrency}
          fallback={
            <div className={"d-flex font-size-12 justify-content-between"}>
              <div className="mr-1">Profit:</div>
              <div className="ml-1">{amountText(profit)}</div>
            </div>
          }
        >
          <div className={"d-flex font-size-12 justify-content-between"}>
            <div className="mr-1">Price:</div>
            <div className="ml-1">{amountText(price)}</div>
          </div>
          <div className={"d-flex font-size-12 justify-content-between"}>
            <div className="mr-1">Referral Fee:</div>
            <div className="ml-1">{amountText(referralFee)}</div>
          </div>
          <RenderIf isTrue={feeType !== "None"}>
            <div className={"d-flex font-size-12 justify-content-between"}>
              <div className="mr-1">{feeType} fee:</div>
              <div className="ml-1">{amountText(fee)}</div>
            </div>
          </RenderIf>
        </RenderIf>

        <hr className="my-2" />
        <div className={"d-flex font-size-16 justify-content-between"}>
          <div className="mr-1">{label}:</div>
          <div className="ml-1">{isCurrency ? `${amountText(value)}` : `${value}%`}</div>
        </div>
      </React.Fragment>
    );
  };

  const getProfitData = (match, header, label, isCurrency) => {
    const data = match?._source;
    const { priceType, valueType, feeType } = appliedFilters?.[marketPlace]?.profitablity?.controls ?? {};
    const costType = `${parseName[priceType?.value]}${parseName[valueType?.value]}`;
    let fee = 0;
    if (feeType?.value !== "None") fee = data?.[parseName[feeType?.value]];
    const cost = data.supplierPrice;
    const referralFee = (data?.referralFeePercentage / 100) * cost;

    return {
      _id: match?._id,
      price: data?.[costType],
      value: data?.[header.name],
      feeType: feeType?.value,
      profit: data?.profit,
      cost,
      referralFee,
      fee,
      label,
      isCurrency,
    };
  };

  const SimpleTooltip = ({ data, header, title, targetKey, field, isCurrency = false }) => {
    const profitData = getProfitData(data, header, field, isCurrency);
    const value = profitData?.value;
    return (
      <div className="d-flex align-items-center">
        <TooltipLayout title={title} id={data._id} targetKey={targetKey}>
          <TooltipData data={profitData} />
        </TooltipLayout>
        <Calculate id={`${targetKey}-tooltip-${data._id}`} />
        {isCurrency ? currencyDisplay(value) : rateDisplay(value)}
      </div>
    );
  };

  return (
    <>
      <CardBody>
        <div className="d-inline-flex align-items-center mx-2 mb-3">
          <RenderIf isTrue={showFilters}>
            <SelectSalesRank />
            <ColumnSelector tableHeaders={tableHeaders} columns={columns} setColumns={setColumns} />
          </RenderIf>
          <RenderIf isTrue={columnFilters && Object.keys(columnFilters).length > 0}>
            <h6 className="mt-2">
              Applied Filters:
              {Object.keys(columnFilters).map((column, index) => (
                <span
                  key={index}
                  className="font-size-12 badge badge-primary badge-pill mx-2 p-2"
                  style={{ backgroundColor: "#2E3A59", color: "#FFFFFF" }}
                >
                  {columnFilters[column]?.title}: {columnFilters[column]?.displayOption} {columnFilters[column]?.value}
                  <span
                    className="cursor-pointer px-1"
                    style={{ color: "#FF6B6B" }}
                    onClick={() => handleRemoveFilter(column)}
                  >
                    <i className="mdi mdi-close "></i>
                  </span>
                </span>
              ))}
            </h6>
          </RenderIf>
        </div>
        <div className="table-responsive" style={{ minHeight: "170px" }}>
          <table className="table table-centered table-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                {matchingListHeader.map(
                  (header, index) =>
                    columns[header.title] &&
                    !header.hide && (
                      <th onClick={header.onClick} key={`table-header ${index}`}>
                        {header.title}
                        {header.info && (
                          <div className="d-inline ml-1">
                            <UncontrolledTooltip
                              target={`${kebabCase(header.title)}-tooltip`}
                              style={{ minWidth: "fit-content", maxWidth: "380px" }}
                            >
                              {header.info}
                            </UncontrolledTooltip>
                            <i
                              id={`${kebabCase(header.title)}-tooltip`}
                              className="bx bx-info-circle text-primary"
                              style={{ fontSize: "18px" }}
                            />
                          </div>
                        )}
                        {header.filterIcon && (
                          <>
                            <div className="d-inline ml-1" id={`icon-${index}`}>
                              {header.filterIcon}
                            </div>
                            <ColumnLevelFilter
                              isOpen={openFilterId === `icon-${index}`}
                              toggle={toggleFilter}
                              filterTitle={header.title}
                              onApply={handleColumnFilter}
                              targetId={`icon-${index}`}
                              isNumeric={header.isNumeric}
                              columnName={header.name}
                              header={header}
                            />
                          </>
                        )}
                        {header.sort ? (
                          <div className="d-inline ml-1">
                            {sortFilter && sortFilter.col === header.title ? (
                              <SortArrows title={header.title} arrow={sortFilter.direction} />
                            ) : (
                              <>
                                <SortArrows arrow={"up"} />
                                <SortArrows arrow={"down"} />{" "}
                              </>
                            )}
                          </div>
                        ) : null}
                      </th>
                    ),
                )}
              </tr>
            </thead>

            <tbody>
              <RenderIf isTrue={matches && matches?.length > 0}>
                {matches.map((match, rowIndex) => (
                  <React.Fragment key={`table-tr ${rowIndex}`}>
                    <tr>
                      {matchingListHeader.map(
                        (header, colIndex) =>
                          columns[header.title] &&
                          !header.hide && (
                            <RenderIf isTrue={match?.hide !== true} key={`table-cell-${rowIndex}-${colIndex}`}>
                              <td className="text-wrap" style={{ minWidth: "180px" }}>
                                {handleDisplayValue(header, match)}
                              </td>
                            </RenderIf>
                          ),
                      )}
                    </tr>
                    <MismatchModal
                      isOpen={openMismatchProductId === match._id}
                      toggleModal={toggleModal}
                      currentState={openMismatchProductId}
                      setState={setOpenMismatchProductId}
                      match={match}
                    />
                    <ListingModal
                      isOpen={openListingProductId === match._id}
                      toggleModal={toggleModal}
                      currentState={openListingProductId}
                      setState={setOpenListingProductId}
                      match={match}
                    />
                  </React.Fragment>
                ))}
              </RenderIf>
            </tbody>
          </table>
          <hr />
        </div>
        <RenderIf isTrue={matches && matches?.length > 0 && matches?.length < totalCount}>
          <div className="d-flex justify-content-center m-3">
            <Button color="primary" onClick={() => handleLoadMore()} outline className="waves-effect waves-light mr-3">
              Load More
            </Button>
          </div>
        </RenderIf>
      </CardBody>
    </>
  );
};

export default MatchingList;
