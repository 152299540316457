import * as $ from "lodash";
import { MARKET_PLACES } from "../../constants";
import { makeQP } from "../../utils/common";
import request from "../restClient";

const resource = "/repricer";
const sameAPIs = ["accounts/listing_insights", "accounts/sales_and_profit", "products/update", "listing_statistics"];
const urlMapping = { amazon: (url) => `amazon/${url}` };

const handleUrl = (platform, baseUrl) => urlMapping[platform]?.(baseUrl) || baseUrl;
const repricerAPI = (url, opts = {}) => request({ url: `${resource}/${url}`, ...opts });

const getUrl = (url, payload) => {
  const { account, priceChangeHistory } = payload || {};
  if (account && !priceChangeHistory) {
    if (account.marketplace === MARKET_PLACES.amazon && !sameAPIs.includes(url)) url = urlMapping.amazon(url);
    else if (sameAPIs.includes(url)) payload.platform = account.marketplace;
    payload.marketplace_account_id = account.identifier;
    delete payload.account;
  }
  // Added priceChangeHistory and deleted here due to account param required in api call
  if (payload?.priceChangeHistory) delete payload.priceChangeHistory;
  return { url, payload };
};

const getAPIResponse = (apiUrl, payload, opts = {}) => {
  const { url, payload: params } = getUrl(apiUrl, payload);
  return repricerAPI(url + makeQP(params), opts);
};

// Dashboard APIs
export const getBuyboxStats = (params) => getAPIResponse("accounts/listing_insights", params);

// Listings APIs
export const getListings = (params) => getAPIResponse("products", params);
export const getFeedsDetails = (params) => getAPIResponse("feeds", params);
export const getListingsFormulas = (platform, params) => getAPIResponse(handleUrl(platform, "formulas/"), params);
export const getListingDetails = (platform, id, mpAccountId) =>
  getAPIResponse(handleUrl(platform, `products/${id}`), { marketplace_account_id: mpAccountId });
export const getPriceChangeHistories = (payload) => getAPIResponse(handleUrl("", "price_change_history"), $.omit(payload, "platform"));
export const createListingsFormulas = (platform, body) =>
  repricerAPI(handleUrl(platform, "formulas/"), { body, method: "POST" });
export const updateListingsFormulas = (platform, id, body) =>
  repricerAPI(handleUrl(platform, `formulas/${id}/`), { body, method: "PUT" });
export const deleteListingsFormula = (platform, id) =>
  repricerAPI(handleUrl(platform, `formulas/${id}/`), { method: "DELETE" });
export const getTags = (platform, params) => getAPIResponse(handleUrl(platform, "tags/"), params);
export const createTag = (platform, body) => repricerAPI(handleUrl(platform, "tags/"), { body, method: "POST" });
export const updateTag = (platform, id, body) =>
  repricerAPI(handleUrl(platform, `tags/${id}/`), { body, method: "PUT" });
export const deleteTag = (platform, id) => repricerAPI(handleUrl(platform, `tags/${id}/`), { method: "DELETE" });
export const deleteAllTags = (platform, body) => repricerAPI(handleUrl(platform, "tags/delete_tags/"), { body, method: "POST" });
export const getStrategies = (platform, params) => getAPIResponse(handleUrl(platform, "strategies"), params);
export const getAllRepricerAccounts = (platform, params) => getAPIResponse(handleUrl(platform, "accounts"), params);
export const getStrategiesStats = (platform, params) =>
  getAPIResponse(handleUrl(platform, "strategies/statistics/"), params);
export const fetchListingsStats = (params) => getAPIResponse("listing_statistics", params);
export const updateListing = (body) =>
  getAPIResponse("products/update", $.pick(body, "account"), {
    body: { params: $.omit(body, "account", "platform", "marketplace_account_id") },
    method: "POST",
  });
export const updateListingsMapPrice = (body) =>
  repricerAPI(handleUrl(body.account.marketplace, `products/${body.productId}/`), {
    body: $.omit(body, ["productId", "account"]),
    method: "PATCH",
  });
export const assignListingsStrategy = (body, params) => getAPIResponse("products/bulk_update", params, { body, method: "POST" });
export const assignStrategy = (platform, body) =>
  repricerAPI(handleUrl(platform, "accounts/assign_strategy/"), { body, method: "PUT" });
export const createStrategy = (platform, body) =>
  repricerAPI(handleUrl(platform, "strategies/"), { body, method: "POST" });
export const updateStrategy = (platform, id, body) =>
  repricerAPI(handleUrl(platform, `strategies/${id}/`), { body, method: "PUT" });
export const removeStrategy = (platform, id) =>
  repricerAPI(handleUrl(platform, `strategies/${id}/`), { method: "DELETE" });
export const removeStrategyAccount = (platform, id) =>
  repricerAPI(handleUrl(platform, `accounts/${id}/unassign_strategy/`), { method: "PUT" });
export const uploadCSVListings = (payload) => {
  const url = "update_supplier_cost";
  const { payload: data } = getUrl(url, payload);
  const formData = new FormData();
  formData.append("file", data?.file, data?.file?.name);
  formData.append("platform", data.platform);
  formData.append("marketplace_account_id", data.marketplace_account_id);
  formData.append("uploaded_by", data.uploaded_by);
  return repricerAPI(url, { method: "POST", formData });
};
