import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import PillBtn from "../../components/Common/PillBtn";
import * as $ from "lodash";
import ReactTable from "../../components/Common/Table";
import * as actions from "../../store/repricer/actions";
import { RenderIf } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import CustomPagination from "../../components/Common/CustomPagination";
import { Alert } from "reactstrap";
import RepricerFormulasModal from "./Components/RepricerFormulasModal";
import SimpleSelect from "../../components/Common/SimpleSelect";
import { setPreloader } from "../../store/actions";
import Preloader from "../../components/Common/Preloader";

const FormulasListings = () => {
  const dispatch = useDispatch();
  const queryParams = queryString.parse(document.location.search);
  const [filters, setFilters] = useState({});
  const [operation, setOperation] = useState(null);
  const [formulasId, setFormulasId] = useState(null);
  const [currentFormulaData, setCurrentFormulaData] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState({ label: "Walmart", value: "walmart" });

  const { listingsFormulas, loading, success, error, repricerFormulasModalIsOpen } = useSelector((state) => state.Repricer);

  useEffect(() => {
    dispatch(actions.fetchListingsFormulas(selectedPlatform.value, $.merge(
      { ...filters, limit: filters.per_page, offset: (filters?.page - 1) * filters?.per_page },
    )));
  }, [filters, selectedPlatform]);

  useEffect(() => window.history.replaceState({}, document.title, window.location.pathname), []);

  const tableHeaders = [
    {
      title: "Name",
      Content: ({ data }) => (
        <div className="d-flex align-items-center">
          <span className="mx-1 font-size-12">{data?.name}</span>
        </div>
      ),
    },
    {
      title: "Statement",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mx-1 font-size-12">{data?.statement}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="font-size-12">
            <i className="mx-1 cursor-pointer text-primary bx bx-sm bx-edit" onClick={() => {
              dispatch(actions.setRepricerFormulasModal(true));
              setOperation("Update");
              setFormulasId(data.id);
              setCurrentFormulaData(data);
            }} />
            <i className="mx-1 cursor-pointer text-danger bx bx-sm bx-trash-alt" onClick={() => {
              dispatch(actions.setRepricerFormulasModal(true));
              setOperation("Delete");
              setFormulasId(data.id);
            }} />
          </span>
        </div>
      ),
    },
  ];

  const cols = $.keyBy(tableHeaders, "title");

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  return (
    <>
      <RenderIf isTrue={loading}>
        <Preloader />
      </RenderIf>
      <RenderIf isTrue={repricerFormulasModalIsOpen}>
        <RepricerFormulasModal
          currentFormulaData={currentFormulaData}
          formulasId={formulasId}
          title={`${operation === "Add" ? "Create" : operation === "Update" ? "Update" : "Delete"} Formula`}
          buttonTitle={operation === "Add" ? "Create" : operation === "Update" ? "Update" : "Confirm"}
          selectedPlatform={selectedPlatform.value}
        />
      </RenderIf>
      <div className="page-content">
        <RenderIf isTrue={success}>
          <div className="auto-hide">
            <Alert color="success" className="my-1">
              <i className="bx bx-check pr-2"></i>
              {success || ""}
            </Alert>
          </div>
        </RenderIf>
        <RenderIf isTrue={error}>
          <div className="auto-hide">
            <Alert color="danger" className="my-1">
              <i className="bx bx-info-circle pr-2"></i>
              {error || ""}
            </Alert>
          </div>
        </RenderIf>
        <Breadcrumb
          title={"Formulas"}
          children={
            <div className="d-flex align-items-baseline">
              <div>
                <SimpleSelect
                  className="mx-1"
                  name="Platform"
                  value={selectedPlatform}
                  onChange={setSelectedPlatform}
                  options={[
                    { label: "Walmart", value: "walmart" },
                    { label: "Amazon", value: "amazon" },
                  ]}
                  formStyle={{ width: "132px" }}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div>
                <PillBtn
                  className="mx-1"
                  title="Add New Formula"
                  name="Add New Formula"
                  color="primary"
                  icon="bx bx-xs bx-plus"
                  onClick={() => {
                    dispatch(actions.setRepricerFormulasModal(true));
                    setOperation("Add");
                    setFormulasId(null);
                    setCurrentFormulaData([]);
                  }}
                />
              </div>
            </div>
          }
        />
        <div className="formulas-table-bg mt-1 mb-4 p-3">
          <ReactTable
            className="table-select"
            tableHeader={
              <>
                {$.values(cols).map((header, index) => (
                  <th key={`table-header-${index}`} className={index ? "text-center" : null}>
                    {header.title}
                  </th>
                ))}
              </>
            }
            style={{ width: "100%", overflow: "auto" }}
            tableBody={
              <RenderIf
                isTrue={!$.isEmpty(listingsFormulas?.results)}
                fallback={
                  <RenderIf isTrue={!loading}>
                    <tr>
                      <td colSpan="100%">
                        <h2 className="text-center">No Records Found</h2>
                      </td>
                    </tr>
                  </RenderIf>
                }
              >
                <>
                  {$.map(listingsFormulas?.results, (data, key) => (
                    <tr key={`table-row-${key}`}>
                      {$.values(cols).map((header, colKey) => (
                        <td key={`table-col-${colKey}`}>
                          <header.Content data={data} index={key} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              </RenderIf>
            }
          />
          <CustomPagination
            total={listingsFormulas?.count}
            page={filters?.page || +queryParams?.page || 1}
            perPage={filters?.perPage || +queryParams?.per_page || 30}
            tabsFilter={filters}
            setTabFilter={setFilters}
            pageOptions={[30, 100, 250]}
            repricer={true}
          />
        </div>
      </div>
    </>
  );
};

export default FormulasListings;
