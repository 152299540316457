import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  SET_EDIT_USER_MODAL,
  SET_ADD_USER_MODAL,
  SET_SELECTED_USER_MODAL,
  FETCH_ALL_ACCOUNTS,
  FETCH_ALL_ACCOUNTS_SUCCESS,
  SEND_USER_INVITE_SUCCESS,
  SET_USER_INVITE_MODAL,
  SET_AGENCY_ACCOUNT_MODAL,
  ASSIGN_AGENCY_ACCOUNTS_TO_USERS,
  ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS,
  ASSIGN_AGENCIES,
  ASSIGN_AGENCIES_SUCCESS,
  SEND_USER_INVITE,
  COMPLETE_USER_ONBOARDING,
  COMPLETE_USER_ONBOARDING_SUCCESS,
  EXPIRE_SESSION,
  EXPIRE_SESSION_SUCCESS,
  API_ERROR,
  SET_CHANGE_PASSWORD_MODEL,
  SET_MANAGE_AGENCIES_MODAL,
  UPDATE_PROFILE_AND_COMPANY_INFO,
  UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS,
  FETCH_COMPANY_INFO_SUCCESS,
  FETCH_COMPANY_INFO,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  SET_ADD_BALANCE_MODAL,
  FETCH_DISPUTE_LOGS,
  FETCH_DISPUTE_LOGS_SUCCESS,
  SET_TOS_ACCEPTANCE_MODAL,
  UPDATE_TOS_BIT,
  UPDATE_TOS_BIT_SUCCESS,
  UPDATE_TOS_FOR_ALL,
  UPDATE_TOS_FOR_ALL_SUCCESS,
  GET_AGENCY_CLIENTS,
  GET_AGENCY_CLIENTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  passwordError: "",
  users: null,
  loading: false,
  roles: {},
  accounts: [],
  clients: [],
  stats: [],
  disputeData: {},
  selectedUser: null,
  editUserModalIsOpen: false,
  addUserModalIsOpen: false,
  inviteUserModalIsOpen: false,
  agencyAccountModalIsOpen: false,
  manageAgenciesModalIsOpen: false,
  changePasswordModalIsOpen: false,
  addBalanceModalIsOpen: false,
  tosAcceptanceModalIsOpen: false,
  onboardUserSuccess: "",
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ALL_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        users: action.payload,
      };
      break;
    case EXPIRE_SESSION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EXPIRE_SESSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        gotExpired: action.payload.message,
      };
      break;
    case FETCH_ALL_ACCOUNTS:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case FETCH_ALL_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        roles: action.payload.roles,
        accounts: action.payload.accounts,
        stats: action.payload.stats,
      };
      break;
    case COMPLETE_USER_ONBOARDING:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case COMPLETE_USER_ONBOARDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        onboardUserSuccess: "User Onboarded Successfully",
      };
      break;
    case CREATE_USER:
      state = {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
      break;
    case CREATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: (action.payload?.username || "") + " Created Successfuly",
      };
      break;
    case UPDATE_USER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: (action.payload?.username || "") + " Updated Successfully",
      };
      break;
    case UPDATE_PROFILE_AND_COMPANY_INFO:
      state = {
        ...state,
        loading: true,
        error: "",
        success: "",
      };
      break;
    case UPDATE_PROFILE_AND_COMPANY_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: action.payload.message,
        data: action.payload.data,
      };
      break;
    case FETCH_COMPANY_INFO:
      state = {
        ...state,
        loading: true,
        error: "",
        success: "",
      };
      break;
    case FETCH_COMPANY_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "",
        company_info: action.payload.data,
        user_info: action.payload.user,
      };
      break;
    case DELETE_USER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: (action.payload?.username || "") + " Deleted Successfully",
      };
      break;
    case SEND_USER_INVITE:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case SEND_USER_INVITE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        success: "Invite Sent Successfully to " + (action.payload || ""),
      };
      break;
    case SET_EDIT_USER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        editUserModalIsOpen: action.payload,
      };
      break;
    case SET_ADD_USER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        addUserModalIsOpen: action.payload,
      };
      break;
    case SET_CHANGE_PASSWORD_MODEL:
      state = {
        ...state,
        error: "",
        success: "",
        changePasswordModalIsOpen: action.payload,
      };
      break;
    case SET_MANAGE_AGENCIES_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        manageAgenciesModalIsOpen: action.payload,
      };
      break;
    case SET_USER_INVITE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        inviteUserModalIsOpen: action.payload,
      };
      break;
    case SET_TOS_ACCEPTANCE_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        tosAcceptanceModalIsOpen: action.payload,
      };
      break;
    case SET_AGENCY_ACCOUNT_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        agencyAccountModalIsOpen: action.payload,
      };
      break;
    case ASSIGN_AGENCY_ACCOUNTS_TO_USERS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ASSIGN_AGENCY_ACCOUNTS_TO_USERS_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Agency Accounts has been Assigned to Users Successfully",
        loading: false,
      };
      break;
    case ASSIGN_AGENCIES:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case ASSIGN_AGENCIES_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.message,
        loading: false,
      };
      break;
    case SET_SELECTED_USER_MODAL:
      state = {
        ...state,
        error: "",
        success: "",
        selectedUser: action.payload,
      };
      break;
    case FETCH_DISPUTE_LOGS:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
      break;
    case FETCH_DISPUTE_LOGS_SUCCESS:
      state = {
        ...state,
        disputeData: action.payload.data,
        loading: false,
      };
      break;
    case UPDATE_ACCOUNT:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case UPDATE_ACCOUNT_SUCCESS:
      state = { ...state, success: "Account Updated Successfully", addBalanceModalIsOpen: false, loading: false };
      break;
    case SET_ADD_BALANCE_MODAL:
      state = { ...state, error: "", success: "", addBalanceModalIsOpen: action.payload };
      break;
    case UPDATE_TOS_BIT:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case UPDATE_TOS_BIT_SUCCESS:
      state = { ...state, success: "TOS Updated  Successfully", tosAcceptanceModalIsOpen: false, loading: false };
      break;
    case UPDATE_TOS_FOR_ALL:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case UPDATE_TOS_FOR_ALL_SUCCESS:
      state = { ...state, success: "TOS updated  successfully against all users", loading: false };
      break;
    case GET_AGENCY_CLIENTS:
      state = { ...state, error: "", success: "", loading: true };
      break;
    case GET_AGENCY_CLIENTS_SUCCESS:
      state = { ...state, error: "", success: "", loading: false, clients: action.payload };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.includes("password") ? "" : action.payload,
        passwordError: action.payload.includes("password") ? action.payload : "",
        success: false,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Users;
