import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  const totalCount = props.totalCount?.toLocaleString("en-US") || props.totalCount;
  return (
    <Row>
      <Col xs="12">
        <div
          className={`page-title-box d-flex align-items-center justify-content-between ${
            props.responsive ? "flex-column flex-md-row" : ""
          }`}
        >
          <div className={`${props.responsive ? "mb-3 mb-md-0" : ""}`}>
            <h4
              className="mb-0 font-size-18"
              style={{ textTransform: props.isCapitalize ? "capitalize" : "uppercase" }}
            >
              {props.title}
            </h4>
          </div>
          <div>
            <p className="mb-0 font-size-18 text-white font-weight-bold flex-column flex-md-row">
              Total Results: {totalCount}
            </p>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
            {props.children}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
