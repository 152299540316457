import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  getAllAccounts,
  assignAgencyAccounts,
  assignAgencies,
  inviteUser,
  onboardUser,
  expireUserSession,
  updateProfileAndCompanyInfo,
  fetchCompanyInfo,
  updateAccount,
  fetchDisputeLogs,
  updateTosPolicy,
  updateAllTos,
  fetchAgencyClients,
} from "../../api";

// Login Redux States
import {
  FETCH_ALL_USERS,
  EXPIRE_SESSION,
  FETCH_ALL_ACCOUNTS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SEND_USER_INVITE,
  ASSIGN_AGENCIES,
  ASSIGN_AGENCY_ACCOUNTS_TO_USERS,
  COMPLETE_USER_ONBOARDING,
  UPDATE_PROFILE_AND_COMPANY_INFO,
  FETCH_COMPANY_INFO,
  UPDATE_ACCOUNT,
  FETCH_DISPUTE_LOGS,
  UPDATE_TOS_BIT,
  UPDATE_TOS_FOR_ALL,
  GET_AGENCY_CLIENTS,
} from "./actionTypes";

import {
  apiError,
  fetchUsersSuccess,
  fetchAccountsSuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  setAddUserModal,
  setEditUserModal,
  sendInviteForUserSuccess,
  assignAgenciesSuccess,
  setManageAgenciesModal,
  assignAgencyAccountToUsersSuccess,
  setAgencyAccountModal,
  setInviteUserModal,
  expireSessionSuccess,
  completeUserOnboardingSuccess,
  updateProfileAndCompanyInfoSuccess,
  fetchCompanyInfoSuccess,
  setChangePasswordModel,
  updateAccountSuccess,
  fetchDisputeLogsSuccess,
  updateTosSuccess,
  setTosAcceptanceModal,
  updateTosForAllSuccess,
  getAgencyClientsSuccess,
} from "./actions";
import { getUserInfo, encode, decodeJwtToken as jwtDecode, sleep, setAuthTokenInBrowser } from "../../utils/common";

const User = (state) => state.User;
const Settings = (state) => state.Settings;

function * fetchUsersSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllUsers, { params });
    yield put(fetchUsersSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchDisputeLogsSaga ({ payload: params }) {
  try {
    const res = yield call(fetchDisputeLogs, params);
    yield put(fetchDisputeLogsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * expireSession ({ payload: { params } }) {
  try {
    const res = yield call(expireUserSession, { params });
    yield put(expireSessionSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function * fetchAccountsSaga ({ payload: { params } }) {
  try {
    const res = yield call(getAllAccounts, params);
    yield put(fetchAccountsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * createUserSaga ({ payload: { data } }) {
  try {
    const res = yield call(createUser, { data });
    if (res?.data) {
      const { users, accounts } = yield select(User);
      const account = accounts?.find((acc) => acc.id === getUserInfo()?.account_id);
      if (account) res.data.account = account;

      users.data = [...users.data, res.data];
      yield put(setAddUserModal(false));
      yield put(createUserSuccess(res.data));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateUserSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateUser, { data, id });
    if (res?.data) {
      const { users, accounts } = yield select(User);
      const account = accounts?.find((acc) => acc.id === res.data.account_id);
      if (account) res.data.account = account;
      if (Array.isArray(users?.data)) {
        const index = users.data.findIndex((user) => user.id === res.data.id);
        users.data[index] = res.data;
      }
      yield put(setEditUserModal(false));
      yield put(setChangePasswordModel(false));
      yield put(updateUserSuccess(res.data));
      if ("password" in data) {
        yield sleep(3);
        window.location.replace("/logout");
      }
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateTosSaga () {
  try {
    const res = yield call(updateTosPolicy);
    if (res?.data) {
      const { account } = yield select(Settings);
      account.data = res.data;
      yield put(updateTosSuccess(res));
      yield put(setTosAcceptanceModal(false));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateTosForAllSaga () {
  try {
    const res = yield call(updateAllTos);
    yield put(updateTosForAllSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateProfileAndCompanyInfoSaga ({ payload }) {
  try {
    const res = yield call(updateProfileAndCompanyInfo, { id: payload.id, data: payload.data });
    yield put(updateProfileAndCompanyInfoSuccess(res));
    if (res?.token) {
      setAuthTokenInBrowser(res.token);
      if (jwtDecode(res.token)?.role === "admin") {
        const tokenData = jwtDecode(res.token);
        localStorage.setItem(
          "I6fiFamLVmL",
          encode(JSON.stringify({ username: tokenData?.username, email: tokenData?.email }), 10),
        );
      }
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchCompanyInfoSaga ({ payload: id }) {
  try {
    const res = yield call(fetchCompanyInfo, id);
    yield put(fetchCompanyInfoSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteUserSaga ({ payload }) {
  try {
    const { data } = yield call(deleteUser, payload);
    if (data) {
      const { users } = yield select(User);
      users.data = users.data.filter((user) => user.id !== data.id);
      yield put(setAddUserModal(false));
      yield put(createUserSuccess(data));
    }
    yield put(deleteUserSuccess(data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignAgencyAccountsToUsersSaga ({ payload: { data } }) {
  try {
    const res = yield call(assignAgencyAccounts, { data });
    if (res?.success) {
      const { users } = yield select(User);
      const { allTenants } = yield select(Settings);

      if (res.deletedIds.length) {
        users.data.find((x) => x.id === data.user_id).AccountUser = users.data
          ?.find((x) => x.id === data.user_id)
          .AccountUser.filter((x) => !res.deletedIds.includes(x.id));
      }

      if (res.filteredIds) {
        const newAccounts = allTenants.filter((x) => res.filteredIds.includes(x.id));
        users.data.find((x) => x.id === data.user_id).AccountUser = [
          ...users.data?.find((x) => x.id === data.user_id).AccountUser,
          ...newAccounts,
        ];
      }
      yield put(setAgencyAccountModal(false));
    }
    yield put(assignAgencyAccountToUsersSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * assignAgenciesSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(assignAgencies, { data, id });
    if (res?.success) {
      const { users } = yield select(User);
      const index = users.data.findIndex((user) => user.id === id);
      users.data[index].agency_info = data;
      yield put(setManageAgenciesModal(false));
      yield put(assignAgenciesSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * sendUserInviteSaga ({ payload: { data } }) {
  try {
    yield call(inviteUser, { data });
    yield put(setInviteUserModal(false));
    yield put(sendInviteForUserSuccess(data.email));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * onboardUserSaga ({ payload }) {
  try {
    const res = yield call(onboardUser);
    yield put(completeUserOnboardingSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateAccountSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateAccount, { data, id });
    if (res?.data) {
      const { allTenants } = yield select(Settings);
      const index = allTenants.findIndex((x) => x.id === id);
      allTenants[index] = res.data;
    }
    yield put(updateAccountSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * fetchAgencyClientsSaga () {
  try {
    const res = yield call(fetchAgencyClients);
    yield put(getAgencyClientsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchUsers () {
  yield takeEvery(FETCH_ALL_USERS, fetchUsersSaga);
}

export function * watchFetchDisputeLogs () {
  yield takeEvery(FETCH_DISPUTE_LOGS, fetchDisputeLogsSaga);
}

export function * watchFetchCompanyInfo () {
  yield takeEvery(FETCH_COMPANY_INFO, fetchCompanyInfoSaga);
}

export function * watchUpdateProfileAndCompanyInfo () {
  yield takeEvery(UPDATE_PROFILE_AND_COMPANY_INFO, updateProfileAndCompanyInfoSaga);
}

export function * watchExpireSession () {
  yield takeEvery(EXPIRE_SESSION, expireSession);
}

export function * watchCreateUser () {
  yield takeEvery(CREATE_USER, createUserSaga);
}

export function * watchUpdateUser () {
  yield takeEvery(UPDATE_USER, updateUserSaga);
}

export function * watchDeleteUser () {
  yield takeEvery(DELETE_USER, deleteUserSaga);
}

export function * watchFetchAccounts () {
  yield takeEvery(FETCH_ALL_ACCOUNTS, fetchAccountsSaga);
}

export function * watchAgencyAccountsAssign () {
  yield takeEvery(ASSIGN_AGENCY_ACCOUNTS_TO_USERS, assignAgencyAccountsToUsersSaga);
}

export function * watchAssignAgencies () {
  yield takeEvery(ASSIGN_AGENCIES, assignAgenciesSaga);
}

export function * watchSendUserInvite () {
  yield takeEvery(SEND_USER_INVITE, sendUserInviteSaga);
}

export function * watchOnboardUser () {
  yield takeEvery(COMPLETE_USER_ONBOARDING, onboardUserSaga);
}

export function * watchUpdateAccount () {
  yield takeEvery(UPDATE_ACCOUNT, updateAccountSaga);
}

export function * watchUpdateTos () {
  yield takeEvery(UPDATE_TOS_BIT, updateTosSaga);
}

export function * watchUpdateTosForAll () {
  yield takeEvery(UPDATE_TOS_FOR_ALL, updateTosForAllSaga);
}

export function * watchFetchAgencyClientsSaga () {
  yield takeEvery(GET_AGENCY_CLIENTS, fetchAgencyClientsSaga);
}

function * SuppliersSaga () {
  yield all([
    fork(watchFetchCompanyInfo),
    fork(watchUpdateProfileAndCompanyInfo),
    fork(watchFetchUsers),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchFetchAccounts),
    fork(watchSendUserInvite),
    fork(watchOnboardUser),
    fork(watchExpireSession),
    fork(watchAssignAgencies),
    fork(watchAgencyAccountsAssign),
    fork(watchUpdateAccount),
    fork(watchFetchDisputeLogs),
    fork(watchUpdateTos),
    fork(watchUpdateTosForAll),
    fork(watchFetchAgencyClientsSaga),
  ]);
}

export default SuppliersSaga;
