import React, { useEffect } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { amountText, formatNumber, RenderIf } from "../../../utils/common";
import * as $ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeesDetails } from "../../../store/actions";
import moment from "moment";
import { Spinner } from "reactstrap";

const FeeDetailsCard = ({ storeId }) => {
  const { feesDetails, feesDetailsLoader } = useSelector((state) => state.Graph);
  const dispatch = useDispatch();
  useEffect(() => {
    !feesDetailsLoader &&
      storeId &&
      dispatch(
        fetchFeesDetails({
          marketplace_id: storeId,
          date: moment().format("YYYY-MM-DD"),
          utc_offset: moment().format("Z"),
        }),
      );
  }, []);

  const DetailsCardContent = ({
    innerKey: key,
    index,
    icon,
    label,
    value,
    isAmount,
    valueColor = "inventory-color",
  }) => (
    <div key={`__${key}__`}>
      <RenderIf isTrue={index}>
        <hr style={{ borderColor: "#A6B0CF33" }} />
      </RenderIf>
      <div className="d-flex justify-content-between align-items-center flex-wrap font-size-12">
        <div className="d-flex align-items-center">
          <i className={`text-primary font-size-16 bx ${icon}`} />
          <span className="mx-1 font-weight-medium">{label}</span>
        </div>
        <div className={`font-weight-medium ${valueColor}`}>
          {$.isNil(value) ? "N/A" : isAmount ? amountText(value, true) : value}
        </div>
      </div>
    </div>
  );

  const DetailsCard = ({ title, data, titleColor, icon }) => {
    const details = [
      { icon: "bx-dollar-circle", label: "Sales:", value: data?.sales, isAmount: true, valueColor: "text-success" },
      { icon: "bx-package", label: "Orders:", value: data?.orders },
      { icon: "bx-package", label: "Units:", value: data?.units },
      { icon: "bx-reset", label: "Refunds:", value: data?.refunds, isAmount: true, valueColor: "text-warning" },
      {
        icon: "bx-dollar-circle",
        label: "Total Profit:",
        value: formatNumber(data?.profit, "currency"),
        isAmount: true,
        valueColor: data?.profit < 0 ? "text-danger" : "text-success",
      },
    ];
    return (
      <div className="w-100 formulas-equation-bg" style={{ minHeight: "100%" }}>
        <div
          className="d-flex justify-content-between align-items-center px-3 pt-3 font-weight-semibold"
          style={{ minHeight: "82px" }}
        >
          <div className="d-flex flex-column font-size-13">
            <div className={`mb-2 text-${titleColor}`}>{title}</div>
            <RenderIf isTrue={data?.startDate || data?.endDate}>
              <div className="font-weight-normal">
                <RenderIf
                  isTrue={data?.startDate !== data?.endDate}
                  fallback={moment(data?.startDate).format("MM/DD/YYYY")}
                >
                  {moment(data?.startDate).format("MM/DD/YYYY")} to {moment(data?.endDate).format("MM/DD/YYYY")}
                </RenderIf>
              </div>
            </RenderIf>
          </div>
          <RenderIf isTrue={icon}>
            <div className="d-flex flex-column">
              <div
                className="avatar-sm rounded-circle bg-primary align-self-center"
                style={{ width: "40px", height: "40px" }}
              >
                <span className="avatar-title rounded-circle">
                  <i className={icon + " font-size-18"} />
                </span>
              </div>
            </div>
          </RenderIf>
        </div>
        <hr style={{ borderColor: "#A6B0CF33" }} className="my-0" />
        <div className="p-3">
          {$.map(details, (item, index) => (
            <DetailsCardContent {...item} index={index} key={`content-card-${index}`} innerKey={`card-key-${index}`} />
          ))}
        </div>
      </div>
    );
  };

  const detailsCardData = [
    { title: "Today", titleColor: "primary", icon: "mdi mdi-calendar-check" },
    { title: "Yesterday", titleColor: "warning", icon: "mdi mdi-calendar-arrow-left" },
    { title: "Last Week", titleColor: "sky-blue", icon: "mdi mdi-calendar-arrow-left" },
    { title: "Last Month", titleColor: "purple", icon: "mdi mdi-calendar-arrow-left" },
  ];

  return (
    <>
      <Breadcrumb isCapitalize={true} title="Fees Details" />
      <RenderIf
        isTrue={!feesDetailsLoader}
        fallback={
          <div className="d-flex justify-content-center">
            <Spinner color="primary" style={{ width: "40px", height: "40px" }} className="ml-3" />
          </div>
        }
      >
        <div className="row">
          {$.map(detailsCardData, (props, idx) => (
            <div key={`card-details-${idx}`} className="mb-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <DetailsCard {...props} data={$.result(feesDetails, `stats.${props.title}`, {})} />
            </div>
          ))}
        </div>
      </RenderIf>
    </>
  );
};

export default FeeDetailsCard;
