import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Row, Button, Col, Collapse, FormGroup, UncontrolledTooltip } from "reactstrap";
import { setFilter, setSavedFilter } from "../../../store/actions";
import Select from "react-select";
import * as $ from "lodash";

import SelectFilter from "./SelectFilter";
import { getFilterSections } from "../common";

const FilterSection = (props) => {
  const { filters, marketplace, setIsModalOpen, selectedFilter, handleApplyFilter } = props;

  const dispatch = useDispatch();
  const [collapseInfoOpen, setCollapseInfoOpen] = useState({ detail: true, fee: false, settings: false });

  const Collapseable = ({ heading, name, className = "", children, filterType = {}, isCheckBox = false }) => {
    let filterKeys = [];
    try {
      filterKeys = Object.keys(filterType?.controls);
      if (isCheckBox) filterKeys = filterKeys.filter((x) => filterType?.controls?.[x]?.checked);
    } catch (e) {
      filterKeys = [];
    }

    return (
      <>
        <div className={"d-inline-flex align-items-center " + className}>
          <i
            style={{ height: "16px", width: "16px", marginTop: "-6px", color: "#556EE6" }}
            className={`bx bxs-${collapseInfoOpen[name] ? "down" : "right"}-arrow cursor-pointer mr-2`}
            onClick={(_) => setCollapseInfoOpen({ ...collapseInfoOpen, [name]: !collapseInfoOpen[name] })}
          />
          <h6>
            {heading}:
            {filterKeys.map((subFilter, index) => (
              <span key={index} className="font-size-12 badge-soft-primary badge badge-primary badge-pill mx-2 p-2">
                {filterType?.controls?.[subFilter]?.value?.label || filterType?.controls?.[subFilter]?.value}
              </span>
            ))}
          </h6>
        </div>
        <Collapse isOpen={collapseInfoOpen[name]} id={name}>
          {children}
        </Collapse>
      </>
    );
  };

  const DropDownSection = ({ dropdown, filterType, controlType, filterSection }) => {
    const formatOptions = (options) => options?.map((val) => ({ value: val, label: val }));
    const iconId = `${$.kebabCase(dropdown?.label)}-${$.kebabCase(dropdown?.value)}-tooltip`;

    return (
      <>
        <div className="text-[#C3CBE4]" key={filterType} style={{ maxWidth: "340px", width: "100%" }}>
          <h6 className="d-inline-flex">
            <span className="text-[#C3CBE4]">{dropdown?.label}</span>
            <div className="ml-1">
              {dropdown?.info?.[0] && (
                <>
                  <UncontrolledTooltip target={iconId} style={{ minWidth: "max-content" }}>
                    {
                      <div className="w-100 d-flex flex-column gap-2 p-1">
                        {dropdown?.info?.map((text, index) => (
                          <div
                            key={index}
                            className="w-100 d-flex justify-content-start"
                            style={{
                              ...(index !== dropdown.info.length - 1 && {
                                paddingBottom: "4px",
                                borderBottom: "1px solid #A6B0CF33",
                              }),
                            }}
                          >
                            <span>{text}</span>
                          </div>
                        ))}
                      </div>
                    }
                  </UncontrolledTooltip>
                  <i id={iconId} className="bx bx-info-circle text-primary" style={{ fontSize: "18px" }} />
                </>
              )}
            </div>
          </h6>
          <FormGroup className="select2-container text-[#C3CBE4]">
            <Select
              name={dropdown?.label}
              value={formatOptions([dropdown?.value])}
              options={formatOptions(dropdown.options)}
              onChange={(e) => {
                const { value } = e;
                dispatch(
                  setFilter({
                    marketplace,
                    filterSection,
                    filterSubSection: filterType,
                    controlType,
                    filterValue: value,
                  }),
                );
              }}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </div>
      </>
    );
  };

  const CheckboxSection = ({ checkboxDetails, controlType, filterSection, checkboxName, index }) => {
    const iconId = `${$.kebabCase(checkboxDetails?.label)}-${$.kebabCase(checkboxDetails?.value)}-tooltip`;

    return (
      <>
        <div className="custom-control custom-checkbox mb-3 d-flex align-items-center" key={index}>
          <div>
            <input
              type="checkbox"
              className="custom-control-input p-2"
              id={`${checkboxName}-${index}`}
              checked={checkboxDetails?.checked}
              onChange={() => 1}
            />
            <label
              onClick={() =>
                dispatch(
                  setFilter({
                    marketplace,
                    filterSection,
                    filterSubSection: checkboxName,
                    controlType,
                  }),
                )
              }
              className="custom-control-label d-inline-flex mr-2 mb-0"
            >
              {checkboxDetails?.label}
              <div className="ml-1">
                {checkboxDetails?.info?.[0] && (
                  <>
                    <UncontrolledTooltip target={iconId}>{checkboxDetails?.info?.[0]}</UncontrolledTooltip>
                    <i id={iconId} className="bx bx-info-circle text-primary" style={{ fontSize: "18px" }} />
                  </>
                )}
              </div>
            </label>
          </div>
          {$.keys(checkboxDetails?.options).map((optionName, optionIndex) => (
            <div key={`${checkboxDetails?.label}-${optionName}-${optionIndex}`} className="d-flex align-items-center">
              <input
                type="checkbox"
                className="custom-control-input p-2 ml-3"
                id={`condition-${optionIndex}`}
                checked={checkboxDetails.options[optionName]?.checked && checkboxDetails?.checked}
                style={{ position: "static" }}
                disabled={!checkboxDetails?.checked}
                onChange ={() => {
                  dispatch(
                    setFilter({
                      marketplace,
                      filterSection,
                      filterSubSection: checkboxName,
                      controlType,
                      optionType: optionName,
                    }),
                  );
                }}
              />

              <label
                htmlFor={`condition-${optionIndex}`}
                className={`custom-control-label d-inline-flex mb-0 ${!checkboxDetails?.checked ? "text-muted" : ""}`}
              >
                {checkboxDetails.options[optionName].label}
              </label>
            </div>
          ))}
        </div>
      </>
    );
  };

  const handleResetFilter = () => {
    dispatch(
      setSavedFilter({
        selectedFilter,
      }),
    );
  };
  return (
    <Card>
      <CardBody>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div>
              <h5 className="mb-0 font-size-16">Filters</h5>
            </div>
            <div className="page-title-right">
              <Row className="align-items-center d-flex justify-content-end mr-1">
                <SelectFilter classes="mt-3 mr-3 text-[#C3CBE4]" style={{ width: "131px" }} />
                <Button
                  color="primary"
                  onClick={handleApplyFilter}
                  outline
                  className="waves-effect waves-light mr-3"
                >
                  Apply Filter
                </Button>
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-3"
                  onClick={() => setIsModalOpen(true)}
                >
                  Save Filter
                </Button>
                <Button
                  color="danger"
                  onClick={() => handleResetFilter()}
                  outline
                  className="waves-effect waves-light mr-3"
                >
                  Reset Filter
                </Button>
              </Row>
            </div>
          </div>
        </Col>

        <Card className="card-layout" key={marketplace}>
          <CardBody>
            {getFilterSections(filters).map((filterName, filterIndex) => (
              <React.Fragment key={filterIndex}>
                <div>
                  <Collapseable
                    heading={filters[filterName]?.name || filterName}
                    name={filterName}
                    filterType={filters[filterName]}
                    isCheckBox={filters[filterName]?.type === "checkbox"}
                    key={filterName}
                  >
                    {filters[filterName]?.type === "dropdown" && (
                      <div className="pt-4" key={filterName}>
                        {filters[filterName]?.label && <h6> {filters[filterName].label} </h6>}
                        <Row className="align-items-center d-flex justify-content-between px-2 pt-2">
                          {$.keys(filters[filterName]?.controls).map((filterType, index) => (
                            <DropDownSection
                              filterType={filterType}
                              dropdown={filters[filterName]?.controls[filterType]}
                              key={filterType}
                              filterSection={filterName}
                              controlType={filters[filterName]?.type}
                            />
                          ))}
                        </Row>
                      </div>
                    )}
                    {filters[filterName]?.type === "checkbox" &&
                      $.keys(filters[filterName]?.controls).map((checkboxName, index) => (
                        <React.Fragment key={checkboxName}>
                          <CheckboxSection
                            key={checkboxName}
                            checkboxDetails={filters[filterName]?.controls?.[checkboxName]}
                            checkboxName={checkboxName}
                            filterSection={filterName}
                            controlType={filters[filterName]?.type}
                            index={index}
                          />
                        </React.Fragment>
                      ))}
                  </Collapseable>
                </div>
                <hr />
              </React.Fragment>
            ))}
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default FilterSection;
