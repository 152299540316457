import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// components
import { Card, CardBody, Container, CardHeader, Alert, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomPagination from "../../components/Common/CustomPagination";
import Modal from "../../components/Common/Modal";
import { ConfirmDialogIcon } from "../../components/Common/ConfirmDialog";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import UserModal from "./components/UserModal";
import InviteModal from "./components/InviteModal";
import AgencyAccountModal from "./components/AgencyAccountModal";
import ManageAgenciesModal from "./components/ManageAgenciesModal";

// actions
import {
  fetchUsers,
  deleteUser,
  setPreloader,
  setAddUserModal,
  setEditUserModal,
  setSelectedUser,
  setInviteUserModal,
  setAgencyAccountModal,
  setManageAgenciesModal,
  expireSession,
} from "../../store/actions";
import { EMAIL_REGEX, INT_REGEX, IP_REGEX, USER_ROLES } from "../../constants";
import { isUserHasExpirePrevilige, sanitizeObj, getUserInfo } from "../../utils/common";
function FormatIPInfo (props) {
  const {
    ipInfoModal: { isOpen, item: user },
    setIpInfoModal,
  } = props;
  if (!user) return <></>;
  const { ip_info: ipInfo } = user;
  if (!ipInfo) return <></>;
  return (
    <Modal size="md" isOpen={isOpen} toggle={() => setIpInfoModal(false)}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          IP Info
        </h5>
      </div>
      <div className="px-4">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <b>IP</b>
              </td>
              <td>
                {ipInfo.ip}
                <CopyToClipBoard text={ipInfo.ip} />
              </td>
            </tr>
            <tr>
              <td>
                <b>Location</b>
              </td>
              <td>{ipInfo.loc}</td>
            </tr>
            <tr>
              <td>
                <b>ISP</b>
              </td>
              <td>{ipInfo.org}</td>
            </tr>
            <tr>
              <td>
                <b>City</b>
              </td>
              <td>{ipInfo.city}</td>
            </tr>
            <tr>
              <td>
                <b>Zip</b>
              </td>
              <td>{ipInfo.postal}</td>
            </tr>
            <tr>
              <td>
                <b>Region</b>
              </td>
              <td>{ipInfo.region}</td>
            </tr>
            <tr>
              <td>
                <b>Country</b>
              </td>
              <td>{ipInfo.country}</td>
            </tr>
            <tr>
              <td>
                <b>Host</b>
              </td>
              <td>{ipInfo.hostname}</td>
            </tr>
            <tr>
              <td>
                <b>Timezone</b>
              </td>
              <td>{ipInfo.timezone}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

const Users = (props) => {
  const [ipInfoModal, setIpInfoModal] = useState({ isOpen: false, item: null });
  const queryParameters = queryString.parse(props.location.search);
  const {
    users,
    success,
    error,
    gotExpired,
    loading,
    selectedUser,
    editUserModalIsOpen,
    addUserModalIsOpen,
    agencyAccountModalIsOpen,
    manageAgenciesModalIsOpen,
    inviteUserModalIsOpen,
  } = useSelector((state) => state.User);
  const { allTenants, account } = useSelector((state) => state.Settings);
  const [tenants, setTenants] = useState({});
  const [filters, setFilters] = useState({
    page: queryParameters?.page || 1,
    all_users: isUserHasExpirePrevilige(),
  });
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const tableHeaders = [
    { title: "Username" },
    { title: "Email" },
    { title: "Parent Email" },
    { title: "Role" },
    { title: "IP Info" },
    { title: "Actions" },
  ];

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }

  function fetchAllUsers () {
    dispatch(fetchUsers(sanitizeObj(filters)));
  }

  function expireUserSession (userId) {
    dispatch(expireSession(userId));
  }

  function handleAddNewUser () {
    dispatch(setAddUserModal(true));
  }

  function handleEditUser (user) {
    dispatch(setEditUserModal(true));
    dispatch(setSelectedUser(user));
  }
  function handleDeleteUser (id) {
    dispatch(deleteUser(id));
  }

  function handleInviteUser () {
    dispatch(setInviteUserModal(true));
  }

  function handleAgencyAccount (bool, user) {
    dispatch(setAgencyAccountModal(bool));
    dispatch(setSelectedUser(user));
  }

  function handleManageAgencies (user) {
    dispatch(setManageAgenciesModal(!manageAgenciesModalIsOpen));
    dispatch(setSelectedUser(user));
  }

  const isApplicableUser = (user) => [USER_ROLES.manager, USER_ROLES.readOnly].includes(user.role);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetchAllUsers();
  }, [filters]);

  useEffect(() => {
    if (!Array.isArray(allTenants)) return;
    setTenants(
      allTenants.reduce((acc, tenant) => {
        acc[tenant.id] = tenant.email;
        return acc;
      }, {}),
    );
  }, [allTenants]);

  return (
    <React.Fragment>
      {selectedUser && editUserModalIsOpen && <UserModal type="edit" />}
      {addUserModalIsOpen && <UserModal type="add" />}
      {inviteUserModalIsOpen && <InviteModal type="add" />}
      {manageAgenciesModalIsOpen && <ManageAgenciesModal />}
      {selectedUser && agencyAccountModalIsOpen && <AgencyAccountModal />}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Users" />
          {success && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {success}
              </Alert>
            </div>
          )}
          {gotExpired && (
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {gotExpired}
              </Alert>
            </div>
          )}
          {error && !addUserModalIsOpen && !editUserModalIsOpen && (
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          )}
          <Card>
            <CardHeader>
              <div className="row d-flex align-items-center justify-content-between">
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      name: IP_REGEX.test(search) || INT_REGEX.test(search) ? null : search,
                      role: Object.keys(USER_ROLES).includes(search) ? search : null,
                      account_id: INT_REGEX.test(search) ? search : null,
                      ip: IP_REGEX.test(search) ? search : null,
                      email: EMAIL_REGEX.test(search) ? search : null,
                    });
                  }}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        if (!e.target.value) {
                          ["name", "role", "account_id", "ip", "email"].forEach((key) => delete filters[key]);
                          setSearch("");
                          setFilters({ ...filters });
                        } else {
                          setSearch(e.target.value.trim());
                        }
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </form>
                <div>
                  <Button color="primary m-1" onClick={handleInviteUser}>
                    <i className="mdi mdi-email-send-outline align-middle mr-1"></i>
                    Invite
                  </Button>
                  <Button color="primary m-1" onClick={handleAddNewUser}>
                    <i className="bx bx-plus align-middle mr-1"></i>
                    Add
                  </Button>
                </div>
              </div>
            </CardHeader>
            <FormatIPInfo ipInfoModal={ipInfoModal} setIpInfoModal={setIpInfoModal} />

            {users?.data?.length ? (
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        {/* table headers */}
                        {tableHeaders.map((header, index) => (
                          <th
                            className={/role|actions/i.test(header.title) ? "text-center" : ""}
                            key={`table-header ${index}`}
                          >
                            {header.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {users?.data?.map((item, key) => (
                        <tr key={"_listings_" + key} className="mt-3 mb-3">
                          <td>{item.username}</td>
                          <td>
                            {item.email}
                            {item.email ? <CopyToClipBoard text={item.email} /> : null}
                          </td>
                          <td>
                            {tenants[item.account_id]
                              ? (
                              <>
                                {tenants[item.account_id]}
                                <CopyToClipBoard text={tenants[item.account_id]} />
                              </>
                                )
                              : (
                                  ""
                                )}
                          </td>
                          <td className="text-center">
                            {String(item.role === "readOnly" ? "client" : item.role).toUpperCase()}
                          </td>
                          <td>
                            {item.ip_info
                              ? (
                              <div className="d-flex align-items-center">
                                {item.ip_info.ip}
                                <i
                                  title="Detail info"
                                  className="px-1 bx bx-xs bx-detail cursor-pointer"
                                  onClick={() => setIpInfoModal({ isOpen: true, item })}
                                ></i>
                              </div>
                                )
                              : item.ip
                                ? (
                              <div>{item.ip}</div>
                                  )
                                : null}
                          </td>
                          <td className="text-center">
                            <i
                              title="Edit"
                              className="bx bx-sm bx-edit p-1 text-warning cursor-pointer"
                              onClick={() => handleEditUser(item)}
                            ></i>
                            {account?.data?.agency_id === null && (
                              <i
                                title={`${
                                  isApplicableUser(item) ? "" : "Cannot "
                                }Assign Agencies To Managers and Clients`}
                                className={`bx bx-sm bx-detail text-${
                                  isApplicableUser(item) ? "primary" : "gray"
                                } cursor-${isApplicableUser(item) ? "pointer" : "not-allowed"}`}
                                onClick={() => isApplicableUser(item) && handleManageAgencies(item)}
                                disabled={true}
                              />
                            )}
                            {isUserHasExpirePrevilige() && (
                              <i
                                title="Clear Session"
                                className="bx bx-sm bx-log-out p-1 text-primary cursor-pointer"
                                onClick={() => expireUserSession(item.id)}
                              ></i>
                            )}
                            <ConfirmDialogIcon
                              icon={{
                                title: "Delete",
                                className: "bx bx-sm text-danger bx-trash",
                              }}
                              msg={`To Delete ${item?.username} Credentials`}
                              onConfirm={() => handleDeleteUser(item.id)}
                            />
                            {["owner", "manager"].includes(getUserInfo()?.role) && item.role === "va" && (
                              <i
                                title="Assign Agency Account To VA"
                                className="bx bx-sm bx-user-pin text-primary cursor-pointer"
                                onClick={() => {
                                  handleAgencyAccount(!agencyAccountModalIsOpen, item);
                                }}
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {users && (
                  <CustomPagination
                    total={users.total}
                    page={users.page}
                    perPage={users.perPage}
                    tabsFilter={filters}
                    setTabFilter={setFilters}
                  />
                )}
              </CardBody>
            ) : null}
            {users?.data?.length === 0 && !loading
              ? (
              <div className="d-flex justify-content-center m-2">
                <div className="d-flex flex-column">
                  <h1>No Records Found</h1>
                </div>
              </div>
                )
              : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
