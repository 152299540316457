import React, { useEffect, useState } from "react";
import moment from "moment";
import StoreSelector from "../../../components/Common/StoreSelector";
import UTCSelect from "../../../components/Common/UTCSelect";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Alert } from "reactstrap";
import {
  RenderIf,
  getUserInfo,
  addStoreFront,
  isUserHaveFullAccess,
  daysLeftInTrial,
  getValidStores,
} from "../../../utils/common";
import { getAccount } from "../../RepricerListings/common";
import PillBtn from "../../../components/Common/PillBtn";
import Insights from "../components/Insights";
import BuyboxChart from "../components/BuyboxChart";
import OrdersChart from "../components/TotalOrders";
import SalesRevenueChart from "../components/SalesRevenueChart";
import GrowBusiness from "../components/GrowBusiness";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import $ from "lodash";
import { ENV, EXTENSION_LINK, MARKET_PLACES } from "../../../constants";
import BlurCard from "../components/BlurCard";
import HeaderBanner from "../../../components/Common/headerBanner";
import onBoardingIcon from "../../../assets/svg/onBoardingIcon.svg";
import ProfitTrialModal from "./components/ProfitTrialModal";
import BestSellerCard from "../components/BestSellerCard";
import SalesCard from "../components/SalesCard";

const CardLayout = (props) => {
  const dispatch = useDispatch();
  const getHeight = () => {
    if (["Insights", "ByCategory", "ByBrand"].includes(props.title)) return "64%";
    else if (props.title === "BestSellers") return "75%";
    else return "";
  };
  const getTop = () => {
    if (props.title === "Insights") return "74px";
    else if (["ByCategory", "ByBrand", "BestSellers"].includes(props.title)) return "79px";
    else return "";
  };
  return (
    <Card className="resources p-3" style={{ position: "relative" }}>
      <RenderIf isTrue={!props.isStoreAdded}>
        <BlurCard height={getHeight()} top={getTop()}>
          <u className="text-primary mx-1 cursor-pointer" onClick={() => addStoreFront(dispatch)}>
            {" "}
            Connect your Storefront{" "}
          </u>{" "}
          via API to access your free Sales Dashboard.
        </BlurCard>
      </RenderIf>
      <CardBody className="community-box">{props.children}</CardBody>
    </Card>
  );
};

const NewDashboard = () => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.Settings);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const { dailyReport, error } = useSelector((state) => state.Graph);
  const { associatedWarehouses } = useSelector((state) => state.WareHouses);
  const { profitTrialModalIsOpen } = useSelector((state) => state.Repricer);
  const [store, setStore] = useState({ value: "", label: "" });
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const isStoreAdded = !!account?.data?.stores_count;
  const [showCOGSAlert, setShowCOGSAlert] = useState(true);
  const isShowMissingCogsAlert = $.result(dailyReport, "data.hasMissingCogsOrder", false);

  const [selectedTimeZone, setSelectedTimeZone] = useState({ value: "-05:00", label: "EST (UTC -05:00)" });
  const [utcOffset, setUtcOffset] = useState(moment().format("Z"));

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    setUtcOffset(moment().format(selectedOption.value));
  };

  const optionData = (acc) => ({
    label: acc.name || "",
    value: acc.id ? acc.id : acc.value,
    marketplace: acc.marketplace,
    identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
    key: 0,
  });

  useEffect(() => {
    !associatedWarehouses && dispatch(actions.fetchAllWarehouses({ associated: true }));

    const userAgent = navigator?.userAgent;
    if (!userAgent || !userAgent.toLowerCase().includes("chrome")) return;
    if (!window.chrome?.runtime || !ENV.REACT_APP_ECOM_EXTENSION_ID) return setIsExtensionInstalled(false);

    window.chrome.runtime.sendMessage(ENV.REACT_APP_ECOM_EXTENSION_ID, { message: "isInstalled" }, (res) => {
      if (res && res.success) setIsExtensionInstalled(true);
      else setIsExtensionInstalled(false);
    });
  }, []);

  const getComponentOpts = (component, title) => ({ Component: component, title });
  const stores = getValidStores(accounts, { forRepricer: true, isValid: true });
  useEffect(() => {
    if (stores?.length) {
      const [acc] = stores;
      setStore(optionData(acc));
    }
  }, [accounts?.data, account?.data?.stores_count]);

  return (
    <>
      <RenderIf isTrue={error}>
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2" />
            Internal Server Error
          </Alert>
        </div>
      </RenderIf>
      <RenderIf isTrue={showCOGSAlert && isShowMissingCogsAlert}>
        <div>
          <Alert color="primary">
            <div className="d-flex justify-content-between align-items-center font-weight-semibold font-size-13 gap-1 flex-wrap">
              <div className="d-flex align-items-center">
                <i className="bx bx-info-circle pr-2" />
                <span>
                  Some orders are missing COGS so profits may not be accurate.&nbsp;
                  <a
                    href={`/source_orders/missing_cogs?marketplace_account_id=${store.value}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{" "}
                  to fix this.
                </span>
              </div>
              <i className="bx font-size-20 bx-x bx-primary cursor-pointer" onClick={() => setShowCOGSAlert(false)} />
            </div>
          </Alert>
        </div>
      </RenderIf>
      <RenderIf isTrue={profitTrialModalIsOpen}>
        <ProfitTrialModal />
      </RenderIf>
      <Breadcrumb
        isCapitalize={false}
        title={`Welcome, ${getUserInfo()?.full_name || getUserInfo()?.username}`}
        children={
          <div className="d-flex">
            <RenderIf isTrue={getUserInfo()?.account_id === 2}>
             <div className="mr-3">
               <UTCSelect value={selectedTimeZone} onChange={handleTimeZoneChange} />
             </div>
            </RenderIf>
            <div>
              <RenderIf isTrue={stores?.length > 1}>
                <StoreSelector
                  forRepricer={true}
                  optionData={optionData}
                  value={store}
                  onChange={setStore}
                  isValid={true}
                />
              </RenderIf>
            </div>
          </div>
        }
      />
      <RenderIf
        isTrue={!isUserHaveFullAccess("extension") && (!account?.data?.trial_end || daysLeftInTrial(account?.data) > 0)}
      >
        <HeaderBanner>
          <div className="mb-3 fs-20 fw-bold font-size-20 text-white listing-banner-para text-center">
            Start Your Free Trial of the Profit Dashboard and Pro Extension for only{" "}
            <span className="price">$27/mo.</span>
          </div>
          <Button color="primary" onClick={() => dispatch(actions.setProfitDashboardTrialModal(true))}>
            <i aria-hidden="true" className=" text-white bx  bx-rocket pr-2"></i>
            Start Trial Now
          </Button>
        </HeaderBanner>
      </RenderIf>
      <Card className="p-3">
        <CardBody>
          <div className="row">
            <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
              <h3>
                <span>Welcome to the Ecom </span>
                <span className="text-primary">Circles</span> Dashboard
              </h3>
              <h6 className="pt-2">
                <div className="pt-2">
                  The Dashboard is the base of operations for your business within Ecom Circles.
                </div>
                <div className="pt-2">We're excited to help you grow!</div>
              </h6>
              <h4 className="mt-5">Onboarding Steps:</h4>
              <OnboardingStep
                text="You have successfully registered with Ecom Circles. Welcome aboard!"
                isCompleted={true}
              />
              <OnboardingStep
                text="You have successfully installed the Chrome Extension to power up your product research on Amazon and Walmart!"
                inCompleteMessage={
                  <>
                    Install our free{" "}
                    <a href={EXTENSION_LINK} rel="noreferrer" target={"_blank"}>
                      <b className="text-primary cursor-pointer">Chrome Extension</b>
                    </a>{" "}
                    to get insights while browsing Amazon or Walmart.
                  </>
                }
                isCompleted={isExtensionInstalled}
              />
              <OnboardingStep
                text="You have successfully connected your storefront via API."
                inCompleteMessage={
                  <>
                    <b className="text-primary cursor-pointer" onClick={() => addStoreFront(dispatch)}>
                      Connect Your Storefront
                    </b>{" "}
                    via API to access your free Sales Dashboard
                  </>
                }
                isCompleted={isStoreAdded}
              />
              <hr
                className="mt-4 d-none"
                style={{ borderTop: "2px solid #454c61", marginTop: "6px", marginBottom: "6px" }}
              />
              <h4 className="mt-4 d-none">You did it! You're Registered!</h4>
              <div className="d-flex mt-4 d-none">
                <PillBtn
                  outline={true}
                  className="waves-effect waves-light mr-3 d-none"
                  color="primary"
                  title="Hide this Welcome Box"
                  name="Hide this Welcome Box"
                  icon="bx bxs-hide ml-1"
                  onClick={() => null}
                />
                <PillBtn
                  className="d-none"
                  color="primary"
                  title="Take the tour"
                  name="Take the tour"
                  icon="bx bx-xs bx-rocket ml-1"
                  onClick={() => null}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
              <div className="d-flex justify-content-center p-4 mr-4">
                <div className="d-flex flex-column">
                  <img src={onBoardingIcon} alt={"Onboarding Icon"} />
                </div>
              </div>
            </div>
            <div className="col d-none">
              <div className="d-flex justify-content-between mt-4 p-4">
                <div className="d-flex flex-column"></div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <GrowBusiness />
      {[
        getComponentOpts(Insights, "Insights"),
        getComponentOpts(BuyboxChart),
        getComponentOpts(SalesRevenueChart),
        getComponentOpts(OrdersChart),
        getComponentOpts(BestSellerCard, "BestSellers"),
        getComponentOpts(SalesCard, "ByCategory"),
        getComponentOpts(SalesCard, "ByBrand"),
      ].map(({ title, Component }, idx) => (
        <CardLayout key={"__card__layout__" + idx} isStoreAdded={isStoreAdded} title={title}>
          <Component store={getAccount(store)} platform={store.marketplace} storeId={store.value} title={title} utcOffset={utcOffset} isStoreAdded={isStoreAdded} />
        </CardLayout>
      ))}
    </>
  );
};

const OnboardingStep = ({ text, isCompleted, inCompleteMessage }) => (
  <div className="d-flex">
    <RenderIf isTrue={isCompleted} fallback={<i className="bx bx-error-circle text-danger pr-1 font-size-20" />}>
      <i className="bx bx-check text-success pr-1 font-size-20" />
    </RenderIf>
    <span className="font-size-14">{isCompleted ? text : inCompleteMessage}</span>
  </div>
);

export default NewDashboard;
