import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Input, Label, Row, Alert } from "reactstrap";
import { isStrongPassword } from "../../../utils/common";
import Modal from "../../../components/Common/Modal";

const ChangePasswordModel = (props) => {
  const [isShownPass, setIsShownPass] = useState(false);
  const [isShownPassNew, setIsShownPassNew] = useState(false);
  const [isShownPassConfirm, setIsShownPassConfirm] = useState(false);
  const toggleModal = () => {
    props.toggleIsOpen(!props.isOpen);
  };

  const initialValues = {
    account_id: props.accountId,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validate = (values) => {
    const errors = {};
    if (values.newPassword !== values.confirmPassword) errors.confirmPassword = "Confirm Password not matched!";

    if (values.newPassword === "") errors.newPassword = "Field is Required";
    if (values.currentPassword === "") errors.currentPassword = "Field is Required";
    if (values.confirmPassword === "") errors.confirmPassword = "Field is Required";
    if (values.newPassword && !isStrongPassword(values.newPassword))
      errors.passwordError =
        "Password must contain at least one uppercase letter, one number, one special character and must be of 8 characters minimum.";
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onChange: (name, value, { props }) => {
      props.handleFormChange(name, value);
    },
    onSubmit: (values) => {
      props.updateUser({
        data: {
          old_password: values.currentPassword,
          password: values.newPassword,
        },
        id: values.account_id,
      });
      formik.resetForm();
    },
  });

  return (
    <Modal isOpen={props.isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="myLargeModalLabel">
          Change Password
        </h5>
        <button onClick={() => toggleModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" className="text-secondary bx bx-md bx-x"></i>
        </button>
      </div>
      {props?.userState?.passwordError && (
        <Alert color="danger" role="alert">
          {props?.userState?.passwordError}
        </Alert>
      )}
      {props?.userState?.success && (
        <Alert color="success" role="alert">
          Password Updated Successfully! You are logging out so you can login using the new password.
        </Alert>
      )}
      <div className="modal-body">
        <form className="m-2" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="12 mb-2">
              <Label>Current Password*</Label>
              <div className="d-flex justify-content-between">
                <Input
                  name="currentPassword"
                  type={isShownPass ? "text" : "password"}
                  value={formik.values.currentPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span className="input-group-append">
                  <span
                    className="input-group-text colorpicker-input-addon rounded-right"
                    onClick={() => {
                      setIsShownPass(!isShownPass);
                    }}
                  >
                    <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                  </span>
                </span>
              </div>
              {formik.touched.currentPassword && formik.errors.currentPassword
                ? (
                <span className="text-danger mt-1">{formik.errors.currentPassword}</span>
                  )
                : null}
            </Col>
            <Col lg="12 mb-2">
              <Label>New Password*</Label>
              <div className="d-flex justify-content-between">
                <Input
                  name="newPassword"
                  type={isShownPassNew ? "text" : "password"}
                  placeholder="Enter new Password"
                  value={formik.values.newPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span className="input-group-append">
                  <span
                    className="input-group-text colorpicker-input-addon rounded-right"
                    onClick={() => {
                      setIsShownPassNew(!isShownPassNew);
                    }}
                  >
                    <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                  </span>
                </span>
              </div>
              {formik.touched.newPassword && formik.errors.newPassword
                ? (
                <span className="text-danger mt-1">{formik.errors.newPassword}</span>
                  )
                : null}
              {formik.touched.newPassword && formik.errors.passwordError
                ? (
                <span className="text-danger mt-1">{formik.errors.passwordError}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row>
            <Col lg="12 mb-2">
              <Label>Confirm Password*</Label>
              <div className="d-flex justify-content-between">
                <Input
                  name="confirmPassword"
                  type={isShownPassConfirm ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <span className="input-group-append">
                  <span
                    className="input-group-text colorpicker-input-addon rounded-right"
                    onClick={() => {
                      setIsShownPassConfirm(!isShownPassConfirm);
                    }}
                  >
                    <i className="bx bx-xs bx-show mx-1 cursor-pointer"></i>
                  </span>
                </span>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? (
                <span className="text-danger mt-1">{formik.errors.confirmPassword}</span>
                  )
                : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3">
            <Button type="submit" color="success" className="mr-3">
              Submit
            </Button>
          </Row>
        </form>
      </div>
      <div className="modal-footer"></div>
    </Modal>
  );
};

export default ChangePasswordModel;
